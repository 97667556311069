import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import styles from "./SoSFullLogo.module.scss";
export const SoSFullLogo = ({ containerProps }: TSoSFullLogoProps) => {
   return (
      <div {...containerProps} className={`${styles.contentC} ${containerProps?.className}`}>
         <img src={CF_CDN_URL("/assets/logo_dark.svg")} alt="" />
         <img src={CF_CDN_URL("/assets/nav_simply_os.svg")} alt="" />
      </div>
   );
};

type TSoSFullLogoProps = {
   containerProps?: React.HTMLAttributes<HTMLDivElement>;
};

import classNames from "classnames/bind";
import { SHrPopup, SHrSosTabsBar } from "@simplyhomes/react";
import { useMemo, useState } from "react";

import styles from "./QuestionLibrary.module.scss";
import QuestionCard from "../QuestionCard/QuestionCard";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { useQQE_Questions } from "../../../hooks/queries/QEditor/useQQE_Questions";
import { useDnDKitWrapper_Draggable } from "../../../hooks/DnDKit/useDnDKitWrapper_Draggable";
import { TDnDQuestionData, useQEContext } from "../../../contexts/QEditorContext";
import { QuestionSettings } from "../QuestionSettings/QuestionSettings";

const cx = classNames.bind(styles);

const questionLibraryTabs = ["For Property", "For Unit"];

const QuestionLibrary = () => {
   const { qeDispatch } = useQEContext();

   const qQE_Questions = useQQE_Questions();

   const questions = qQE_Questions.data?.questions;
   const [search, setSearch] = useState<string>("");
   const [showCreateModal, setShowCreateModal] = useState(false);
   const [tabSelected, setTabSelected] = useState<(typeof questionLibraryTabs)[number]>(questionLibraryTabs[0]);

   const qids = useMemo(() => {
      const allQuestions = questions || [];
      const selectedQuestions =
         tabSelected === "For Property"
            ? allQuestions.filter((q) => !q.data.isUnitQuestion)
            : tabSelected === "For Unit"
            ? allQuestions.filter((q) => q.data.isUnitQuestion)
            : [];
      return selectedQuestions
         .filter(
            (q) => !search || ("question" in q.data && q.data.question.toLowerCase().includes(search.toLowerCase()))
         )
         .map((q) => q.qid);
   }, [questions, search, tabSelected]);

   const handleShowCreateModal = () => {
      if (!search) return;
      setSearch("");
      setShowCreateModal(true);
      qeDispatch({ type: "select-question", payload: { qId: null } });
   };

   return (
      <div className={cx("questionLibraryC")}>
         <div className={cx("qlHeaderC")}>
            <span>Question Library</span>

            <div className={cx("input_search")}>
               <input
                  type="text"
                  placeholder="Search a question"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
               />
               <button type="button" title="btn" onClick={handleShowCreateModal} className={cx("image_icon")}>
                  <img src={CF_CDN_URL(`/assets/${search.length ? "plus_white.svg" : "search.svg"}`)} alt="" />
               </button>
            </div>
            <SHrPopup show={showCreateModal} handleShow={setShowCreateModal}>
               <QuestionSettings />
            </SHrPopup>
         </div>
         <SHrSosTabsBar tabs={questionLibraryTabs} value={tabSelected} onChange={setTabSelected} />
         <ul className={cx(`qlBodyC`)}>
            {qids.map((qid, i) => (
               <Question key={i + qid} questionId={qid} />
            ))}
         </ul>
      </div>
   );
};
const Question = ({ questionId }: { questionId: string }) => {
   const { setNodeRef, attributes, listeners, isDragging } = useDnDKitWrapper_Draggable<TDnDQuestionData>({
      id: `library|${questionId}`,
      data: { id: questionId, source: "library" },
   });
   return (
      <li {...attributes} ref={setNodeRef} className={cx({ isDragging })}>
         <img src={CF_CDN_URL("/assets/drag_indicator_primary.svg")} alt="" {...listeners} />
         <QuestionCard questionId={questionId} />
      </li>
   );
};

export default QuestionLibrary;

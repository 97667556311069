import { TableColumn } from "react-data-table-component";
import { PVi_useQPVi_Sources_Source } from "../../../hooks/queries/PVi/useQPVi_Sources";

export const allPropertiesColumn: TableColumn<PVi_useQPVi_Sources_Source>[] = [
   { name: <strong>Street Address</strong>, selector: (source) => source.address_one_line },
   { name: <strong>Neighborhood</strong>, selector: (source) => "" },
   { name: <strong>Market</strong>, selector: (source) => source.market },
   { name: <strong>source Type</strong>, selector: (source) => source.ptype },
   {
      name: <strong>Total Beds</strong>,
      selector: (source) => source.units.map((unit) => unit.beds).reduce((acc, value) => acc + value, 0),
   },
   { name: <strong>Asking Price</strong>, selector: (source) => source.price },
   { name: <strong>Square Feet</strong>, selector: (source) => source.sqft || 0 },
   { name: <strong>Max Offer Amount</strong>, selector: (source) => "" },
   { name: <strong>Cap Rate</strong>, selector: (source) => "" },
   { name: <strong>Lead history</strong>, selector: (source) => "" },
   { name: <strong>Year Built</strong>, selector: (source) => source.year_built },
];

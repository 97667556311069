import classNames from "classnames/bind";
import { useMemo } from "react";
import { SHrPopup, SHrSosButton, SHrSosTabsBar } from "@simplyhomes/react";

import styles from "./PropertyViabilityPage.module.scss";
import SoSFilter from "../../components/common/SoSFilter/SoSFilter";

import { PViProvider, usePViContext } from "../../contexts/PViContext";
import { CF_CDN_URL } from "../../utils/CF_CDN_URL";
import { useQPVi_FilterOptions } from "../../hooks/queries/PVi/useQPVi_FilterOptions";
import { SoSInputSelect } from "../../components/common/SoSInputSelect/SoSInputSelect";
import { useQPVi_SourceStatuses } from "../../hooks/queries/PVi/useQPVi_SourceStatuses";
import { SoSInputTitle } from "../../components/common/soSInputTitle/SoSInputTitle";
import { usePViQueryParams } from "../../hooks/sosApps/propertyViability/usePViQueryParams";
import { useQPV_Properties } from "../../hooks/queries/propertyValidator/useQPV_Properties";
import { useMPVi_QuickPickSource } from "../../hooks/mutates/PVi/useMPVi_QuickPickSource";
import { PViAskingPriceFilter } from "./PViAskingPriceFilter/PViAskingPriceFilter";
import { PViGridView } from "./PViGridView/PViGridView";
import { PViListView } from "./PViListView/PViListView";
import { PViMapView } from "./PViMapView/PViMapView";
import { PViPropertyDetails } from "./PViPropertyDetails/PViPropertyDetails";

const cx = classNames.bind(styles);

enum EViews {
   GridView = "Grid View",
   MapView = "Map View",
   ListView = "List View",
}
const viewIcons: Record<string, string | undefined> = {
   "Grid View": CF_CDN_URL("/assets/property_viability/grid_view_primary.svg"),
   "Map View": CF_CDN_URL("/assets/property_viability/map_view_primary.svg"),
   "List View": CF_CDN_URL("/assets/property_viability/list_view_primary.svg"),
};

const PVi = () => {
   const { view } = usePViQueryParams();
   return (
      <div className={cx(`contentC`)}>
         <PViPropertyDetailsModal />
         <PViTop />
         <div className={cx("body")}>
            {view === EViews.GridView ? (
               <PViGridView />
            ) : view === EViews.ListView ? (
               <PViListView />
            ) : view === EViews.MapView ? (
               <PViMapView />
            ) : null}
         </div>
      </div>
   );
};
/**
 * !WARN: NEED PViContext
 */
export const PViPropertyDetailsModal = () => {
   const { pviState, pviOverwrite } = usePViContext();
   const { showDetailsModal } = pviState;

   return (
      <SHrPopup
         show={showDetailsModal}
         handleShow={(b) => pviOverwrite({ showDetailsModal: b, excludedSourceIds: [], sid: b ? undefined : "" })}
      >
         <PViPropertyDetails />
      </SHrPopup>
   );
};

const PViTop = () => {
   const qpPVi = usePViQueryParams();
   const { status, ptypes, zips, markets, q, filters, view } = qpPVi;
   const { setStatus, setPtypes, setZips, setMarkets, setQ, setView } = qpPVi;

   const qPViProps = useQPV_Properties();

   const qPV_SourceStatuses = useQPVi_SourceStatuses();

   const mPVi_QuickPickSource = useMPVi_QuickPickSource();

   const tabOptions = useMemo(
      () =>
         qPV_SourceStatuses.data?.statuses.map(({ status, count }) => ({
            label: `${status} (${count})`,
            value: status,
         })) || [],
      [qPV_SourceStatuses.data]
   );

   const filterOptions = useQPVi_FilterOptions().data;

   const newPropLength = qPV_SourceStatuses.data?.statusMap?.New || 0;
   const isFilter = filters.markets.length > 0 || filters.ptypes.length > 0 ? "With Filters" : "";

   const handleChangeTab = (v: string) => {
      qPV_SourceStatuses.refetch();
      qPViProps.refetch();
      setStatus(v);
   };

   return (
      <div className={cx("top")}>
         <div className={cx("topFilter")}>
            <section className={cx("topLeft")}>
               <SoSInputTitle
                  value={q}
                  type="text"
                  handleValue={setQ}
                  icon="search"
                  placeholder="Search the address"
                  inputProps={{ className: cx("input_search") }}
               />

               <div className={cx("divider-container")}>
                  <hr className={cx("divider")} />
               </div>

               <div className={cx("filterList")}>
                  <SoSFilter
                     title="Market"
                     type="select"
                     isMulti={true}
                     value={markets}
                     onChange={(vs) => setMarkets(vs.map((o) => o.value))}
                     options={filterOptions?.market || []}
                     className={cx("filter-dropdown")}
                  />

                  <SoSFilter
                     title="ZIP"
                     type="select"
                     isMulti={true}
                     value={zips}
                     onChange={(vs) => setZips(vs.map((o) => o.value))}
                     options={filterOptions?.zip || []}
                     className={cx("filter-dropdown")}
                  />
                  <SoSFilter
                     title="Property Type"
                     type="select"
                     isMulti={true}
                     value={ptypes}
                     onChange={(vs) => setPtypes(vs.map((o) => o.value))}
                     options={filterOptions?.ptype || []}
                     className={cx("filter-dropdown")}
                  />

                  <PViAskingPriceFilter />

                  {/* <SoSFilter
               title="Neighborhood"
               type="select"
               isMulti={true}
               value={values}
               onChange={(values) => setValues(values)}
               options={operatorConditions}
               className={cx("filter-dropdown")}
            />

            <SoSFilter
               title="Asking Price"
               type="select"
               isMulti={true}
               value={values}
               onChange={(values) => setValues(values)}
               options={operatorConditions}
               className={cx("filter-dropdown")}
            />

            <SoSFilter
               title="Total Bedrooms"
               type="select"
               isMulti={true}
               value={values}
               onChange={(values) => setValues(values)}
               options={operatorConditions}
               className={cx("filter-dropdown")}
            />

            <SoSFilter
               title="Source"
               type="select"
               isMulti={true}
               value={values}
               onChange={(values) => setValues(values)}
               options={operatorConditions}
               className={cx("filter-dropdown")}
            /> */}
               </div>
            </section>
            <section className={cx("topRight")}>
               <div className={cx("view")}>
                  <div className={cx("img")}>{viewIcons[view] && <img src={viewIcons[view]} alt="" />}</div>
                  <SoSInputSelect
                     options={Object.values(EViews)}
                     value={view}
                     onChange={(op) => setView(op.value)}
                     isMulti={false}
                     className={cx("filter-dropdown")}
                  />
               </div>

               {newPropLength > 0 && status === "New" && (
                  <>
                     <div className={cx("divider-container")}>
                        <hr className={cx("divider")} />
                     </div>
                     <SHrSosButton
                        loading={mPVi_QuickPickSource.isPending}
                        buttonProps={{ onClick: () => mPVi_QuickPickSource.mutate() }}
                     >
                        Start Quick Pick
                        {isFilter} ({newPropLength})
                     </SHrSosButton>
                  </>
               )}
            </section>
         </div>
         <div className={cx("topTab")}>
            <SHrSosTabsBar tabs={tabOptions} value={status} onChange={handleChangeTab} />
         </div>
      </div>
   );
};

export const PropertyViabilityPage = () => {
   return (
      <PViProvider>
         <PVi />
      </PViProvider>
   );
};

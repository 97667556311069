import { useMutation } from "@tanstack/react-query";

import { useQQE_Flows } from "../queries/QEditor/useQQE_Flows";
import { useSosAxios } from "../axios/useSosAxios";

export const useMQE_CreateFlow = () => {
   const { sosAxios } = useSosAxios();
   const qFlows = useQQE_Flows();

   return useMutation({
      mutationFn: async (createNewFlowProps: CreateFlowDataProps) => {
         const flow = await sosAxios
            .post("/qeditor/flows", { ...createNewFlowProps })
            .then(({ data }: { data: TData }) => data);
         qFlows.refetch();
         return flow;
      },
   });
};

type TData = { flow: { fid: string; name: string } };

type CreateFlowDataProps = {
   name: string;
};

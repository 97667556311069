import classNames from "classnames/bind";
import { CSS } from "@dnd-kit/utilities";
import { SHrSosButton } from "@simplyhomes/react";
import { useMemo, useState } from "react";
import { SHrPopup, SHrSpinner } from "@simplyhomes/react";

import styles from "./FlowEditor.module.scss";
import QuestionCard from "../QuestionCard/QuestionCard";

import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { useQQE_Flow } from "../../../hooks/queries/QEditor/useQQE_Flow";
import { useQQE_Flows } from "../../../hooks/queries/QEditor/useQQE_Flows";
import { SoSInputTitle } from "../../../components/common/soSInputTitle/SoSInputTitle";
import { useQueryParams } from "../../../hooks/react/useQueryParams";
import { SoSInputSelect } from "../../../components/common/SoSInputSelect/SoSInputSelect";
import { useMQE_CreateFlow } from "../../../hooks/mutates/useMQE_CreateFlow";
import { useDnDKitWrapper_Sortable } from "../../../hooks/DnDKit/useDnDKitWrapper_Sortable";
import { useDnDKitWrapper_Droppable } from "../../../hooks/DnDKit/useDnDKitWrapper_Droppable";
import { TDnDQuestionData, useQEContext } from "../../../contexts/QEditorContext";
import { SHu_widget_populate_questions } from "@simplyhomes/utils";
import { useMQE_SaveFlow } from "../../../hooks/mutates/useMQE_SaveFlow";
import { Widget } from "../../../components/widget/Widget";

const cx = classNames.bind(styles);
const FlowEditor = () => {
   const { qeState, qeDispatch } = useQEContext();
   const { isPreviewing, flowCode, flowQuestions } = qeState;
   const { useSingleQueryParamsState: getStateParams } = useQueryParams();
   const [fid, setFid] = getStateParams("fid");
   const [showConfigModal, setShowConfigModal] = useState(false);

   const qQE_Flows = useQQE_Flows();
   const qQE_Flow = useQQE_Flow({ fid });
   const mQE_CreateFlow = useMQE_CreateFlow();
   const mQE_SaveFlow = useMQE_SaveFlow();

   const flowOptions = useMemo(
      () => qQE_Flows.data?.flows.map((f) => ({ value: f.fid, label: f.name })) || [],
      [qQE_Flows.data?.flows]
   );
   const flowSelected = qQE_Flow.data ? { label: qQE_Flow.data.flow?.name, value: qQE_Flow.data.flow?.fid } : "";

   const handleCreateNewFlow = async ({ flowName }: { flowName: string }) => {
      const { flow } = await mQE_CreateFlow.mutateAsync({ name: flowName });
      if (flow.fid) setFid(flow.fid);
   };
   const handlePublish = () => {
      if (!flowCode) return setShowConfigModal(true);
      mQE_SaveFlow.mutate();
   };
   return (
      <div className={cx("flowEditorC")}>
         <div className={cx("feTopC")}>
            <div className={cx("feTopLC")}>
               <h1>Choose a flow</h1>
               <SoSInputSelect
                  className={cx("input-serach-flow")}
                  placeholder="Search or create a flow"
                  isMulti={false}
                  value={flowSelected}
                  onChange={(o) => setFid(o.value)}
                  options={flowOptions}
                  isLoading={mQE_CreateFlow.isPending}
                  allowCreate={{ cb: ({ value }) => handleCreateNewFlow({ flowName: value }) }}
               />
               {qQE_Flow.isFetched && <FlowSettingModal show={showConfigModal} setShow={setShowConfigModal} />}
            </div>
            {qQE_Flow.isFetched && flowQuestions.length > 0 && (
               <div className={cx("feTopRC")}>
                  <SHrPopup
                     show={isPreviewing}
                     handleShow={(b) => qeDispatch({ overwrite: { isPreviewing: b } })}
                     triggerElement={
                        <SHrSosButton buttonProps={{ className: cx("sosBtn") }} type="outlined">
                           <span>Preview</span>
                        </SHrSosButton>
                     }
                  >
                     <FlowPreviewModal />
                  </SHrPopup>
                  <SHrSosButton
                     loading={mQE_SaveFlow.isPending}
                     buttonProps={{ className: cx("sosBtn"), onClick: handlePublish }}
                     type="filled"
                  >
                     Publish Now
                  </SHrSosButton>
               </div>
            )}
         </div>
         {qQE_Flow.isFetching ? (
            <div className={cx(`flowFetching`)}>
               <SHrSpinner />
               <span>Loading Flow...</span>
            </div>
         ) : fid ? (
            <FlowItems />
         ) : (
            <div className={cx(`noFlowSelected`)}>
               <span>Select a flow to start</span>
            </div>
         )}
      </div>
   );
};

const FlowSettingModal = ({ show, setShow }: { show: boolean; setShow: (b: boolean) => void }) => {
   const { qeState, qeDispatch } = useQEContext();
   const { flowCode } = qeState;
   return (
      <SHrPopup
         show={show}
         handleShow={setShow}
         triggerElement={
            <img className={cx(`gearBtnImg`)} src={CF_CDN_URL(`/assets/question-editor/settings_primary.svg`)} alt="" />
         }
      >
         <article className={`${styles.settingModalC}`}>
            <header>
               <div>
                  <img src={CF_CDN_URL("/assets/question-editor/settings_primary.svg")} alt="" />
                  <h1>Configurations</h1>
               </div>
               <button type="button" onClick={() => setShow(false)}>
                  <img src={CF_CDN_URL("/assets/question-editor/close_neutral.svg")} alt="close" />
               </button>
            </header>
            <SoSInputTitle
               type="text"
               title="Widget Code"
               value={flowCode || ""}
               placeholder="Unique code (important)"
               handleValue={(flowCode) => qeDispatch({ overwrite: { flowCode } })}
               error={flowCode ? "" : "This field is required"}
            />
         </article>
      </SHrPopup>
   );
};
const FlowPreviewModal = () => {
   const { qeState, qeHelper } = useQEContext();
   const { flowQuestions } = qeState;
   const { libraryQuestionsMap } = qeHelper;
   const { populatedQuestions } = SHu_widget_populate_questions({
      rawQuestionIds: flowQuestions,
      questionsMap: libraryQuestionsMap,
   });
   console.log({ flowQuestions, populatedQuestions });

   return <div className={cx(`previewC`)}>{flowQuestions.length > 0 && <Widget data={populatedQuestions} />}</div>;
};

const FlowItems = () => {
   const { qeState } = useQEContext();
   const { flowQuestions: qids, qIdDragging } = qeState;
   const { setNodeRef: dropRef } = useDnDKitWrapper_Droppable({ id: "flowEditor" });
   const legalDrop = !!qIdDragging;
   return (
      <div className={cx(`flowItemsC`, { legalDrop })} ref={dropRef}>
         {qids.map((item, i) => (
            <FlowItem index={i} item={item} key={i} />
         ))}
      </div>
   );
};
const FlowItem = ({ item, index }: { item: string | string[]; index: number }) => {
   const isUnitSet = Array.isArray(item);
   const {
      setNodeRef: dragRef,
      attributes,
      listeners,
      transform,
      transition,
      isDragging,
   } = useDnDKitWrapper_Sortable<TDnDQuestionData>({
      id: isUnitSet ? `Array|${index}` : `${item}|${index}`,
      data: { id: isUnitSet ? `Array|${index}` : item, source: "flow", index },
   });
   const style = { transform: CSS.Transform.toString(transform), transition };
   const [expanded, setExpanded] = useState(true);
   return (
      <div style={style} ref={dragRef} className={cx(`flowItemC`, { isDragging, isUnitSet })}>
         <div className={cx(`itemHandle`)}>
            <img
               onMouseDown={() => setExpanded(false)}
               {...listeners}
               {...attributes}
               src={CF_CDN_URL("/assets/drag_indicator_primary.svg")}
               alt=""
            />
            <span>{index < 10 ? `0${index + 1}` : index}</span>
            {isUnitSet && (
               <SHrSosButton
                  type="bland"
                  buttonProps={{ className: cx(`itemHandleBtn`), onClick: () => setExpanded((prev) => !prev) }}
               >
                  {expanded ? "Collapse" : "Expand"}
               </SHrSosButton>
            )}
         </div>
         {isUnitSet ? (
            <FlowItemSet itemSet={item} itemIndex={index} expanded={expanded} />
         ) : (
            <QuestionCard questionId={item} itemIndex={index} />
         )}
      </div>
   );
};

const FlowItemSet = ({ itemSet, itemIndex, expanded }: { itemSet: string[]; itemIndex: number; expanded: boolean }) => {
   const { setNodeRef: dropRef } = useDnDKitWrapper_Droppable({ id: `unitSet|${itemIndex}` });
   const { qeState, qeHelper } = useQEContext();
   const { libraryQuestionsMap } = qeHelper;
   const { qIdDragging } = qeState;
   const questionDragging = libraryQuestionsMap[qIdDragging];
   const legalDrop = questionDragging?.isUnitQuestion;
   return (
      <div className={cx(`subItemSetC`, { legalDrop })} ref={dropRef}>
         {expanded &&
            (!qIdDragging || (qIdDragging && legalDrop)) &&
            itemSet.map((unitQid, si) => (
               <FlowItemSetItem unitQid={unitQid} itemIndex={itemIndex} subItemIndex={si} key={si} />
            ))}
      </div>
   );
};

const FlowItemSetItem = (p: { unitQid: string; itemIndex: number; subItemIndex: number }) => {
   const { unitQid, itemIndex, subItemIndex } = p;
   const {
      listeners,
      attributes,
      setNodeRef: dragRef,
      transform,
      transition,
      isDragging,
   } = useDnDKitWrapper_Sortable<TDnDQuestionData>({
      id: `${unitQid}|${itemIndex}|${subItemIndex}`,
      data: { id: unitQid, source: "flow", index: itemIndex, subIndex: subItemIndex },
   });
   const style = { transform: CSS.Transform.toString(transform), transition };
   return (
      <div style={style} className={cx(`subItemC`, { isDragging })} {...attributes} ref={dragRef}>
         <img src={CF_CDN_URL("/assets/drag_indicator_primary.svg")} alt="" {...listeners} />
         <QuestionCard itemIndex={itemIndex} subItemIndex={subItemIndex} questionId={unitQid} />
      </div>
   );
};

export default FlowEditor;

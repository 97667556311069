import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { TPgSoS_WidgetQuestions_Schema, TWidget_Question_Raw } from "@simplyhomes/utils";

import { useQEContext } from "../../../contexts/QEditorContext";
import { useSosAxios } from "../../axios/useSosAxios";

export const useQQE_Questions = () => {
   const { qeState, qeDispatch } = useQEContext();
   const { libraryQuestions } = qeState;
   const { sosAxios } = useSosAxios();
   const query = useQuery({
      queryKey: ["QQE_Questions"],
      queryFn: () =>
         sosAxios.get("/qeditor/questions").then(({ data }: { data: TData }) => {
            qeDispatch({ overwrite: { libraryQuestions: data.questions.map((q) => q.data) } });
            return data;
         }),
   });

   // useEffect(() => {
   //    const questions = query.data?.questions;
   //    if (questions && libraryQuestions.length === 0) {
   //       console.log("run");
   //       qeDispatch({ overwrite: { libraryQuestions: questions.map((q) => q.data) } });
   //    }
   // }, [libraryQuestions.length, qeDispatch, query.data?.questions]);
   return query;
};

const questionsCols = ["qid", "data"] as (keyof TPgSoS_WidgetQuestions_Schema)[];
type TData = {
   questions: Pick<TPgSoS_WidgetQuestions_Schema, (typeof questionsCols)[number]>[];
};

import classNames from "classnames/bind";
import { constants_paths } from "../../../constants/path";
import styles from "./NavVertical.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";

const cx = classNames.bind(styles);
const NavVertical = () => {
   const [isOpen, setIsOpen] = useState(false);
   return (
      <div className={cx("contentC", { expandedC: isOpen })}>
         <MapNavItems menuList={mainMenuList} />
         <div className={cx("divider")} />
         <MapNavItems menuList={appsMenuList} />
         <button className={cx(`expandBtnC`, { active: isOpen })} onClick={() => setIsOpen(!isOpen)}>
            <img src={CF_CDN_URL("/assets/double_arrow_right_primary.svg")} alt="toggle-btn" />
         </button>
      </div>
   );
};

export default NavVertical;

const MapNavItems = ({ menuList }: { menuList: TMenuItem[] }) => {
   const location = useLocation();
   return (
      <ul>
         {menuList.map((item, i) => (
            <li key={i + item.title}>
               <NavLink to={item.path} className={cx({ active: location.pathname === item.path })}>
                  <img src={CF_CDN_URL(`/assets/${item.icon}`)} alt="" />
                  <span>{item.title}</span>
               </NavLink>
            </li>
         ))}
      </ul>
   );
};

type TMenuItem = { path: string; icon: string; title: string };
const mainMenuList: TMenuItem[] = [
   { path: constants_paths["HOME"], icon: "nav_home.svg", title: "Home" },
   { path: constants_paths["APPLICATION_LIBRARY"], icon: "widgets_black.svg", title: "Application Library" },
];
const appsMenuList: TMenuItem[] = [
   { path: constants_paths["PROPERTY_VALIDATOR"], icon: "add_home_work_black.svg", title: "Property Validator" },
   { path: constants_paths["OFFERS"], icon: "contact_mail_black.svg", title: "Offers" },
   { path: constants_paths["APPS_QEDITOR"], icon: "quiz.svg", title: "Question Editor" },
   { path: constants_paths["APPS_CSV_UPLOAD"], icon: "cloud_upload.svg", title: "CSV Upload" },
   { path: constants_paths["APPS_MAP"], icon: "map_black.svg", title: "Map" },
];

import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useMapContext } from "../../../contexts/MapContext";
import { useSosAxios } from "../../axios/useSosAxios";

export const useQMap_Properties = () => {
   const { sosAxios } = useSosAxios();
   const { mapDispatch } = useMapContext();
   const isDev = process.env.NODE_ENV === "development";
   const useDummy = isDev && false;
   const query = useQuery({
      queryKey: ["useQMap_Properties"],
      queryFn: () =>
         useDummy ? dummyMapResp : sosAxios.get("/map/properties").then(({ data }: { data: TData }) => data),
   });
   useEffect(() => {
      if (query.data?.properties)
         mapDispatch({ type: "setProperties", payload: { properties: query.data.properties } });
   }, [query.data?.properties, mapDispatch]);

   return query;
};

type TData = {
   properties: MapPropertyDto[];
};
export type MapPropertyDto = {
   id: string;
   address: string;
   sqft: number;
   unitCount: number;
   bedrooms: number;
   bathrooms: number;
   askingPrice: number;
   coordinates: { x: number; y: number };
};

const dummyMapResp: TData = {
   properties: [
      {
         id: "rec00DGLbyIfR13V8",
         address: "9921 Sophia Ave, Cleveland Oh 44104",
         sqft: 1150,
         unitCount: 1,
         bedrooms: 3,
         bathrooms: 2,
         askingPrice: 44900,
         coordinates: {
            x: 41.4790711,
            y: -81.6152717,
         },
      },
      {
         id: "rec00t3i23FXAsfcK",
         address: "516 East 112th Street, Cleveland, OH, USA",
         sqft: 1376,
         unitCount: 1,
         bedrooms: 3,
         bathrooms: 2,
         askingPrice: 55000,
         coordinates: {
            x: 41.5433206,
            y: -81.606884,
         },
      },
      {
         id: "rec014Bxx2ZeGKnIi",
         address: "2411 Woodstock Ave, Pittsburgh, PA 15218",
         sqft: 1805,
         unitCount: 1,
         bedrooms: 4,
         bathrooms: 2,
         askingPrice: 144000,
         coordinates: {
            x: 40.4174823,
            y: -79.8811929,
         },
      },
      {
         id: "rec03Bj8X54N1nbYp",
         address: "1101 W Roache Street, Indianapolis, IN 46208",
         sqft: 1150,
         unitCount: 1,
         bedrooms: 2,
         bathrooms: 1,
         askingPrice: 70000,
         coordinates: {
            x: 39.8044585,
            y: -86.1802307,
         },
      },
      {
         id: "rec09szeM7EARtAnK",
         address: "1532 Nelson Avenue, Indianapolis, IN 46203",
         sqft: 872,
         unitCount: 1,
         bedrooms: 2,
         bathrooms: 1,
         askingPrice: 118000,
         coordinates: {
            x: 39.7286805,
            y: -86.1333363,
         },
      },
      {
         id: "rec0B70cqXnOz2eIg",
         address: "1450 Golay St, Indianapolis, IN 46203",
         sqft: 975,
         unitCount: 1,
         bedrooms: 2,
         bathrooms: 1,
         askingPrice: 0,
         coordinates: {
            x: 39.7475841,
            y: -86.11424380000001,
         },
      },
      {
         id: "rec0D7SjN0UnzqTQP",
         address: "1835 Reyburn Rd Cleveland, OH 44112",
         sqft: 2065,
         unitCount: 0,
         bedrooms: 0,
         bathrooms: 0,
         askingPrice: 0,
         coordinates: {
            x: 41.5490736,
            y: -81.5597926,
         },
      },
      {
         id: "rec0DGeNc1oGytlii",
         address: "3031 N New Jersey, Indianapolis, 46205",
         sqft: 2600,
         unitCount: 1,
         bedrooms: 4,
         bathrooms: 1,
         askingPrice: 175000,
         coordinates: {
            x: 39.8111889,
            y: -86.1505931,
         },
      },
   ],
};

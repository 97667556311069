import classNames from "classnames/bind";

import styles from "./QuestionEditorPage.module.scss";
import FlowEditor from "./FlowEditor/FlowEditor";
import QuestionLibrary from "./QuestionLibrary/QuestionLibrary";
import MainAndTriggerQuestion from "./MainAndTriggerQuestion/MainAndTriggerQuestion";

import { QEditorProvider, TDnDQuestionData, useQEContext } from "../../contexts/QEditorContext";
import {
   DndContext,
   DragCancelEvent,
   DragEndEvent,
   DragMoveEvent,
   DragOverEvent,
   DragOverlay,
   DragStartEvent,
   PointerSensor,
   useSensor,
   useSensors,
} from "@dnd-kit/core";
import QuestionCard from "./QuestionCard/QuestionCard";
import { Props as DndProps } from "@dnd-kit/core/dist/components/DndContext/DndContext";
import { SortableContext, horizontalListSortingStrategy } from "@dnd-kit/sortable";
import { useMemo } from "react";
import { useQueryParams } from "../../hooks/react/useQueryParams";
import { QuestionSettings } from "./QuestionSettings/QuestionSettings";

const cx = classNames.bind(styles);
const QuestionEditorPage = () => {
   return (
      <QEditorProvider>
         <DnDWrapper />
      </QEditorProvider>
   );
};

export default QuestionEditorPage;

const DnDWrapper = () => {
   const { qeState, qeDispatch } = useQEContext();
   const { qIdDragging, flowQuestions, libraryQuestions, qIdSelected } = qeState;
   const sensors = useSensors(useSensor(PointerSensor));
   const onDragStart = (e: DragStartEvent & TDndEvent) => {
      // console.log({ DragStartEvent: e });
      qeDispatch({ overwrite: { qIdDragging: e.active.data.current?.id } });
   };
   const { params } = useQueryParams();
   const fid = params.get("fid");
   const sortItems = useMemo(
      () => [
         ...flowQuestions.map((item, i) => (Array.isArray(item) ? `Array|${i}` : `${item}|${i}`)),
         ...flowQuestions.flatMap((item, i) => (Array.isArray(item) ? item.map((si, j) => `${si}|${i}|${j}`) : [])),
         ...libraryQuestions.map((q) => `library|${q.id}`),
      ],
      [flowQuestions, libraryQuestions]
   );

   const onDragEnd = (e: DragEndEvent & TDndEvent) => {
      // console.log({ DragEndEvent: e });
      qeDispatch({ overwrite: { qIdDragging: "" } });
      const { active, over } = e;
      const from = active.data.current;
      const to = over?.data.current;
      const containerId = over?.id?.toString();
      if (from && to) qeDispatch({ type: "flow-drag-end", payload: { from, to } });
      if (from && containerId) qeDispatch({ type: "flow-drag-empty", payload: { from, containerId } });
   };
   return (
      <DnDKitWrapperContext sensors={sensors} onDragStart={onDragStart} onDragEnd={onDragEnd}>
         <SortableContext items={sortItems} strategy={horizontalListSortingStrategy}>
            <section className={cx("container")}>
               <div className={cx("topC")}>
                  {fid && <QuestionLibrary />}
                  <MainAndTriggerQuestion />
                  {qIdSelected && <QuestionSettings />}
               </div>
               <FlowEditor />
            </section>
            {qIdDragging && (
               <DragOverlay>
                  <QuestionCard questionId={qIdDragging} />
               </DragOverlay>
            )}
         </SortableContext>
      </DnDKitWrapperContext>
   );
};
type TDndEvent = { active: { data: { current?: TDnDQuestionData } }; over?: { data: { current?: TDnDQuestionData } } };
const DnDKitWrapperContext = (
   props: Omit<DndProps, "onDragStart" | "onDragMove" | "onDragOver" | "onDragEnd" | "onDragCancel"> & {
      onDragStart?(event: DragStartEvent & TDndEvent): void;
      onDragMove?(event: DragMoveEvent & TDndEvent): void;
      onDragOver?(event: DragOverEvent & TDndEvent): void;
      onDragEnd?(event: DragEndEvent & TDndEvent): void;
      onDragCancel?(event: DragCancelEvent & TDndEvent): void;
   }
) => {
   return <DndContext {...props}></DndContext>;
};

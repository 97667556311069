import classNames from "classnames/bind";
import styles from "./OffersLeadCard.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { useQOffers_GetLeads } from "../../../hooks/queries/offers/leads/useQOffers_GetLeads";
import { useOffersContext } from "../../../contexts/OffersContext";
import { SoSConfirmWrapper } from "../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
const cx = classNames.bind(styles);

export const formatDate = (dateString: string): string => {
   const date = new Date(dateString);

   const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      day: "numeric",
      month: "short",
      year: "numeric",
   };

   return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const OffersLeadCard = ({ lid }: TOffersLeadCardProps) => {
   const { offersDispatch } = useOffersContext();

   const qOfferLeads = useQOffers_GetLeads();
   if (!qOfferLeads.data) return null;

   const { leadsMap } = qOfferLeads.data;
   const lead = leadsMap[lid];

   if (!lead) return null;
   const { status } = lead;

   return (
      <div className={cx("container")} onClick={() => offersDispatch({ overwrite: { lid } })}>
         <div className={cx("nameC")}>
            <span className={cx("title")}>Lead Name</span>
            <SoSConfirmWrapper type="default" onCancel={() => {}} onConfirm={() => {}}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/delete_outline_black.svg")} alt="" />
               </div>
            </SoSConfirmWrapper>
         </div>
         <div className={cx("addressC")}>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/offer/location_on_black.svg")} alt="" />
            </div>
            <span>1 Helen St, McKees Rocks, PA 15136, USA</span>
         </div>
         <div className={cx("statusesC")}>
            <div className={cx("statusC")}>
               <span>Latest Offer</span>
               <span className={cx("title")}>{status}</span>
            </div>
            <div className={cx("statusC")}>
               <span>Lead Next Actions</span>
               <span className={cx("title")}>Waiting response</span>
            </div>
         </div>
         <div className={cx("timeC")}>
            <span>Created at</span>
            <span>
               <b>12:04AM, 26 Jun 2023</b>
            </span>
         </div>
         <div className={cx("timeC")}>
            <span>Assignee</span>
            <span>
               <b>Mark Lee</b>
            </span>
         </div>
      </div>
   );
};

type TOffersLeadCardProps = {
   lid: string;
};

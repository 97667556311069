import { arrayMove } from "@dnd-kit/sortable";
import { TWidget_Question_Raw } from "@simplyhomes/utils";
import { Dispatch, createContext, useContext, useMemo, useReducer } from "react";
export type TDnDQuestionData = { id: string } & (
   | { source: "library" }
   | { source: "flow"; index: number; subIndex?: number }
);
type TQEditor_State = {
   flowQuestions: (string | string[])[];
   flowCode: string | null;
   libraryQuestions: TWidget_Question_Raw[];
   qIdSelected: string;
   qSelected: TWidget_Question_Raw | null;
   qIdDragging: string;
   isPreviewing: boolean;
};

const initialState: TQEditor_State = {
   flowQuestions: [],
   flowCode: null,
   libraryQuestions: [],
   qIdSelected: "",
   qSelected: null,
   qIdDragging: "",
   isPreviewing: false,
};

type TQE_Action =
   // | { type: "questions-remove-prop-question"; payload: { propIndex?: number } }
   // | { type: "questions-remove-unit-question"; payload: { propIndex?: number; unitIndex?: number } }
   | { type: "flow-set"; payload: Pick<TQEditor_State, "flowQuestions" | "flowCode"> }
   | { type: "flow-remove-question"; payload: { itemIndex: number; subItemIndex?: number } }
   | { type: "flow-drag-end"; payload: { from: TDnDQuestionData; to: TDnDQuestionData } }
   | { type: "flow-drag-empty"; payload: { from: TDnDQuestionData; containerId: string } }
   | { type: "add-question-from-question-library"; payload: { question: TWidget_Question_Raw } }
   | { type: "binding-question-selected-to-questing-setting"; payload: { qIdSelected: string } }
   | { type: "select-question"; payload: { qId: string | null } }
   | { type: "update-selected-question_isUnitQuestion"; payload: { isUnitQuestion: boolean } }
   | { type: "update-selected-question_question"; payload: { question: string } }
   | { type: "update-selected-question_description"; payload: { description: string } }
   | { type: "update-selected-question_allowSkip"; payload: { allowSkip: boolean } }
   | { type: "update-selected-question_id"; payload: { id: string } }
   | { type: "update-selected-question_type"; payload: { type: string } }
   | { type: "update-selected-question_multipleChoice"; payload: { multipleChoice: boolean } }
   | { type: "update-selected-question_style"; payload: { style: string } }
   | { type: "update-selected-question_option-label"; payload: { i: number; label: string } }
   | { type: "update-selected-question_option-value"; payload: { i: number; value: string } }
   | { type: "update-selected-question_option-description"; payload: { i: number; description: string } }
   | { type: "update-selected-question_option-img"; payload: { i: number; img: string } }
   | { type: "update-selected-question_option-add" }
   | { type: "update-selected-question_option-delete"; payload: { i: number } }
   | { type: "update-selected-question_fu-add" }
   | { type: "update-selected-question_fu-del"; payload: { i: number } }
   | { type: "update-selected-question_fu-operator"; payload: { i: number; operator: string } }
   | { type: "update-selected-question_fu-cond-add"; payload: { i: number } }
   | { type: "update-selected-question_fu-cond-del"; payload: { i: number; j: number } }
   | { type: "update-selected-question_fu-cond-qid"; payload: { i: number; j: number; qid: string } }
   | { type: "update-selected-question_fu-cond-op"; payload: { i: number; j: number; op: string } }
   | { type: "update-selected-question_fu-cond-val"; payload: { i: number; j: number; val: string } }
   | { type: "update-selected-question_fu-ques-sel"; payload: { i: number; l: number; qid: string } }
   | { type: "update-selected-question_fu-ques-add"; payload: { i: number } }
   | { type: "update-selected-question_rej-add" }
   | { type: "update-selected-question_rej-del"; payload: { i: number } }
   | { type: "update-selected-question_fu-ques-del"; payload: { i: number; l: number } }
   | { type: "update-selected-question_rej-operator"; payload: { i: number; operator: string } }
   | { type: "update-selected-question_rej-cond-qid"; payload: { i: number; j: number; qid: string } }
   | { type: "update-selected-question_rej-cond-op"; payload: { i: number; j: number; op: string } }
   | { type: "update-selected-question_rej-cond-val"; payload: { i: number; j: number; val: string } }
   | { type: "update-selected-question_rej-cond-add"; payload: { i: number } }
   | { type: "update-selected-question_rej-cond-del"; payload: { i: number; j: number } }
   | { type: "update-selected-question_rej-header"; payload: { i: number; header: string } }
   | { type: "update-selected-question_rej-message"; payload: { i: number; message: string } }
   | { overwrite: Partial<Omit<TQEditor_State, "qIdSelected">> };

export const QEditorContext = createContext<{
   qeState: TQEditor_State;
   qeDispatch: Dispatch<TQE_Action>;
}>({
   qeState: initialState,
   qeDispatch: () => {},
});

const qEReducer = (state: TQEditor_State, action: TQE_Action): TQEditor_State => {
   if ("overwrite" in action) return { ...state, ...action.overwrite };
   switch (action.type) {
      case "update-selected-question_id": {
         const { id } = action.payload;
         if (!state.qSelected) return { ...state };
         return { ...state, qSelected: { ...state.qSelected, id } as TWidget_Question_Raw };
      }
      case "update-selected-question_rej-message": {
         const { i, message } = action.payload;
         if (!state.qSelected) return { ...state };
         const rejections = "rejections" in state.qSelected ? state.qSelected.rejections || [] : [];
         const newRejection = rejections.map((r, index) =>
            index === i ? { ...r, rejection: { ...r.rejection, message } } : r
         );
         return { ...state, qSelected: { ...state.qSelected, rejections: newRejection } as TWidget_Question_Raw };
      }
      case "update-selected-question_rej-header": {
         const { i, header } = action.payload;
         if (!state.qSelected) return { ...state };
         const rejections = "rejections" in state.qSelected ? state.qSelected.rejections || [] : [];
         const newRejection = rejections.map((r, index) =>
            index === i ? { ...r, rejection: { ...r.rejection, header } } : r
         );
         return { ...state, qSelected: { ...state.qSelected, rejections: newRejection } as TWidget_Question_Raw };
      }
      case "update-selected-question_rej-del": {
         const { i } = action.payload;
         if (!state.qSelected) return { ...state };
         const rejections = "rejections" in state.qSelected ? state.qSelected.rejections || [] : [];
         const newRejection = rejections.filter((_, index) => index !== i);
         return { ...state, qSelected: { ...state.qSelected, rejections: newRejection } as TWidget_Question_Raw };
      }
      case "update-selected-question_rej-add": {
         if (!state.qSelected) return { ...state };
         const rejections = "rejections" in state.qSelected ? state.qSelected.rejections || [] : [];
         const newRejection = [...rejections, { operator: "and", conditions: [{ operator: "eq", value: "" }] }];
         return { ...state, qSelected: { ...state.qSelected, rejections: newRejection } as TWidget_Question_Raw };
      }
      case "update-selected-question_rej-cond-del": {
         const { i, j } = action.payload;
         if (!state.qSelected) return { ...state };
         const rejections = "rejections" in state.qSelected ? state.qSelected.rejections || [] : [];
         const newRejection = rejections.map((r, index) =>
            index === i ? { ...r, conditions: r.conditions.filter((_, index) => index !== j) } : r
         );
         return { ...state, qSelected: { ...state.qSelected, rejections: newRejection } as TWidget_Question_Raw };
      }
      case "update-selected-question_rej-cond-add": {
         const { i } = action.payload;
         if (!state.qSelected) return { ...state };
         const rejections = "rejections" in state.qSelected ? state.qSelected.rejections || [] : [];
         const newRejection = rejections.map((r, index) =>
            index === i ? { ...r, conditions: [...r.conditions, { operator: "eq", value: "" }] } : r
         );
         return { ...state, qSelected: { ...state.qSelected, rejections: newRejection } as TWidget_Question_Raw };
      }
      case "update-selected-question_rej-cond-val": {
         const { i, j, val } = action.payload;
         if (!state.qSelected) return { ...state };
         const rejections = "rejections" in state.qSelected ? state.qSelected.rejections || [] : [];
         const newRejection = rejections.map((r, index) =>
            index === i
               ? { ...r, conditions: r.conditions.map((c, index) => (index === j ? { ...c, value: val } : c)) }
               : r
         );
         return { ...state, qSelected: { ...state.qSelected, rejections: newRejection } as TWidget_Question_Raw };
      }
      case "update-selected-question_rej-cond-op": {
         const { i, op } = action.payload;
         if (!state.qSelected) return { ...state };
         const rejections = "rejections" in state.qSelected ? state.qSelected.rejections || [] : [];
         const newRejection = rejections.map((r, index) => (index === i ? { ...r, operator: op } : r));
         return { ...state, qSelected: { ...state.qSelected, rejections: newRejection } as TWidget_Question_Raw };
      }
      case "update-selected-question_rej-cond-qid": {
         const { i, j, qid } = action.payload;
         if (!state.qSelected) return { ...state };
         const rejections = "rejections" in state.qSelected ? state.qSelected.rejections || [] : [];
         const newRejection = rejections.map((r, index) =>
            index === i ? { ...r, conditions: r.conditions.map((c, index) => (index === j ? { ...c, qid } : c)) } : r
         );
         return { ...state, qSelected: { ...state.qSelected, rejections: newRejection } as TWidget_Question_Raw };
      }
      case "update-selected-question_rej-operator": {
         const { i, operator } = action.payload;
         if (!state.qSelected) return { ...state };
         const rejections = "rejections" in state.qSelected ? state.qSelected.rejections || [] : [];
         const newRejection = rejections.map((r, index) => (index === i ? { ...r, operator } : r));
         return { ...state, qSelected: { ...state.qSelected, rejections: newRejection } as TWidget_Question_Raw };
      }
      case "update-selected-question_fu-add": {
         if (!state.qSelected) return { ...state };
         const followUps = "followUps" in state.qSelected ? state.qSelected.followUps : [];
         const newFollowUps = [
            ...followUps,
            { operator: "and", conditions: [{ operator: "eq", value: "" }], questions: [""] },
         ] as TWidget_Question_Raw["followUps"];
         return { ...state, qSelected: { ...state.qSelected, followUps: newFollowUps } };
      }
      case "update-selected-question_fu-del": {
         const { i } = action.payload;
         if (!state.qSelected) return { ...state };
         const followUps = "followUps" in state.qSelected ? state.qSelected.followUps : [];
         const newFollowUps = followUps.filter((_, index) => index !== i) as TWidget_Question_Raw["followUps"];
         return { ...state, qSelected: { ...state.qSelected, followUps: newFollowUps } };
      }
      case "update-selected-question_fu-ques-del": {
         const { i, l } = action.payload;
         if (!state.qSelected) return { ...state };
         const followUps = "followUps" in state.qSelected ? state.qSelected.followUps : [];
         const newFollowUps = followUps.map((fu, index) =>
            index === i ? { ...fu, questions: fu.questions.filter((_, index) => index !== l) } : fu
         ) as TWidget_Question_Raw["followUps"];
         return { ...state, qSelected: { ...state.qSelected, followUps: newFollowUps } };
      }
      case "update-selected-question_fu-ques-add": {
         const { i } = action.payload;
         if (!state.qSelected) return { ...state };
         const followUps = "followUps" in state.qSelected ? state.qSelected.followUps : [];
         const newFollowUps = followUps.map((fu, index) =>
            index === i ? { ...fu, questions: [...fu.questions, ""] } : fu
         ) as TWidget_Question_Raw["followUps"];
         return { ...state, qSelected: { ...state.qSelected, followUps: newFollowUps } };
      }
      case "update-selected-question_fu-ques-sel": {
         const { i, l, qid } = action.payload;
         if (!state.qSelected) return { ...state };
         const followUps = "followUps" in state.qSelected ? state.qSelected.followUps : [];
         const newFollowUps = followUps.map((fu, index) =>
            index === i ? { ...fu, questions: fu.questions.map((q, index) => (index === l ? qid : q)) } : fu
         ) as TWidget_Question_Raw["followUps"];
         return { ...state, qSelected: { ...state.qSelected, followUps: newFollowUps } };
      }
      case "update-selected-question_fu-cond-val": {
         const { i, j, val } = action.payload;
         if (!state.qSelected) return { ...state };
         const followUps = "followUps" in state.qSelected ? state.qSelected.followUps : [];
         const newFollowUps = followUps.map((fu, index) =>
            index === i
               ? { ...fu, conditions: fu.conditions.map((c, index) => (index === j ? { ...c, value: val } : c)) }
               : fu
         ) as TWidget_Question_Raw["followUps"];
         return { ...state, qSelected: { ...state.qSelected, followUps: newFollowUps } };
      }
      case "update-selected-question_fu-cond-op": {
         const { i, j, op } = action.payload;
         if (!state.qSelected) return { ...state };
         const followUps = "followUps" in state.qSelected ? state.qSelected.followUps : [];
         const newFollowUps = followUps.map((fu, index) =>
            index === i
               ? { ...fu, conditions: fu.conditions.map((c, index) => (index === j ? { ...c, operator: op } : c)) }
               : fu
         ) as TWidget_Question_Raw["followUps"];
         return { ...state, qSelected: { ...state.qSelected, followUps: newFollowUps } };
      }
      case "update-selected-question_fu-cond-qid": {
         const { i, j, qid } = action.payload;
         if (!state.qSelected) return { ...state };
         const followUps = "followUps" in state.qSelected ? state.qSelected.followUps : [];
         const newFollowUps = followUps.map((fu, index) =>
            index === i ? { ...fu, conditions: fu.conditions.map((c, index) => (index === j ? { ...c, qid } : c)) } : fu
         ) as TWidget_Question_Raw["followUps"];
         return { ...state, qSelected: { ...state.qSelected, followUps: newFollowUps } };
      }
      case "update-selected-question_fu-cond-del": {
         const { i, j } = action.payload;
         if (!state.qSelected) return { ...state };
         const followUps = "followUps" in state.qSelected ? state.qSelected.followUps : [];
         const newFollowUps = followUps.map((fu, index) =>
            index === i ? { ...fu, conditions: fu.conditions.filter((_, index) => index !== j) } : fu
         ) as TWidget_Question_Raw["followUps"];
         return { ...state, qSelected: { ...state.qSelected, followUps: newFollowUps } };
      }
      case "update-selected-question_fu-cond-add": {
         const { i } = action.payload;
         if (!state.qSelected) return { ...state };
         const followUps = "followUps" in state.qSelected ? state.qSelected.followUps : [];
         const newFollowUps = followUps.map((fu, index) =>
            index === i ? { ...fu, conditions: [...fu.conditions, { operator: "eq", value: "" }] } : fu
         ) as TWidget_Question_Raw["followUps"];
         return { ...state, qSelected: { ...state.qSelected, followUps: newFollowUps } };
      }
      case "update-selected-question_fu-operator": {
         const { i, operator } = action.payload;
         if (!state.qSelected) return { ...state };
         const followUps = "followUps" in state.qSelected ? state.qSelected.followUps : [];
         const newFollowUps = followUps.map((fu, index) => (index === i ? { ...fu, operator } : fu));
         return { ...state, qSelected: { ...state.qSelected, followUps: newFollowUps } as TWidget_Question_Raw };
      }
      case "update-selected-question_option-delete": {
         if (!state.qSelected) return { ...state };
         const options = "options" in state.qSelected ? state.qSelected.options : [];
         const newOptions = options.filter((_, i) => i !== action.payload.i);
         return { ...state, qSelected: { ...state.qSelected, options: newOptions } as TWidget_Question_Raw };
      }
      case "update-selected-question_option-add": {
         if (!state.qSelected) return { ...state };
         const options = "options" in state.qSelected ? state.qSelected.options : [];
         const newOptions = [...options, { label: "", value: "", description: "", img: "" }];
         return { ...state, qSelected: { ...state.qSelected, options: newOptions } as TWidget_Question_Raw };
      }
      case "update-selected-question_option-img": {
         const { i, img } = action.payload;
         if (!state.qSelected) return { ...state };
         const options = "options" in state.qSelected ? state.qSelected.options : [];
         const newOptions = options.map((o, index) => (index === i ? { ...o, img } : o));
         return { ...state, qSelected: { ...state.qSelected, options: newOptions } as TWidget_Question_Raw };
      }
      case "update-selected-question_option-description": {
         const { i, description } = action.payload;
         if (!state.qSelected) return { ...state };
         const options = "options" in state.qSelected ? state.qSelected.options : [];
         const newOptions = options.map((o, index) => (index === i ? { ...o, description } : o));
         return { ...state, qSelected: { ...state.qSelected, options: newOptions } as TWidget_Question_Raw };
      }
      case "update-selected-question_option-label": {
         const { i, label } = action.payload;
         if (!state.qSelected) return { ...state };
         const options = "options" in state.qSelected ? state.qSelected.options : [];
         const option = options[i];
         const newOption = {
            ...option,
            label,
            value: !option.value || option.value.startsWith("#") ? "#" + label : option.value,
         };
         const newOptions = options.map((o, index) => (index === i ? newOption : o));
         return { ...state, qSelected: { ...state.qSelected, options: newOptions } as TWidget_Question_Raw };
      }
      case "update-selected-question_option-value": {
         const { i, value } = action.payload;
         if (!state.qSelected) return { ...state };
         const options = "options" in state.qSelected ? state.qSelected.options : [];
         const newOptions = options.map((o, index) => (index === i ? { ...o, value } : o));
         return { ...state, qSelected: { ...state.qSelected, options: newOptions } as TWidget_Question_Raw };
      }
      case "update-selected-question_style": {
         const { style } = action.payload;
         if (!state.qSelected) return { ...state };
         return { ...state, qSelected: { ...state.qSelected, style } as TWidget_Question_Raw };
      }
      case "update-selected-question_type": {
         const { type } = action.payload;
         if (!state.qSelected) return { ...state };
         const style =
            type === "text"
               ? "short-input"
               : type === "select"
               ? "text-list-short"
               : "style" in state.qSelected
               ? state.qSelected.style
               : "short-input";
         return { ...state, qSelected: { ...state.qSelected, type, style } as TWidget_Question_Raw };
      }
      case "update-selected-question_multipleChoice": {
         const { multipleChoice } = action.payload;
         if (!state.qSelected) return { ...state };
         return { ...state, qSelected: { ...state.qSelected, multipleChoice } as TWidget_Question_Raw };
      }
      case "update-selected-question_allowSkip": {
         const { allowSkip } = action.payload;
         if (!state.qSelected) return { ...state };
         return { ...state, qSelected: { ...state.qSelected, allowSkip } as TWidget_Question_Raw };
      }
      case "update-selected-question_description": {
         const { description } = action.payload;
         if (!state.qSelected) return { ...state };
         return { ...state, qSelected: { ...state.qSelected, description } as TWidget_Question_Raw };
      }
      case "update-selected-question_question": {
         const { question } = action.payload;
         if (!state.qSelected) return { ...state };
         return { ...state, qSelected: { ...state.qSelected, question } as TWidget_Question_Raw };
      }
      case "update-selected-question_isUnitQuestion": {
         const { isUnitQuestion } = action.payload;
         if (!state.qSelected) return { ...state };
         return { ...state, qSelected: { ...state.qSelected, isUnitQuestion } };
      }
      case "select-question": {
         const { qId } = action.payload;
         const selectedQuestion = state.libraryQuestions.find((q) => q.id === qId) || {
            id: Date.now().toString(),
            followUps: [],
            options: [],
            type: "text",
            question: "",
            style: "short-input",
         };
         return { ...state, qIdSelected: action.payload.qId || "", qSelected: selectedQuestion };
      }
      case "flow-set": {
         const { flowCode, flowQuestions } = action.payload;

         return { ...state, flowCode, flowQuestions };
      }
      case "flow-drag-empty": {
         const { from: f, containerId: cid } = action.payload;
         const draggingQuestion = state.libraryQuestions.find((q) => q.id === f.id);
         if (!draggingQuestion) return { ...state };
         if (cid === "flowEditor") {
            const newFlow = [draggingQuestion.isUnitQuestion ? [f.id] : f.id, ...state.flowQuestions];
            return { ...state, flowQuestions: newFlow };
         }
         if (cid.startsWith("unitSet")) {
            const unitSetIndex = cid.split("|").pop();
            if (!unitSetIndex) return { ...state };
            const newFlow = [...state.flowQuestions];
            const unitSet = newFlow[Number(unitSetIndex)];
            if (!Array.isArray(unitSet)) return { ...state };
            unitSet.unshift(f.id);
            newFlow[Number(unitSetIndex)] = unitSet;
            return { ...state, flowQuestions: newFlow };
         }
         return { ...state };
      }
      case "flow-drag-end": {
         const { from: f, to: t } = action.payload;
         // console.log({ f, t });
         if (t.source !== "flow") return { ...state };
         if (f.source === "flow") {
            if (f.index === t.index && f.subIndex != null && t.subIndex != null && f.subIndex !== t.subIndex) {
               const newFlow = [...state.flowQuestions];
               // use arrayMove
               const item = newFlow[f.index] as string[];
               const newItem = arrayMove(item, f.subIndex, t.subIndex);
               newFlow[f.index] = newItem;
               return { ...state, flowQuestions: newFlow };
            }
            const newFlow = arrayMove(state.flowQuestions, f.index, t.index);
            return { ...state, flowQuestions: newFlow };
         }
         if (f.source === "library") {
            const newFlow = [...state.flowQuestions];
            const droppedQuestion = state.libraryQuestions.find((q) => q.id === f.id);
            // console.log({ droppedQuestion });
            if (!droppedQuestion) return { ...state };
            if (droppedQuestion.isUnitQuestion) {
               //handling unit question
               const unitSet = newFlow[t.index];
               // console.log({ unitSet });
               if (!Array.isArray(unitSet)) {
                  //*target not unit set
                  const newUnitSet = [f.id];
                  newFlow.splice(t.index, 0, newUnitSet);
                  return { ...state, flowQuestions: newFlow };
               }
               const newUnitSet = [...unitSet];
               newUnitSet.splice(t.subIndex ?? 0, 0, f.id);
               newFlow[t.index] = newUnitSet;
               console.log({ newUnitSet, newFlow });
               return { ...state, flowQuestions: newFlow };
            }
            //handling prop question
            // console.log({ newFlow });
            newFlow.splice(t.index, 0, f.id);
            return { ...state, flowQuestions: newFlow };
         }
         return { ...state };
      }
      case "flow-remove-question": {
         const { itemIndex, subItemIndex } = action.payload;
         // console.log({ itemIndex, subItemIndex });
         if (subItemIndex !== undefined) {
            const newFlow = [...state.flowQuestions];
            const itemAtIndex = newFlow[itemIndex];
            if (!Array.isArray(itemAtIndex)) return { ...state };
            //remove item at subIndex but if it's the last item in the array, remove the whole array
            const newItem = itemAtIndex.filter((_, i) => i !== subItemIndex);
            if (newItem.length) newFlow[itemIndex] = newItem;
            else newFlow.splice(itemIndex, 1);
            // console.log({newFlow})
            return { ...state, flowQuestions: [...newFlow] };
         }
         const newFlow = state.flowQuestions.filter((_, i) => i !== itemIndex);
         return { ...state, flowQuestions: newFlow };
      }

      case "add-question-from-question-library":
         return { ...state, libraryQuestions: [action.payload.question, ...state.libraryQuestions] };
      case "binding-question-selected-to-questing-setting":
         return { ...state, qIdSelected: action.payload.qIdSelected };
      default:
         return state;
   }
};

export const QEditorProvider = ({ children }: { children: React.ReactNode }) => {
   const [qeState, qeDispatch] = useReducer(qEReducer, initialState);
   return <QEditorContext.Provider value={{ qeState, qeDispatch }}>{children}</QEditorContext.Provider>;
};

export const useQEContext = () => {
   const context = useContext(QEditorContext);
   const { libraryQuestions } = context.qeState;
   const libraryQuestionsMap = useMemo(
      () =>
         libraryQuestions.reduce(
            (acc, q) => ({ ...acc, [q.id]: q }),
            {} as Record<string, TWidget_Question_Raw | undefined>
         ),
      [libraryQuestions]
   );
   const qeHelper = {
      libraryQuestionsMap,
   };
   return { ...context, qeHelper };
};

import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";

export const useQSoS_UserByEmail = ({ email }: TUseQSoS_UserByEmailProps) => {
   const { sosAxios } = useSosAxios();

   return useQuery({
      queryKey: ["useQSoS_UserByEmail", email],
      queryFn: () =>
         sosAxios.get(`/users/${email}`).then<SoS_GetUserByEmail_Response>(({ data }) => {
            return data;
         }),
   });
};

type TUseQSoS_UserByEmailProps = {
   email: string;
};

export type SoS_GetUserByEmail_User = {
   email: string;
   name: string;
   roles: string[];
};

export type SoS_GetUserByEmail_Response = { user: SoS_GetUserByEmail_User };

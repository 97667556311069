import { SHrPopup, SHrSosButton } from "@simplyhomes/react";
import { useState } from "react";
import styles from "./SoSConfirmWrapper.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
export const SoSConfirmWrapper = (p: TSoSConfirmWrapperProps) => {
   const { children, onCancel = () => {}, onConfirm = () => {}, setShow: externalSetShow, show: externalShow } = p;
   const [internalShow, setInternalShow] = useState(false);

   const isExternalShow = externalShow !== undefined && externalSetShow !== undefined;
   const show = isExternalShow ? externalShow : internalShow;
   const setShow = (b: boolean, confirm?: boolean) => {
      if (isExternalShow) externalSetShow(b);
      else setInternalShow(b);
      if (confirm != null) {
         if (confirm) onConfirm();
         else onCancel();
      }
   };

   return (
      <SHrPopup show={show} handleShow={setShow} triggerElement={children}>
         {"customConfirm" in p ? (
            p.customConfirm
         ) : p.type === "default" ? (
            <SoSConfirmWrapperDefault Confirm={(confirm) => setShow(false, confirm)} />
         ) : null}
      </SHrPopup>
   );
};

type TSoSConfirmWrapperProps = {
   children: React.ReactNode;
   onConfirm?: () => void;
   onCancel?: () => void;
} & ({ show: boolean; setShow: (show: boolean) => void } | { show?: undefined; setShow?: undefined }) &
   (({ type: "default" } & SoSConfirmWrapperDefaultProps) | { customConfirm: React.ReactNode });

type ChildrenBaseProps = { Confirm: (b: boolean) => void; yes?: string; no?: string };

type SoSConfirmWrapperDefaultProps = { title?: string; content?: string };
const SoSConfirmWrapperDefault = (p: SoSConfirmWrapperDefaultProps & ChildrenBaseProps) => {
   const { Confirm, content, title, no, yes } = p;
   return (
      <div className={`${styles.default}`}>
         <div className={`${styles.defaultTopC}`}>
            <b>{title || "Confirm"}</b>
            <button title="close" type="button" onClick={() => Confirm(false)}>
               <img src={CF_CDN_URL("/assets/question-editor/close_neutral.svg")} alt="close" />
            </button>
         </div>
         <div className={`${styles.defaultBodyC}`}>{content || "Are you sure?"}</div>
         <div className={`${styles.defaultBotC}`}>
            <SHrSosButton type="dangerous" buttonProps={{ onClick: () => Confirm(false) }}>
               {no || "No"}
            </SHrSosButton>
            <SHrSosButton type="positive" buttonProps={{ onClick: () => Confirm(true) }}>
               {yes || "Yes"}
            </SHrSosButton>
         </div>
      </div>
   );
};

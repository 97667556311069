import { createContext, useContext, useReducer } from "react";

export type TCsvUpState = {
   stage: "upload" | "preview" | "success" | "error";
   properties: TProperty[];
};
const csvUpInitialState: TCsvUpState = {
   stage: "upload",
   properties: [],
};
type TCsvUpAction = { overwrite: Partial<TCsvUpState> };

export const CsvUpContext = createContext<{
   csvUpState: TCsvUpState;
   csvUpDispatch: React.Dispatch<TCsvUpAction>;
}>({
   csvUpState: csvUpInitialState,
   csvUpDispatch: () => {},
});

const csvUpReducer = (state: TCsvUpState, action: TCsvUpAction): TCsvUpState => {
   if ("overwrite" in action) return { ...state, ...action.overwrite };
   return state;
};

export const CsvUpProvider = ({ children }: { children: React.ReactNode }) => {
   const [csvUpState, csvUpDispatch] = useReducer(csvUpReducer, csvUpInitialState);
   return <CsvUpContext.Provider value={{ csvUpState, csvUpDispatch }}>{children}</CsvUpContext.Provider>;
};

export const useCsvUpContext = () => useContext(CsvUpContext);

export type TProperty = {
   propertyType: string;
   // unitCount: number; // multi = 2 , SFR = 1
   address: string; // {ADDRESS}, {CITY}, {STATE} {ZIP}, USA // 510 Transverse Ave, Pittsburgh, PA 15210, USA
   city: string;
   stateOrProvince: string;
   zipOrPostalCode: string;
   price: number;
   beds: number;
   baths: number;
   sqft: number;
   yearBuilt: number;
   mlsId: string;
   isValid: boolean;
   reason: string;
};

export type TPropertyCompact = {
   fullAddress: string;
   units: { beds: number; baths: number }[];
   price: number;
   sqft: number;
   yearBuilt: number;
   mlsId: string;
};

export const PROPERTY_STATUS_FAILED = ["bad"];

export enum PROPERTY_SCENARIO_PURPOSE_ENUM {
   CLEVELAND_MSL = "Cleveland MLS",
   PITTSBURG_MLS = "Pittsburg MLS",
}

type PropertySenarioPurposeType = keyof typeof PROPERTY_SCENARIO_PURPOSE_ENUM;

export type PropertyPurposeOptionType = {
   label: string;
   value: PropertySenarioPurposeType;
};

export const PROPERTY_PURPOSE_OPTIONS: PropertyPurposeOptionType[] = [
   {
      label: PROPERTY_SCENARIO_PURPOSE_ENUM.CLEVELAND_MSL,
      value: "CLEVELAND_MSL",
   },
   {
      label: PROPERTY_SCENARIO_PURPOSE_ENUM.PITTSBURG_MLS,
      value: "PITTSBURG_MLS",
   },
];

export const PROPERTY_DOCUMENT_HEADERS_BY_PURPOSE: Record<PropertySenarioPurposeType, string[]> = {
   CLEVELAND_MSL: [
      "PROPERTY TYPE",
      "ADDRESS",
      "CITY",
      "STATE OR PROVINCE",
      "ZIP OR POSTAL CODE",
      "PRICE",
      "BEDS",
      "BATHS",
      "SQUARE FEET",
      "YEAR BUILT",
      "MLS#",
   ],
   PITTSBURG_MLS: ["PROPERTY TYPE TEST", "ADDRESS", "CITY", "STATE OR PROVINCE", "ZIP OR POSTAL CODE"],
};

import { useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useQEContext } from "../../contexts/QEditorContext";
import { useQQE_Flow } from "../queries/QEditor/useQQE_Flow";
import { useSosAxios } from "../axios/useSosAxios";

export const useMQE_SaveFlow = () => {
   const { qeState } = useQEContext();
   const { flowCode, flowQuestions } = qeState;
   const { sosAxios } = useSosAxios();
   const [params] = useSearchParams();
   const fid = params.get("fid") || "";
   const qQE_Flow = useQQE_Flow({ fid });
   const body = { flow: { code: flowCode, questions: flowQuestions } };
   return useMutation({
      mutationFn: async () => {
         await sosAxios.put(`/qeditor/flows/${fid}`, body);
         qQE_Flow.refetch();
      },
   });
};

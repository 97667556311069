import classNames from "classnames/bind";
import styles from "./OffersAdditionalTerms.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { useQOffers_GetTermTemplateById } from "../../../../hooks/queries/offers/termTemplate/useQOffers_GetTermTemplateById";

const cx = classNames.bind(styles);
export const OffersAdditionalTerms = () => {
   const qOfferTermTemplate = useQOffers_GetTermTemplateById();

   const addtionalTermTemplates = qOfferTermTemplate.data?.template?.data.additional;

   return addtionalTermTemplates?.map((t, i) => (
      <div className={cx("additionalTermsC")} key={i}>
         <textarea onChange={() => {}} placeholder="Enter your text here" value={t} />
         <div className={cx("img-icon", "delete")}>
            <img src={CF_CDN_URL("/assets/delete_outline_black.svg")} alt="" />
         </div>
      </div>
   ));
};

import { useQueryParams } from "../../react/useQueryParams";

export const usePViQueryParams = () => {
   const { useSingleQueryParamsState, useMultiQueryParamsState } = useQueryParams();
   const [limit] = useSingleQueryParamsState("limit", "10");
   const [q, setQ] = useSingleQueryParamsState("q", "");
   const [status, setStatus] = useSingleQueryParamsState("status", "New");
   const [ptypes, setPtypes] = useMultiQueryParamsState("ptypes", []);
   const [markets, setMarkets] = useMultiQueryParamsState("markets", []);
   const [zips, setZips] = useMultiQueryParamsState("zips", []);
   const [view, setView] = useSingleQueryParamsState("view", "Grid View");
   const [minSourcePrice, setMinSourcePrice] = useSingleQueryParamsState("minSourcePrice");
   const [maxSourcePrice, setMaxSourcePrice] = useSingleQueryParamsState("maxSourcePrice");

   const filters = { limit, status, ptypes, markets, zips, q, minSourcePrice, maxSourcePrice };
   return {
      ...filters,
      filters,
      setPtypes,
      setMarkets,
      setZips,
      setStatus,
      setQ,
      view,
      setView,
      minSourcePrice,
      setMinSourcePrice,
      maxSourcePrice,
      setMaxSourcePrice,
   };
};

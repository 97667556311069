import { SHrPopup, SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import styles from "./PViPropertyDetails.module.scss";
import classNames from "classnames/bind";
import { useMPVi_QuickPickSource } from "../../../hooks/mutates/PVi/useMPVi_QuickPickSource";
import { PViAddress } from "./PViAddress/PViAddress";
import { PViPreviousNextProperty } from "./PViPreviousNextProperty/PViPreviousNextProperty";
import { PViMediaSlider } from "./PViMediaSlider/PViMediaSlider";
import { PViMissingData } from "./PViMissingData/PViMissingData";
import { usePViPropertyDetails } from "../../../hooks/sosApps/propertyViability/usePViPropertyDetails";
import { useState } from "react";
import { PViRejectModal } from "./PViRejectModal/PViRejectModal";
import { useQPVi_SourceStatuses } from "../../../hooks/queries/PVi/useQPVi_SourceStatuses";
import { useMPVi_UpdateSourceStatus } from "../../../hooks/mutates/PVi/useMPVi_UpdateSourceStatus";
import { PViConversation } from "./PViConversation/PViConversation";
import { PViPropertyDetailsTabs } from "./PViPropertyDetailsTabs/PViPropertyDetailsTabs";
import { PViPropertyDetailsTabOverview } from "../PViPropertyDetailsTabOverview/PViPropertyDetailsTabOverview";
const cx = classNames.bind(styles);

export const PViPropertyDetails = () => {
   const { source, qPViSource, isNew, pviOverwrite } = usePViPropertyDetails();

   const mPViQueue = useMPVi_QuickPickSource();

   const [isOpenRejectModal, setIsOpenRejectModal] = useState(false);

   const mPViUpdateSourceStatus = useMPVi_UpdateSourceStatus();

   const qPViStatuses = useQPVi_SourceStatuses();
   const newPropertiesCount = qPViStatuses.data?.statusMap?.New ?? 0;
   const loadingMsg = qPViSource.isFetching
      ? "Loading Property..."
      : mPViQueue.isMutating
      ? "Loading Next Property..."
      : null;
   if (loadingMsg)
      return (
         <article className={`${styles.contentC} ${styles.loading}`}>
            <SHrSpinner />
            <span>{loadingMsg}</span>
         </article>
      );

   const handleReviewLater = () => mPViUpdateSourceStatus.mutate({ status: "Unverified", getNextQuickPick: true });

   const handleReviewNow = () => mPViUpdateSourceStatus.mutate({ status: "Unverified", getNextQuickPick: false });

   const handleMarkVerified = () => mPViUpdateSourceStatus.mutate({ status: "Verified", getNextQuickPick: false });

   const handleValidateProperty = () => mPViUpdateSourceStatus.mutate({ status: "Viable", getNextQuickPick: false });

   if (!source)
      return (
         <article className={`${styles.contentC}`}>
            <div className={cx("no-prop")}>
               <div className={cx("close-btn")}>
                  <button
                     type="button"
                     title="close"
                     onClick={() => pviOverwrite({ showDetailsModal: false, excludedSourceIds: [], sid: "" })}
                  >
                     <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                  </button>
               </div>
               <span>No property to review</span>
            </div>
         </article>
      );
   const { status } = source;
   return (
      <article className={`${styles.contentC}`}>
         <SHrPopup show={isOpenRejectModal} type="center">
            <PViRejectModal toggle={() => setIsOpenRejectModal(false)} />
         </SHrPopup>
         <div className={`${styles.topC}`}>
            <div className={`${styles.top1}`}>
               <div>
                  {isNew ? (
                     <span className={cx("new")}>
                        New Properties Quick Pick ({newPropertiesCount - 1} left) with {status}
                     </span>
                  ) : (
                     <PViAddress />
                  )}
               </div>
               <div className={`${styles.top1Right}`}>
                  {!isNew && (
                     <div className={cx("statusAndBtnC")}>
                        <div className={cx("statusC")}>
                           <span>Status</span>
                           <span className={cx("status")}>{status}</span>
                        </div>
                        <div className={cx("divider-container")}>
                           <hr className={cx("divider")} />
                        </div>
                        <SHrSosButton
                           loading={mPViUpdateSourceStatus.isPending}
                           buttonProps={{
                              disabled: status === "Rejected",
                              className: cx("btn", "reject"),
                              onClick: () => setIsOpenRejectModal(true),
                           }}
                        >
                           Reject
                        </SHrSosButton>
                        <SHrSosButton
                           loading={mPViUpdateSourceStatus.isPending}
                           buttonProps={{
                              disabled: status === "Verified",
                              className: cx("btn", "mark-as-verified"),
                              onClick: handleMarkVerified,
                           }}
                        >
                           Mark as Verified
                        </SHrSosButton>
                        <SHrSosButton
                           loading={mPViUpdateSourceStatus.isPending}
                           buttonProps={{
                              disabled: status === "Viable",
                              className: cx("btn", "validate"),
                              onClick: handleValidateProperty,
                           }}
                        >
                           Validate
                        </SHrSosButton>
                     </div>
                  )}
                  <div className={cx("close-btn")}>
                     <button
                        type="button"
                        title="close"
                        onClick={() => pviOverwrite({ showDetailsModal: false, excludedSourceIds: [], sid: "" })}
                     >
                        <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                     </button>
                  </div>
               </div>
            </div>
            {isNew && (
               <div className={`${styles.top2}`}>
                  <PViAddress />
                  <PViPreviousNextProperty />
               </div>
            )}
         </div>
         <div className={`${styles.bodyC}`}>
            <div className={cx("topMapC")}>
               <PViMediaSlider />
               <PViMissingData />
            </div>

            <div className={cx("detailC")}>
               {isNew ? <PViPropertyDetailsTabOverview /> : <PViPropertyDetailsTabs />}
               <PViConversation />
            </div>
         </div>

         {isNew && (
            <div className={cx("controlsC")}>
               <SHrSosButton
                  loading={mPViUpdateSourceStatus.isPending}
                  buttonProps={{ className: cx("btn", "reject"), onClick: () => setIsOpenRejectModal(true) }}
               >
                  Reject
               </SHrSosButton>
               <SHrSosButton
                  loading={mPViUpdateSourceStatus.isPending}
                  buttonProps={{ className: cx("btn", "review-later"), onClick: handleReviewLater }}
               >
                  Review Later
               </SHrSosButton>
               <SHrSosButton
                  loading={mPViUpdateSourceStatus.isPending}
                  buttonProps={{ className: cx("btn", "review-now"), onClick: handleReviewNow }}
               >
                  Review Now
               </SHrSosButton>
            </div>
         )}
      </article>
   );
};

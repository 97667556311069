import classNames from "classnames/bind";
import { ReactNode } from "react";

import styles from "./MainLayout.module.scss";
import NavVertical from "./navVertical/NavVertical";
import NavHorizontal from "./navHorizontal/NavHorizontal";

import version from "../../version.json";
import { useQApiVersions } from "../../hooks/queries/versions/useQApiVersions";

const cx = classNames.bind(styles);

type MainLayoutProps = { children: ReactNode; appCode?: keyof typeof version };
const MainLayout = ({ children, appCode }: MainLayoutProps) => {
   return (
      <div className={cx("main-layout-container")}>
         <NavHorizontal />
         <div className={cx("bodyC")}>
            <NavVertical />
            <div className={cx("content")}>{children}</div>
         </div>
         <VersionMarker appCode={appCode} />
      </div>
   );
};
export default MainLayout;

const APPVersion = version["APP"].split("|")[0];
const VersionMarker = ({ appCode }: { appCode?: keyof typeof version }) => {
   const appVersion = appCode && version[appCode].split("|")[0];
   const qApiVers = useQApiVersions();
   const apiVersions = qApiVers.data?.[appCode || "APP"]?.split("|")[0];
   const versions: string[] = [`ENV_${process.env.REACT_APP_MODE}`, `SOS_${APPVersion}`];
   if (appVersion) versions.push(`APP_${appVersion}`);
   if (apiVersions) versions.push(`API_${apiVersions}`);
   return <div className={cx("version-marker")}>{versions.join(" | ")}</div>;
};

import classNames from "classnames/bind";
import styles from "./ErrorPage.module.scss";
import { CF_CDN_URL } from "../../utils/CF_CDN_URL";
import { SHrSosButton } from "@simplyhomes/react";
import { useNavigate } from "react-router-dom";
import { SoSFullLogo } from "../../components/common/SoSFullLogo/SoSFullLogo";

const cx = classNames.bind(styles);
const ErrorPage = ({ type }: ErrorPageProps) => {
   const nav = useNavigate();

   const errorData = data[type];
   return (
      <div className={cx("container")}>
         <div className={cx("bg-left")}>
            <img src={CF_CDN_URL(errorData.backgroundImageUrl)} alt="" />
         </div>
         <div className={cx(`bodyC`)}>
            <div className={cx(`logoC`)}>
               <SoSFullLogo />
            </div>
            <div className={cx(`body`)}>
               <div className={cx(`bodyTop`)}>
                  <img src={CF_CDN_URL(errorData.imageUrl)} alt="" />
               </div>
               <div className={cx(`bodyMid`)}>
                  <b>{errorData.message}</b>
                  <p>{errorData.description}</p>
               </div>
               <div className={cx(`bodyBot`)}>
                  <SHrSosButton type="filled" buttonProps={{ onClick: () => nav("/") }}>
                     Back to Home
                  </SHrSosButton>
               </div>
            </div>
         </div>
      </div>
   );
};
export default ErrorPage;

type ErrorPageProps = {
   type: errorType;
};
type errorType = "NotFound" | "Forbidden" | "Unauthorized" | "InternalServerError";
type errorData = { message: string; description: string; imageUrl: string; backgroundImageUrl: string };
const data: Record<errorType, errorData> = {
   NotFound: {
      message: "Oops. Something’s missing.",
      description: "This page doesn’t exist or was removed! We suggest you return to previous page.",
      imageUrl: "/assets/fallback/404_error.png",
      backgroundImageUrl: "/assets/fallback/bg_404_page.png",
   },
   Forbidden: {
      message: "Restricted Access",
      description: "Sorry, you don’t have permission to access this page.",
      imageUrl: "/assets/fallback/403_error.png",
      backgroundImageUrl: "/assets/fallback/bg_403_page.png",
   },
   Unauthorized: {
      message: "Unauthorized Access",
      description: "Access denied due to invalid credentials. Please log in and retry.",
      imageUrl: "/assets/fallback/401_error.png",
      backgroundImageUrl: "/assets/fallback/bg_401_page.png",
   },
   InternalServerError: {
      message: "Oops. Things just got out of hand.",
      description: "Please refresh the page or clear browser cache.",
      imageUrl: "/assets/fallback/500_error.png",
      backgroundImageUrl: "/assets/fallback/bg_500_page.png",
   },
};

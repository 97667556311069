import { useEffect, useRef } from "react";

export const useClickOutside = ({ onClickOutside }: TuseClickOutsideParams) => {
   const ref = useRef(null);
   useEffect(() => {
      const handleClickOutside = (event: any) =>
         ref.current && !(ref.current as any).contains(event.target) && onClickOutside();

      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
   }, [onClickOutside]);
   return { ref };
};
type TuseClickOutsideParams = {
   onClickOutside: () => void;
};

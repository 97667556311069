import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { usePViQueryParams } from "../../sosApps/propertyViability/usePViQueryParams";
import useDebounce from "../../react/useDebounce";

export const useQPVi_SourceStatuses = () => {
   const { sosAxios } = useSosAxios();
   const { filters} = usePViQueryParams();

   const baseParams = { ...filters };
   const dbBaseParams = useDebounce(baseParams, 500);

   const query = useQuery({
      queryKey: ["QPV_SourceStatuses", dbBaseParams],
      staleTime: 500,
      queryFn: () =>
         sosAxios
            .get("/property-viability/sources/statuses", { params: { ...dbBaseParams } })
            .then(({ data: { statuses } }: { data: { statuses: TStatuses } }) => {
               console.log("fetched source statuses");

               const defaultStatuses = ["New", "Unverified", "Verified", "Rejected", "Lost"];
               const baseStatuses = defaultStatuses.map(
                  (baseStatus) =>
                     statuses.find(({ status }) => status === baseStatus) || { status: baseStatus, count: "0" }
               );
               const newStatuses = statuses.filter(({ status }) => !defaultStatuses.includes(status));
               const sortedStatuses = [...baseStatuses, ...newStatuses];
               const statusMap = sortedStatuses.reduce(
                  (acc, { status, count }) => ({ ...acc, [status]: +count }),
                  {} as Record<string, number | undefined>
               );

               return { statuses: sortedStatuses, statusMap };
            }),
   });

   return query;
};

type TStatuses = { status: string; count: string }[];

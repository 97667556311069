import { usePViContext } from "../../../../contexts/PViContext";
import classNames from "classnames/bind";
import styles from "./PViPreviousNextProperty.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { useMPVi_QuickPickSource } from "../../../../hooks/mutates/PVi/useMPVi_QuickPickSource";

const cx = classNames.bind(styles);

export const PViPreviousNextProperty = () => {
   const { pviState, pviOverwrite } = usePViContext();
   const { excludedSourceIds } = pviState;
   const mPViQuickPickSource = useMPVi_QuickPickSource();
   const handleNext = () => {
      mPViQuickPickSource.mutate();
   };

   const handlePrevious = () => {
      const lastExcludedSourceId = excludedSourceIds[excludedSourceIds.length - 1];
      if (!lastExcludedSourceId) return;
      const newExcludedSourceIds = [...excludedSourceIds].toSpliced(-1, 1);
      pviOverwrite({ excludedSourceIds: newExcludedSourceIds, sid: lastExcludedSourceId });
   };

   return (
      <div className={cx("turningPageC")}>
         {excludedSourceIds.length > 0 && (
            <>
               <button className={cx("previousNextC")} onClick={handlePrevious}>
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/property_validator/keyboard_double_arrow_left_black.svg")} alt="" />
                  </div>
                  <span>Previous</span>
               </button>
               <div className={cx("divider-container")}>
                  <hr className={cx("divider")} />
               </div>
            </>
         )}
         <button className={cx("previousNextC")} onClick={handleNext}>
            <span>Next</span>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/property_validator/keyboard_double_arrow_right_black.svg")} alt="" />
            </div>
         </button>
      </div>
   );
};

import classNames from "classnames/bind";
import { usePViPropertyDetails } from "../../../../hooks/sosApps/propertyViability/usePViPropertyDetails";
import styles from "./PViMissingData.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { PViDataPointNumber } from "../PViDataPointNumber/PViDataPointNumber";
import { SHu_js_currencyFormat } from "@simplyhomes/utils";
const cx = classNames.bind(styles);

export const PViMissingData = () => {
   const { source } = usePViPropertyDetails();
   if (!source) return null;
   const totalBaths = source.units.map((u) => u.baths).reduce((acc, value) => acc + value, 0)!;

   return (
      <div className={cx("missingDataC")}>
         <div className={cx("headingC")}>
            <span>Missing/Unverified Data</span>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/property_validator/content_copy_black.svg")} alt="" />
            </div>
         </div>

         <div className={cx("dataPointC")}>
            <span>Square feet</span>
            <PViDataPointNumber value={source.sqft ?? "null"} />

            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/property_validator/verified_black.svg")} alt="" />
            </div>
         </div>

         <div className={cx("dataPointC")}>
            <span>Bathrooms</span>
            <PViDataPointNumber value={totalBaths} />
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/property_validator/verified_black.svg")} alt="" />
            </div>
         </div>

         <div className={cx("dataPointC")}>
            <span>Flood zone</span>
            <PViDataPointNumber value={"No"} isDefault />
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/property_validator/verified_black.svg")} alt="" />
            </div>
         </div>

         <div className={cx("dataPointC")}>
            <span>Asking Price</span>
            <PViDataPointNumber
               value={SHu_js_currencyFormat(source.price, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
            />
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/property_validator/verified_black.svg")} alt="" />
            </div>
         </div>
      </div>
   );
};

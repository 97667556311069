import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";

export const useQApiVersions = () => {
   const { sosAxios } = useSosAxios({ redirectOnError: false });
   return useQuery({
      queryKey: ["useQVersions"],
      queryFn: () =>
         sosAxios
            .get("/versions")
            .then<Record<string, string | undefined>>(({ data }) => data)
            .catch((er) => console.log({ er })),
   });
};

import styles from "./DevJim.module.scss";
import { SHrSosButton } from "@simplyhomes/react";
import { SoSConfirmWrapper } from "../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { SoSInputSelect, TSoSInputSelect_option } from "../../components/common/SoSInputSelect/SoSInputSelect";
import { useState } from "react";
export const DevJim = () => {
   return (
      <div className={`${styles.contentC}`}>
         <TestInputSelectTags />
         <TestConfirmWrapper />
      </div>
   );
};

const TestInputSelectTags = () => {
   const [selected, setSelected] = useState<TSoSInputSelect_option[]>([]);
   const options: TSoSInputSelect_option[] = [
      { label: "Option 1", value: "option1" },
      { label: "Option 2", value: "option2" },
      { label: "Option 3", value: "option3" },
      { label: "Option 4", value: "option4" },
   ];
   return (
      <div>
         <SoSInputSelect isMulti value={selected} onChange={setSelected} options={options} />
      </div>
   );
};

const TestConfirmWrapper = () => (
   <SoSConfirmWrapper type="default" onCancel={() => alert("Cancelled")} onConfirm={() => alert("CONFIRMED")}>
      <SHrSosButton type="dangerous" buttonProps={{ onClick: () => alert("DO NOT USE ONCLICK ON CHILDREN") }}>
         Delete
      </SHrSosButton>
   </SoSConfirmWrapper>
);

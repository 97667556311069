import { useQuery } from "@tanstack/react-query";
import { useQFns_AD } from "./useQFns_AD";

export const useQ_AD_subcribedApps = () => {
   const { qfns_AD } = useQFns_AD();
   return useQuery({
      queryKey: ["subscribedApps"],
      queryFn: qfns_AD.q_getSubscribedApps,
   });
};



import { useState } from "react";
// import { SoSInputSelect, TSoSInputSelect_customOption } from "../../components/common/SoSInputSelect/SoSInputSelect";
import styles from "./Dev.module.scss";
// import ReactSelect from "react-select";
// import { Widget } from "../../components/widget/Widget";
// import { SoSInputTitle } from "../../components/common/soSInputTitle/SoSInputTitle";
import ModalComponent from "../../components/modalComponent/ModalComponent";
import classNames from "classnames/bind";
import { CF_CDN_URL } from "../../utils/CF_CDN_URL";
import { SHrPopup, SHrSosButton } from "@simplyhomes/react";
import SoSSlider, { SoSSlider_Item } from "../../components/SoSSlider/SoSSlider";

const cx = classNames.bind(styles);

export const Dev = () => {
   // const [value, setValue] = useState({ label: "Option 1", value: "option1" });
   // const [internalValue, setInternalValue] = useState("");
   const options = [
      { label: "Option 1", value: "option1" },
      { label: "Option 2", value: "option2" },
      { label: "Option 3", value: "option3" },
      { label: "Option 4", value: "option4" },
      { label: "Option 3", value: "option3" },
      { label: "Option 4", value: "option4" },
      { label: "Option 3", value: "option3" },
      { label: "Option 4", value: "option4" },
      { label: "Option 3", value: "option3" },
      { label: "Option 4", value: "option4" },
   ];
   // const customOptions: TSoSInputSelect_customOption[] = internalValue
   //    ? [{ label: `Custom Create ${internalValue}`, cb: ({ label }) => alert(`${label},${internalValue}`) }]
   //    : [];
   // const test = (e: any) => {
   //    console.log({ e });
   //    setValue(e);
   // };
   // const [widgetCode, setWidgetCode] = useState("123");

   const [isOpen, setIsOpen] = useState(false);

   console.log(isOpen);
   const items = [
      "https://www.simplyhomes.com/hs-fs/hubfs/family-experience_800w.jpg?width=500&name=family-experience_800w.jpg",
      "https://www.simplyhomes.com/hubfs/Moving_scene_04_800w.png",
      "https://www.simplyhomes.com/hubfs/Offer-engine-warm_with_Simply_Sam_slightcrop.png",
      "https://www.simplyhomes.com/hubfs/family%20transparent.png",
      "https://www.simplyhomes.com/hubfs/stocksy-3049078.png",
   ];
   return (
      <div className={`${styles.contentC}`}>
         <div className={`${styles.sliderC}`}>
            <SoSSlider items={items.map((src) => ({ type: "image", src }))} />
         </div>

         <SHrSosButton buttonProps={{ onClick: () => setIsOpen(!isOpen) }}>Open modal</SHrSosButton>
      </div>
   );
};

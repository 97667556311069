import classNames from "classnames/bind";
import styles from "./OffersEditTermTemplateModal.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { useMemo, useState } from "react";
import { SHrSosButton, SHrSosTabsBar, SHrSpinner } from "@simplyhomes/react";
import { SoSConfirmWrapper } from "../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { SoSInputSelect, TSoSInputSelect_option } from "../../../components/common/SoSInputSelect/SoSInputSelect";
import { useQOffers_GetTermTemplates } from "../../../hooks/queries/offers/termTemplate/useQOffers_GetTermTemplates";
import { useOffersContext } from "../../../contexts/OffersContext";
import { useQOffers_GetTermTemplateById } from "../../../hooks/queries/offers/termTemplate/useQOffers_GetTermTemplateById";
import { useMTT_CreateTermTemplate } from "../../../hooks/mutates/offers/termTemplate/useMTT_CreateTermTemplate";
import { OffersAdditionalTerms } from "./OffersAdditionalTerms/OffersAdditionalTerms";
import { OffersSimplyTerms } from "./OffersSimplyTerms/OffersSimplyTerms";
const cx = classNames.bind(styles);

export const OffersEditTermTemplateModal = ({ toggel }: TOffersEditTermTemplateModalProps) => {
   const { offersState, offersDispatch } = useOffersContext();
   const { ttid } = offersState;

   const [activeTab, setActiveTab] = useState("Simply Terms");
   const [triggerAddNewItem, setTriggerAddNewItem] = useState(false);

   const qOfferTermTemplates = useQOffers_GetTermTemplates();
   const qOfferTermTemplate = useQOffers_GetTermTemplateById();

   const { mutate } = useMTT_CreateTermTemplate();

   const termTemplateOptions: TSoSInputSelect_option[] = useMemo(
      () => qOfferTermTemplates.data?.templates.map((t) => ({ label: t?.name, value: t?.tid.toString() })) || [],
      [qOfferTermTemplates.data?.templates]
   );

   const termTemplateSelected = {
      label: qOfferTermTemplate.data?.template?.name || "",
      value: qOfferTermTemplate.data?.template?.tid.toString() || "",
   };

   const handleCreateNewTermTemplate = (value: string) => {
      mutate({ name: value });
   };

   return (
      <div className={cx("container")}>
         <div className={cx("headingC")}>
            <div className={cx("headingLeftC")}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/offer/list_black.svg")} alt="" />
               </div>
               <span>Edit Terms Template</span>
            </div>
            <div className={cx("headingRightC")}>
               <div className={cx("input-search")}>
                  <SoSInputSelect
                     placeholder="Template Name"
                     isMulti={false}
                     value={termTemplateSelected}
                     onChange={(o) => offersDispatch({ overwrite: { ttid: o.value } })}
                     options={termTemplateOptions}
                     isLoading={!termTemplateOptions.length}
                     allowCreate={{ cb: ({ value }) => handleCreateNewTermTemplate(value) }}
                  />
               </div>

               <div className={cx("closeBtn")}>
                  <button type="button" title="close" onClick={toggel}>
                     <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                  </button>
               </div>
            </div>
         </div>
         {ttid ? (
            <>
               <div className={cx("tabs")}>
                  <SHrSosTabsBar tabs={tabs} value={activeTab} onChange={setActiveTab} />
                  {ttid && (
                     <SHrSosButton
                        type="text"
                        buttonProps={{ className: cx("btn"), onClick: () => setTriggerAddNewItem(!triggerAddNewItem) }}
                     >
                        <div className={cx("img-icon")}>
                           <img src={CF_CDN_URL("/assets/add_box_black.svg")} alt="" />
                        </div>
                        Add new Term
                     </SHrSosButton>
                  )}
               </div>
               <div className={cx("tearmsSetC")}>
                  {qOfferTermTemplate.isLoading && qOfferTermTemplate.isFetching && (
                     <div className={cx("spinnerC")}>
                        <SHrSpinner />
                     </div>
                  )}

                  {activeTab === "Simply Terms" && <OffersSimplyTerms triggerAddNewItem={triggerAddNewItem} />}
                  {activeTab === "Additional Terms" && <OffersAdditionalTerms />}
               </div>
               <SoSConfirmWrapper type="default" onCancel={() => {}} onConfirm={() => {}}>
                  <div className={cx("btnSave")}>
                     <SHrSosButton buttonProps={{ className: cx("") }}>Save Template</SHrSosButton>
                  </div>
               </SoSConfirmWrapper>
            </>
         ) : (
            <div className={cx("noDataC")}>No Selected Term Template!</div>
         )}
      </div>
   );
};

type TOffersEditTermTemplateModalProps = {
   toggel: () => void;
};
const tabs = ["Simply Terms", "Additional Terms"];

import classNames from "classnames/bind";
import styles from "./PViUnitDetailsTab.module.scss";
import { CF_CDN_URL } from "../../../../../utils/CF_CDN_URL";
import { PViDataPoint } from "../../PViDataPoint/PViDataPoint";
import { SHrSosButton } from "@simplyhomes/react";
import { usePViPropertyDetails } from "../../../../../hooks/sosApps/propertyViability/usePViPropertyDetails";

const cx = classNames.bind(styles);

export const PViUnitDetailsTab = () => {
   const { source } = usePViPropertyDetails();
   if (!source) return null;
   const { units } = source;
   return units.map((_, i) => (
      <div className={cx("unitDetailTabC")}>
         <PViUnitDetail unitIndex={i} key={i} />
         <div className={cx("addPhotosC")}>
            <SHrSosButton buttonProps={{ className: cx("btnAdd") }} type="outlined">
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/plus_white.svg")} alt="" />
               </div>
               <span>Add Unit</span>
            </SHrSosButton>
         </div>
      </div>
   ));
};

const PViUnitDetail = ({ unitIndex }: { unitIndex: number }) => {
   const { source } = usePViPropertyDetails();
   if (!source) return null;
   const { units } = source;
   const unit = units[unitIndex];
   if (!unit) return null;
   return (
      <div className={cx("container")}>
         <div className={cx("heading-title")}>
            <span>Unit {unitIndex}</span>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/delete_outline_black.svg")} alt="" />
            </div>
         </div>
         <div className={cx("unitDetailC")}>
            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Rooms & Stories</b>
                  </span>
               </div>
               <div className={cx("unitDataC")}>
                  <PViDataPoint
                     title="Bedrooms"
                     auditKey={`unit_${unitIndex}_beds`}
                     type="bold-title"
                     value={unit.beds}
                     displayValue={`${unit.beds > 1 ? `${unit.beds} Beds` : `${unit.beds} Bed`}`}
                     icon="bedroom"
                  />
                  <PViDataPoint
                     title="Bathrooms"
                     auditKey={`unit_${unitIndex}_baths`}
                     type="bold-title"
                     value={unit.baths}
                     displayValue={`${unit.baths > 1 ? `${unit.baths} Baths` : `${unit.baths} Bath`}`}
                     icon="bathroom"
                  />
                  <PViDataPoint
                     title="Stories"
                     auditKey={`unit_${unitIndex}_baths`}
                     type="bold-title"
                     value={"N/A"}
                     displayValue={"N/A"}
                     icon="bathroom"
                     isMocked
                  />
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>HCV</b>
                  </span>
               </div>

               <div className={cx("unitDataC")}>
                  <PViDataPoint
                     title="HCV Payment Standard"
                     type="bold-title"
                     value={1500}
                     isMocked
                     format="currency"
                  />
                  <PViDataPoint
                     title="HCV Total Utility Allowance"
                     type="bold-title"
                     value={1500}
                     isMocked
                     format="currency"
                  />
                  <PViDataPoint
                     title="HCV Estimated Gross Rent"
                     type="bold-title"
                     value={1500}
                     isMocked
                     format="currency"
                  />
                  <PViDataPoint title="HCV Payment standard Source" type="bold-title" value={"source"} isMocked />
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Leasing</b>
                  </span>
               </div>

               <div className={cx("leasingC")}>
                  <div className={cx("leasingLeftC")}>
                     <PViDataPoint title="Current Lease" type="bold-title" value={1500} isMocked format="currency" />
                     <PViDataPoint
                        title="Current Lease Rent"
                        type="bold-title"
                        value={1500}
                        isMocked
                        format="currency"
                     />
                     <PViDataPoint
                        title="Current Lease End"
                        type="bold-title"
                        value={1500}
                        isMocked
                        format="currency"
                     />
                     <PViDataPoint
                        title="Current Lease Type"
                        type="bold-title"
                        value={1500}
                        isMocked
                        format="currency"
                     />
                  </div>

                  <div className={cx("leasingRightC")}>
                     <div className={cx("inspectionAsset")}>
                        <div className={cx("headingC")}>
                           <span>
                              <b>Initial SOW</b>
                           </span>
                        </div>

                        <SHrSosButton type="tonal" buttonProps={{ className: cx("uploadBtn") }}>
                           Upload a file
                        </SHrSosButton>

                        <div className={cx("linkC")}>
                           <div className={cx("linkUploadC")}>
                              <div className={cx("img-icon")}>
                                 <img src={CF_CDN_URL("/assets/property_validator/picture_as_pdf_black.svg")} alt="" />
                              </div>
                              <span>File name.pdf</span>
                              <div className={cx("linkIconsC")}>
                                 <div className={cx("img-icon")}>
                                    <img
                                       src={CF_CDN_URL(
                                          "/assets/property_validator/drive_file_rename_outline_black.svg"
                                       )}
                                       alt=""
                                    />
                                 </div>
                                 <div className={cx("img-icon")}>
                                    <img src={CF_CDN_URL("/assets/property_validator/download_black.svg")} alt="" />
                                 </div>
                                 <div className={cx("img-icon")}>
                                    <img src={CF_CDN_URL("/assets/property_validator/delete_black.svg")} alt="" />
                                 </div>
                              </div>
                           </div>
                           <div className={cx("linkUploadC")}>
                              <div className={cx("img-icon")}>
                                 <img
                                    src={CF_CDN_URL("/assets/property_validator/insert_drive_file_black.svg")}
                                    alt=""
                                 />
                              </div>
                              <span>File name.pdf</span>
                              <div className={cx("linkIconsC")}>
                                 <div className={cx("img-icon")}>
                                    <img
                                       src={CF_CDN_URL(
                                          "/assets/property_validator/drive_file_rename_outline_black.svg"
                                       )}
                                       alt=""
                                    />
                                 </div>
                                 <div className={cx("img-icon")}>
                                    <img src={CF_CDN_URL("/assets/property_validator/download_black.svg")} alt="" />
                                 </div>
                                 <div className={cx("img-icon")}>
                                    <img src={CF_CDN_URL("/assets/property_validator/delete_black.svg")} alt="" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Heating Source Type</b>
                  </span>
               </div>

               <div className={cx("unitDataC")}>
                  <PViDataPoint title="Heating Source Type" type="bold-title" value={1500} isMocked format="currency" />
                  <PViDataPoint
                     title="Water Heating Source Type"
                     type="bold-title"
                     value={1500}
                     isMocked
                     format="currency"
                  />
                  <PViDataPoint title="Cooking Source Type" type="bold-title" value={1500} isMocked format="currency" />
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>---?</b>
                  </span>
               </div>

               <div className={cx("unitDataC")}>
                  <PViDataPoint title="Electric Type" type="bold-title" value={1500} isMocked format="currency" />
                  <PViDataPoint title="Plumbing Type" type="bold-title" value={1500} isMocked format="currency" />
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Access Details</b>
                  </span>
               </div>

               <div className={cx("unitDataC")}>
                  <PViDataPoint title="Object Type" type="bold-title" value={1500} isMocked format="currency" />
                  <PViDataPoint title="Code" type="bold-title" value={1500} isMocked format="currency" />
                  <PViDataPoint title="Owner" type="bold-title" value={1500} isMocked format="currency" />
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Refrigerator Details</b>
                  </span>
               </div>

               <div className={cx("unitDataRightC")}>
                  <PViDataPoint title="Exists" type="bold-title" value={1500} isMocked format="currency" />
                  <PViDataPoint title="Serial value" type="bold-title" value={1500} isMocked format="currency" />
                  <PViDataPoint title="Model" type="bold-title" value={1500} isMocked format="currency" />
                  <PViDataPoint title="Condition" type="bold-title" value={1500} isMocked format="currency" />

                  <div className={cx("uploadImg")}>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img", "addImg")}>
                        <img src={CF_CDN_URL("/assets/property_validator/add_photo_alternate.svg")} alt="" />
                     </div>
                  </div>
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Stove Details</b>
                  </span>
               </div>

               <div className={cx("unitDataRightC")}>
                  <PViDataPoint title="Exists" type="bold-title" value={1500} isMocked format="currency" />
                  <PViDataPoint title="Serial value" type="bold-title" value={1500} isMocked format="currency" />
                  <PViDataPoint title="Model" type="bold-title" value={1500} isMocked format="currency" />
                  <PViDataPoint title="Condition" type="bold-title" value={1500} isMocked format="currency" />
                  <div className={cx("uploadImg")}>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img", "addImg")}>
                        <img src={CF_CDN_URL("/assets/property_validator/add_photo_alternate.svg")} alt="" />
                     </div>
                  </div>
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Microwave Details</b>
                  </span>
               </div>

               <div className={cx("unitDataRightC")}>
                  <PViDataPoint title="Exists" type="bold-title" value={1500} isMocked format="currency" />
                  <PViDataPoint title="Serial value" type="bold-title" value={1500} isMocked format="currency" />
                  <PViDataPoint title="Model" type="bold-title" value={1500} isMocked format="currency" />
                  <PViDataPoint title="Condition" type="bold-title" value={1500} isMocked format="currency" />
                  <div className={cx("uploadImg")}>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img", "addImg")}>
                        <img src={CF_CDN_URL("/assets/property_validator/add_photo_alternate.svg")} alt="" />
                     </div>
                  </div>
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>HVAC Details</b>
                  </span>
               </div>

               <div className={cx("unitDataRightC")}>
                  <PViDataPoint title="Exists" type="bold-title" value={1500} isMocked format="currency" />
                  <PViDataPoint title="Serial value" type="bold-title" value={1500} isMocked format="currency" />
                  <PViDataPoint title="Model" type="bold-title" value={1500} isMocked format="currency" />
                  <PViDataPoint title="Condition" type="bold-title" value={1500} isMocked format="currency" />
                  <div className={cx("uploadImg")}>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img", "addImg")}>
                        <img src={CF_CDN_URL("/assets/property_validator/add_photo_alternate.svg")} alt="" />
                     </div>
                  </div>
               </div>
            </div>

            <hr className={cx("divide-hr")} />
         </div>
      </div>
   );
};

import { constants_paths } from "./path";
export type TAppCategory =
   | "all"
   | "engineer"
   | "leads"
   | "underwriting"
   | "transaction"
   | "renovation"
   | "asset-management";

export type TApp = {
    category: TAppCategory;
    name: string;
    icon: string;
    description: string;
    id: string;
    path: string;
 };

export const apps: TApp[] = [
    {
       icon: "location_black.svg",
       description: "Simply's Map",
       name: "Simply Map",
       category: "all",
       id: "app_map",
       path: constants_paths["APPS_MAP"],
    },
    {
       id: "app_qeditor",
       icon: "analytic_black.svg",
       category: "engineer",
       name: "Question Editor",
       description: "Edit questions for the Simply Widget",
       path: constants_paths["APPS_QEDITOR"],
    },
 ];
 
import { SHu_js_flattenObject } from "@simplyhomes/utils";
import { useState, useEffect } from "react";

function useDebounce<T>(value: T, delay: number): T {
   const [debouncedValue, setDebouncedValue] = useState(value);

   useEffect(() => {
      const handler = setTimeout(() => {
         if (
            typeof value === "object" &&
            typeof debouncedValue === "object" &&
            JSON.stringify(SHu_js_flattenObject(value as any)) ===
               JSON.stringify(SHu_js_flattenObject(debouncedValue as any))
         )
            return;
         setDebouncedValue(value);
      }, delay);

      return () => {
         clearTimeout(handler);
      };
   }, [value, delay, debouncedValue]);

   return debouncedValue;
}

export default useDebounce;

import { ReactNode } from "react";
import styles from "./SignInLayout.module.scss";
type SignInLayoutProps = {
   children: ReactNode;
};

const SignInLayout = ({ children }: SignInLayoutProps) => {
   return <div className={`${styles.contentC}`}>{children}</div>;
};
export default SignInLayout;

import classNames from "classnames/bind";
import styles from "./QuestionCard.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { useQEContext } from "../../../contexts/QEditorContext";
import { SHrSosButton } from "@simplyhomes/react";

const cx = classNames.bind(styles);

const QuestionCard = ({ questionId, itemIndex, subItemIndex }: QuestionCardProps) => {
   const { qeState, qeDispatch, qeHelper } = useQEContext();
   const { qIdSelected } = qeState;
   const { libraryQuestionsMap } = qeHelper;

   const question = libraryQuestionsMap[questionId] || {
      id: "",
      followUps: [],
      rejections: [],
      type: "",
      question: "NOT FOUND!",
   };
   const { type, followUps = [], id, rejections = [] } = question;

   const style = "style" in question ? question.style : "";
   const options = "options" in question ? question.options : "";
   const questionText = "question" in question ? question.question : "";

   const styleSelectedQuestion = qIdSelected === questionId ? "selected" : "";

   const handleBindingToQuestionSetting = () =>
      qeDispatch({ type: "select-question", payload: { qId: qIdSelected === questionId ? "" : questionId } });
   const handleDelete = () =>
      itemIndex != null && qeDispatch({ type: "flow-remove-question", payload: { itemIndex, subItemIndex } });
   return (
      <div className={cx("questionCardC", styleSelectedQuestion)} onClick={handleBindingToQuestionSetting}>
         <span className={cx("top-card")}>
            <b>{id}</b>
            <b>&minus;</b>

            <span>{type}</span>
            {style && (
               <>
                  <b>&minus;</b>
                  <span>{style}</span>
               </>
            )}
            {options && (
               <>
                  <b>&minus;</b>
                  <span>
                     <b>{options.length}</b> Options
                  </span>
               </>
            )}
         </span>
         <span className={cx("tittle")}>{questionText}</span>
         <div className={cx("bottom-card")}>
            <div className={cx("trigger-left")}>
               <div>
                  <img src={CF_CDN_URL("/assets/bolt_black.svg")} alt="" />
               </div>
               <span>
                  {followUps.length} Trigger(s) | {rejections.length} Rejection(s)
               </span>
            </div>
            {itemIndex != null && (
               <SHrSosButton type="bland" buttonProps={{ className: cx("deleteBtn"), onClick: handleDelete }}>
                  <img src={CF_CDN_URL("/assets/question-editor/delete_negative.svg")} alt="" />
               </SHrSosButton>
            )}
         </div>
      </div>
   );
};

export default QuestionCard;
type QuestionCardProps = {
   questionId: string;
   itemIndex?: number;
   subItemIndex?: number;
};

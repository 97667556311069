import { useIsMutating, useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { usePViQueryParams } from "../../sosApps/propertyViability/usePViQueryParams";
import { usePViContext } from "../../../contexts/PViContext";

export const useMPVi_QuickPickSource = () => {
   const { sosAxios } = useSosAxios();
   const { filters } = usePViQueryParams();
   const { pviState, pviOverwrite } = usePViContext();
   const { excludedSourceIds: currentExcludedSourceIds, sid } = pviState;
   const newExcludedSourceIds = [...currentExcludedSourceIds];
   if (sid) newExcludedSourceIds.push(sid);
   const mutationKey = ["useMPVi_QuickPickSource"];
   const isMutating = useIsMutating({ mutationKey });
   const mutation = useMutation({
      mutationKey,
      mutationFn: () =>
         sosAxios
            .get(`/property-viability/sources/quick-pick`, {
               params: { ...filters, excludedSourceIds: newExcludedSourceIds },
            })
            .then(({ data }: { data: Response }) => {
               const { sid } = data;
               if (sid) pviOverwrite({ sid, excludedSourceIds: newExcludedSourceIds, showDetailsModal: true });
               return data;
            }),
   });
   return { ...mutation, isMutating };
};

type Response = { sid: string | undefined };

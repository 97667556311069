import { useQuery } from "@tanstack/react-query";
import { TPgSoS_WidgetFlows_Schema } from "@simplyhomes/utils";
import { useQEContext } from "../../../contexts/QEditorContext";
import { useEffect } from "react";
import { useSosAxios } from "../../axios/useSosAxios";

export const useQQE_Flow = ({ fid }: { fid: string }) => {
   const { sosAxios } = useSosAxios();
   const { qeDispatch } = useQEContext();
   const query = useQuery({
      enabled: !!fid,
      queryKey: ["QQE_Flow", fid],
      queryFn: () => sosAxios.get(`/qeditor/flows/${fid}`).then(({ data }: { data: TData }) => data),
   });
   useEffect(() => {
      const newFlow = query.data?.flow;
      if (newFlow)
         qeDispatch({
            type: "flow-set",
            payload: { flowCode: newFlow.code, flowQuestions: newFlow.questions },
         });
   }, [qeDispatch, query.data]);

   return query;
};
const flowCols = ["fid", "name", "questions", "code"] as const;
type TData = {
   flow: Pick<TPgSoS_WidgetFlows_Schema, (typeof flowCols)[number]>;
};

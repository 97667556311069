import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";

export const useQ_Users = () => {
   const { sosAxios } = useSosAxios();
   const query = useQuery({
      queryKey: ["useQ_Users"],
      queryFn: () => sosAxios.get("/users").then<SoS_GetAllUser_Response>(({ data }) => data),
   });

   return query;
};

type SoS_GetAllUser_User = {
   email: string;
   name: string;
};

type SoS_GetAllUser_Response = { users: SoS_GetAllUser_User[] };

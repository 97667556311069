import { SHrPopup, SHrSosTabsBar } from "@simplyhomes/react";
import classNames from "classnames/bind";
import { useState } from "react";
import styles from "./PViPropertyDetailsTabs.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { PVipropertyDetailsTab } from "./PVipropertyDetailsTab/PVipropertyDetailsTab";
import { PViUnitDetailsTab } from "./PViUnitDetailsTab/PViUnitDetailsTab";
import { PViViewHistoryModal } from "../PViViewHistoryModal/PViViewHistoryModal";
import { PViPropertyDetailsTabOverview } from "../../PViPropertyDetailsTabOverview/PViPropertyDetailsTabOverview";

const cx = classNames.bind(styles);

const tabs = ["Overview", "Property Details", "Unit Details", "Area", "Tax History", "Underwriting", "Source", "Leads"];

export const PViPropertyDetailsTabs = () => {
   const [isOpenViewHistoryModal, setIsOpenViewHistoryModal] = useState(false);

   const [activeTab, setActiveTab] = useState("Overview");

   return (
      <div className={cx("container")}>
         <SHrPopup show={isOpenViewHistoryModal}>
            <PViViewHistoryModal toggle={() => setIsOpenViewHistoryModal(!isOpenViewHistoryModal)} />
         </SHrPopup>
         <div className={cx("leftC")}>
            <div className={cx("tabAndHistoryC")}>
               <SHrSosTabsBar tabs={tabs} value={activeTab} onChange={setActiveTab} />
               <div className={cx("viewHistoryC")} onClick={() => setIsOpenViewHistoryModal(!isOpenViewHistoryModal)}>
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/property_validator/history_black.svg")} alt="" />
                  </div>
                  <span>View history</span>
               </div>
            </div>
            <div className={`${styles.pd}`}>
               {(activeTab === "Overview" && <PViPropertyDetailsTabOverview />) ||
                  (activeTab === "Property Details" && <PVipropertyDetailsTab />) ||
                  (activeTab === "Unit Details" && <PViUnitDetailsTab />)}
            </div>
         </div>
      </div>
   );
};

import { TWidget_Question, TWidget_Question_Raw } from "@simplyhomes/utils";
import { useEffect } from "react";
export const Widget = ({ data, widgetCode }: TWidgetProps) => {
   useEffect(() => {
      const timestamp = Math.ceil(new Date().getTime() / 300000); // Get current timestamp
      const isDev = process.env.NODE_ENV === "development";
      const cfURL = `https://dyaj6jwzih5dm.cloudfront.net/widget/static${isDev ? "/staging" : ""}`;
      const js = document.createElement("script");
      js.src = `${cfURL}/main.js?${timestamp}`;
      js.defer = true;

      const css = document.createElement("link");
      css.rel = "stylesheet";
      css.type = "text/css";
      css.href = `${cfURL}/main.css?${timestamp}`;

      css.onload = function () {
         document.getElementById("simply-widget")?.appendChild(js);
      };

      document.getElementsByTagName("head")[0].appendChild(css);
   }, [data]);
   return (
      <>
         <div id="simply-widget"></div>
         <div
            id="simply-widget-data"
            data-question={data ? JSON.stringify(data) : undefined}
            data-code={widgetCode}
         ></div>
      </>
   );
};

type TWidgetProps = {
   data?: (TWidget_Question | TWidget_Question[])[] 
   widgetCode?: string;
};

import classNames from "classnames/bind";

import styles from "./MainAndTriggerQuestion.module.scss";
import { useQEContext } from "../../../contexts/QEditorContext";
import { Widget } from "../../../components/widget/Widget";

const cx = classNames.bind(styles);

const MainAndTriggerQuestion = () => {
   const { qeState } = useQEContext();
   const { isPreviewing, qSelected } = qeState;
   // const { Widget } = useInjectWidget({ data: selectedQuestion? [selectedQuestion]:[] });

   return (
      <div className={cx("topCenterC")}>
         <div className={cx(`widgetC`)}>
            {/* {selectedQuestion && <div id="simply-widget"></div>}
            <div id="simply-widget-data" data-question={JSON.stringify(selectedQuestion)}></div> */}
            {/* {selectedQuestion && !isPreviewing && <Widget data={[selectedQuestion]} />} */}
            {qSelected && !isPreviewing && <Widget data={[qSelected]} />}
         </div>
      </div>
   );
};

export default MainAndTriggerQuestion;

import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";

export const useQOffers_GetTermTemplates = () => {
   const { sosAxios } = useSosAxios();
   const query = useQuery({
      queryKey: ["useQOffers_GetTermTemplates"],
      queryFn: () => sosAxios.get("/offers/term-templates").then<SoS_GetTermTemplates_Response>(({ data }) => data),
   });
   return query;
};

export type SoS_GetTermTemplates_TermTemplate = {
   tid: number;
   name: string;
};

export type SoS_GetTermTemplates_Response = { templates: SoS_GetTermTemplates_TermTemplate[] };

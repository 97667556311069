import classNames from "classnames/bind";
import styles from "./PViDataPoint.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { SHu_js_currencyFormat, SHu_js_percentageFormat } from "@simplyhomes/utils";
import { useState } from "react";
const cx = classNames.bind(styles);

export const PViDataPoint = (p: PViDataPointProps) => {
   const { title, value, type, allowEdit, format, icon, isMocked, displayValue } = p;

   const [isEditing, setIsEditing] = useState(false);

   const formattedValue = displayValue
      ? displayValue
      : format === "currency"
      ? SHu_js_currencyFormat(value)
      : format === "percentage"
      ? SHu_js_percentageFormat(value)
      : value || "N/A";
   const Icon = icon ? icons[icon] : null;
   const handleEnableEditing = () => allowEdit && setIsEditing(true);
   return (
      <div className={cx("contentC", `type-${type}`, { isMocked })}>
         <div className={cx("titleC")}>
            <span>{title}</span>
            {/* <div className={cx("img-icon-history")}>
               <img className={cx({})} src={CF_CDN_URL("/assets/property_validator/history_black.svg")} alt="" />
            </div> */}
         </div>
         <div className={cx(`valueC`)}>
            {Icon && <img title={title} src={Icon} alt={title} />}
            {/* {isEditing ? (
               <input title={title} type="text" value={value?.toString() || ""} onChange={() => {}} />
            ) : (
               <span title={formattedValue.toString()}>{formattedValue}</span>
            )} */}
            <div>
               <span title={formattedValue.toString()}>{formattedValue}</span>
            </div>
            {allowEdit && (
               <div className={cx("edit-icon")}>
                  <img
                     onClick={handleEnableEditing}
                     src={CF_CDN_URL("/assets/property_validator/edit_black.svg")}
                     alt="edit"
                  />
               </div>
            )}
         </div>
      </div>
   );
};
const icons = {
   sqft: CF_CDN_URL("/assets/property_validator/square_foot_black.svg"),
   yearbuilt: CF_CDN_URL("/assets/construction_black.svg"),
   propertyType: CF_CDN_URL("/assets/nav_home.svg"),
   neighborhood: CF_CDN_URL("/assets/property_validator/holiday_village_black.svg"),
   source: CF_CDN_URL("/assets/property_validator/source_black.svg"),
   oclock: CF_CDN_URL("/assets/property_validator/watch_later_black.svg"),
   occupied: CF_CDN_URL("/assets/property_validator/sensor_occupied_black.svg"),
   contingent: CF_CDN_URL("/assets/property_validator/person_search_black.svg"),
   bedroom: CF_CDN_URL("/assets/property_validator/bedroom_parent_black.svg"),
   bathroom: CF_CDN_URL("/assets/property_validator/bathroom.svg"),
   local_activity: CF_CDN_URL("/assets/property_validator/local_activity_black.svg"),
   store: CF_CDN_URL("/assets/property_validator/store_black.svg"),
};
export type PViDataPointProps = {
   title: string;
   type: "bold-value" | "bold-title" | "only-value";
   value: string | undefined | null | number;
   displayValue?: string;
   auditKey?: string;
   allowEdit?: boolean;
   isMocked?: boolean;
   format?: "currency" | "percentage";
   icon?: keyof typeof icons;
};

import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import styles from "./SoSTag.module.scss";
export const SoSTag = ({ title, type, icon }: TSoSTagProps) => {
   const Icon = icon ? icons[icon] : null;
   return (
      <div className={`${styles.contentC} ${styles[`type-${type}`]}`}>
         {Icon && <img title={title} src={Icon} alt={title} />}
         <span>{title}</span>
      </div>
   );
};

const icons = {
   bath: CF_CDN_URL("/assets/property_validator/bathroom_warning_600.svg"),
   thumbdown: CF_CDN_URL("/assets/property_validator/thumb_down_alt_primary_500.svg"),
};
type TSoSTagProps = {
   type: "warning";
   title: string;
   icon?: keyof typeof icons;
};

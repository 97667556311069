import { useSearchParams } from "react-router-dom";
import { useDebounceCallback } from "./useDebounceCallback";

export const useQueryParams = () => {
   const [params, setSearchParams] = useSearchParams();
   const { debouncedCallback: dbSetSearchParams } = useDebounceCallback(
      (params: URLSearchParams) => setSearchParams(params),
      500
   );
   const useSingleQueryParamsState = (paramString: string, defaultValue?: string) => {
      const state = params.get(paramString) || defaultValue || "";
      const setState = (s: string) => {
         params.set(paramString, s);
         dbSetSearchParams(params);
      };
      return [state, setState] as const;
   };
   const useMultiQueryParamsState = (paramString: string, defaultValue?: string[]) => {
      const state = params.getAll(paramString) || defaultValue || [];
      const setState = (s: string[]) => {
         params.delete(paramString);
         s.forEach((v) => params.append(paramString, v));
         dbSetSearchParams(params);
      };
      return [state, setState] as const;
   };

   return { params, setSearchParams, useSingleQueryParamsState, useMultiQueryParamsState };
};

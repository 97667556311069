import { useInfiniteQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import useDebounce from "../../react/useDebounce";
import { usePViQueryParams } from "../../sosApps/propertyViability/usePViQueryParams";
import { useMemo } from "react";

export const useQPV_Properties = (p?: TuseQPV_Properties_Params) => {
   const { noLimit } = p || {};
   const { sosAxios } = useSosAxios();

   const { filters } = usePViQueryParams();

   const dbQ = useDebounce(filters.q, 500);
   const minSourcePrice = useDebounce(filters.minSourcePrice, 500);
   const maxSourcePrice = useDebounce(filters.maxSourcePrice, 500);
   const baseParams = { ...filters, noLimit, q: dbQ, minSourcePrice, maxSourcePrice };
   const query = useInfiniteQuery({
      queryKey: [`QPV_Properties`, baseParams],
      staleTime: 500,
      queryFn: ({ pageParam: offset }) =>
         sosAxios
            .get(`/property-viability/properties`, { params: { ...baseParams, offset } })
            .then<TData>(({ data }) => {
               console.log("fetched properties");
               return data;
            }),
      initialPageParam: 0,
      getNextPageParam: ({ metadata: { nextOffset } }) => {
         return nextOffset > 0 && nextOffset >= +filters.limit ? nextOffset : undefined;
      },
   });

   const properties = useMemo(
      () => query.data?.pages.flatMap((page) => page.payload.properties) || [],
      [query.data?.pages]
   );

   return { ...query, properties };
};
type TuseQPV_Properties_Params = {
   noLimit?: boolean;
};

type TData = {
   payload: { properties: PVi_UseQuery_Property[] };
   metadata: { totalRows: number; nextOffset: number };
};

export type PVi_UseQuery_Property = {
   pid: string;
   ptype: string;
   address_one_line: string;
   market: string;
   zip: string;
   state: string;
   county: string;
   city: string;
   status: string;
   geocoding_data: string;
   geocoding_date: Date;
   atom_data: string;
   corelogic_data: string;
   sqft: number | null;
   year_built: number;
   price: number;
   street_view_url_150: string | null;
   coordinates: { lat: number; lng: number };
   units: { beds: number; baths: number }[];
};

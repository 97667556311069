import classNames from "classnames/bind";
import styles from "./PViViewHistoryModal.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import DataTable from "react-data-table-component";
import { allHistoryColumn } from "./data-table-column";
import { THistory } from "./type";

const cx = classNames.bind(styles);

const historyData: THistory[] = [
   {
      person: "Adam Pettengill",
      time_modified: "09:00 AM 23 Feb 2024",
      tab: "Unit Details",
      section: "Unit 1",
      data_label: "Bedrooms",
      current_value: "2 beds",
      value_changed: "3 beds",
   },
   {
      person: "Adam Pettengill",
      time_modified: "03:17 PM 22 Feb 2024",
      tab: "Property Details",
      section: "Property Overview",
      data_label: "Property Type",
      current_value: "Duplex",
      value_changed: "Single Family",
   },
   {
      person: "Adam Pettengill",
      time_modified: "01:38 PM 21 Feb 2024",
      tab: "Overview",
      section: "--",
      data_label: "Asking Price",
      current_value: "1,000,500",
      value_changed: "100,000",
   },
];
const handleShowDetailInformationClick = () => {};

export const PViViewHistoryModal = ({ toggle }: TPViViewHistoryModal) => {
   return (
      <div className={cx("container")}>
         <div className={cx("topC")}>
            <span>History</span>
            <div className={cx("close-btn")}>
               <button type="button" title="close" onClick={toggle}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>

         <div className={cx("tableC")}>
            <DataTable columns={allHistoryColumn} data={historyData} onRowClicked={handleShowDetailInformationClick} />
         </div>
      </div>
   );
};

type TPViViewHistoryModal = {
   toggle: () => void;
};

import DataTable from "react-data-table-component";
import { allPropertiesColumn } from "./data-table-column";
import classNames from "classnames/bind";
import styles from "./PViListView.module.scss";
import { usePViContext } from "../../../contexts/PViContext";
import { SHrSosButton } from "@simplyhomes/react";
import { PVi_useQPVi_Sources_Source, useQPVi_Sources } from "../../../hooks/queries/PVi/useQPVi_Sources";

const cx = classNames.bind(styles);

export const PViListView = () => {
   const { pviOverwrite } = usePViContext();

   const qPViSources = useQPVi_Sources();
   const { sources = [] } = qPViSources;

   const handleShowDetailInformationClick = (source: PVi_useQPVi_Sources_Source) => {
      pviOverwrite({ sid: source.sid, showDetailsModal: true });
   };

   return (
      <div className={cx("tableC")}>
         <DataTable columns={allPropertiesColumn} data={sources} onRowClicked={handleShowDetailInformationClick} />

         <div className={cx("footer")}>
            <SHrSosButton
               buttonProps={{
                  onClick: () => qPViSources.fetchNextPage(),
                  className: cx("load-more", { hidden: !qPViSources.hasNextPage || qPViSources.isFetchingNextPage }),
               }}
            >
               Load More
            </SHrSosButton>
         </div>
      </div>
   );
};

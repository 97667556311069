import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";

export const useQOffers_GetLeads = () => {
   const { sosAxios } = useSosAxios();

   const query = useQuery({
      queryKey: [`useQOffers_GetLeads`],
      staleTime: 500,
      queryFn: () =>
         sosAxios.get(`/offers/leads`).then<SoS_GetLeads_Response>(({ data }) => {
            const leads = data.leads;
            const leadsMap = leads.reduce((acc: Record<string, SoS_GetAllLeads_Lead>, lead: SoS_GetAllLeads_Lead) => {
               acc[lead.lid] = lead;
               return acc;
            }, {} as Record<string, SoS_GetAllLeads_Lead>);

            return {
               leads,
               leadsMap,
               leadIds: leads.map((lead: SoS_GetAllLeads_Lead) => lead.lid),
            };
         }),
   });
   return query;
};

type SoS_GetAllLeads_Lead = {
   lid: string;
   status: string;
};

type SoS_GetLeads_Response = {
   leads: SoS_GetAllLeads_Lead[];
   leadsMap: Record<string, SoS_GetAllLeads_Lead>;
   leadIds: string[];
};

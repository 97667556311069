import classNames from "classnames/bind";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./NavHorizontal.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { useQ_User_profile } from "../../../hooks/queries/user/useQ_User_profile";
import { useSosAuth } from "../../../hooks/auth/useSosAuth";

const cx = classNames.bind(styles);
const NavHorizontal = () => {
   const nav = useNavigate();
   const { logOut } = useSosAuth();
   const qUserProfile = useQ_User_profile();
   const { data: userProfile } = qUserProfile;
   const { picture } = userProfile?.profile || {};

   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

   const userMenuItems: { icon: string; title: string; cb: () => void }[] = [
      { icon: "person_primary.svg", title: "My Profile", cb: () => nav("/profile") },
      { icon: "settings_primary.svg", title: "Account settings", cb: () => nav("/account-settings") },
      { icon: "help_center_primary.svg", title: "Support center", cb: () => nav("/support-center") },
      { icon: "exit_to_app_primary.svg", title: "Sign out", cb: logOut },
   ];

   return (
      <div className={cx("contentC")}>
         <div className={cx("logo")}>
            <img src={CF_CDN_URL("/assets/nav_logo.svg")} alt="" />
            <span>Simply OS</span>
         </div>

         <div className={cx("notification")}>
            <img src={CF_CDN_URL("/assets/notifications_snow.svg")} alt="" />
            <div className={cx("avt-notification")} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
               <div>{picture && <img src={picture} alt="" />}</div>
               <img src={CF_CDN_URL("/assets/arrow_drop_down.svg")} alt="" />
            </div>
            <ul
               className={cx("menu-item", { open: isDropdownOpen })}
               onMouseLeave={() => setIsDropdownOpen(false)}
               onClick={() => setIsDropdownOpen(false)}
            >
               {userMenuItems.map((item, index) => (
                  <li key={index + item.title} onClick={item.cb}>
                     <img src={CF_CDN_URL(`/assets/${item.icon}`)} alt="" />
                     <span>{item.title}</span>
                  </li>
               ))}
            </ul>
         </div>
      </div>
   );
};

export default NavHorizontal;

import { SHrSosButton } from "@simplyhomes/react";
import { SoSTag } from "../../../components/common/SoSTag/SoSTag";
import { usePViPropertyDetails } from "../../../hooks/sosApps/propertyViability/usePViPropertyDetails";
import styles from "./PViPropertyDetailsTabOverview.module.scss";
import classNames from "classnames/bind";
import { PViDataPoint, PViDataPointProps } from "../PViPropertyDetails/PViDataPoint/PViDataPoint";

const cx = classNames.bind(styles);

export const PViPropertyDetailsTabOverview = () => {
   const { source, isNew } = usePViPropertyDetails();
   if (!source) return null;
   const { sqft, ptype, year_built, price, units } = source;
   return (
      <div className={`${styles.pdOverview}`}>
         {!isNew && (
            <div className={`${styles.pdoWarnings}`}>
               <SoSTag title="0 Baths" type="warning" icon="bath" />
               <SoSTag title="0 Baths" type="warning" icon="thumbdown" />
            </div>
         )}
         <div className={`${styles.pdoLargeDP}`}>
            <LargeDataPoint
               title="Asking Price"
               auditKey="source_price"
               type="bold-value"
               value={price}
               format="currency"
               allowEdit
            />
            <LargeDataPoint
               title="Asking Price"
               auditKey="property_max-offer"
               type="bold-value"
               value={55183}
               format="currency"
               isMocked
            />
            <LargeDataPoint
               title="Target Price"
               auditKey="property_target-property"
               type="bold-value"
               value={50500}
               format="currency"
               isMocked
            />
            <LargeDataPoint
               title="As-is Value"
               auditKey="property_as-is-value"
               type="bold-value"
               value={60000}
               format="currency"
               isMocked
            />
            <LargeDataPoint
               title="Originators reno amount"
               auditKey="property_originators-reno-amount"
               type="bold-value"
               value={100000}
               format="currency"
               isMocked
            />
            <LargeDataPoint
               title="Originator's ARV Estimate"
               auditKey="property_originators-arv-estimate"
               type="bold-value"
               value={200000}
               format="currency"
               isMocked
            />
            <LargeDataPoint
               title="Cap Rate"
               auditKey="property_cap-rate"
               type="bold-value"
               value={0.1}
               format="percentage"
               isMocked
            />
            <LargeDataPoint
               title="ARV Uplifted"
               auditKey="property_arv-uplifted"
               type="bold-value"
               value={200000}
               format="currency"
               isMocked
            />
         </div>
         <div className={`${styles.pdoSmallDP}`}>
            <PViDataPoint
               title="Square Feet"
               auditKey="property_square-feet"
               type="only-value"
               value={sqft}
               icon="sqft"
            />
            <PViDataPoint
               title="Property Type"
               auditKey="property_property-type"
               type="only-value"
               value={ptype}
               icon="propertyType"
            />
            <PViDataPoint
               title="Source"
               auditKey="source_source"
               type="only-value"
               value="MLS"
               isMocked
               icon="source"
            />
            <PViDataPoint
               title="Year Built"
               auditKey="property_year-built"
               type="only-value"
               value={year_built}
               icon="yearbuilt"
            />
            <PViDataPoint
               title="Neighborhood"
               auditKey="property_neighborhood"
               type="only-value"
               value="Cleveland"
               isMocked
               icon="neighborhood"
            />
            <PViDataPoint
               title="Days on Market"
               auditKey="source_days-on-market"
               type="only-value"
               value={6}
               isMocked
               icon="oclock"
            />
            <PViDataPoint
               title="Inspection Status"
               auditKey="source_inspection-status"
               type="only-value"
               value="Passed"
               isMocked
               icon="occupied"
            />
         </div>
         <div className={`${styles.pdoUnits}`}>
            {units.map((_, i) => (
               <UnitData unitIndex={i} key={i} />
            ))}
            <SHrSosButton type="tonal">
               <span>+ Add a unit</span>
            </SHrSosButton>
         </div>
         {isNew && <Sources />}
      </div>
   );
};

type LargeDataPointProps = PViDataPointProps;
const LargeDataPoint = (p: LargeDataPointProps) => {
   return (
      <div className={`${styles.largeDataPoint}`}>
         <PViDataPoint {...p} />
      </div>
   );
};
const UnitData = ({ unitIndex }: { unitIndex: number }) => {
   const { source } = usePViPropertyDetails();
   if (!source) return null;
   const { units } = source;
   const unit = units[unitIndex];
   if (!unit) return null;
   return (
      <div className={`${styles.unitData}`}>
         <PViDataPoint
            title="Beds"
            auditKey={`unit_${unitIndex}_beds`}
            type="only-value"
            value={unit.beds}
            displayValue={`${unit.beds} Beds`}
            icon="bedroom"
         />
         <PViDataPoint
            title="Baths"
            auditKey={`unit_${unitIndex}_baths`}
            type="only-value"
            value={unit.baths}
            displayValue={`${unit.baths} Baths`}
            icon="bathroom"
         />
      </div>
   );
};

const Sources = () => {
   return (
      <div className={cx("detailSourcesC")}>
         <div className={cx("sourceInforC")}>
            <span>
               <b>MLS</b>
            </span>
            <div className={cx("dot")}></div>
            <PViDataPoint
               title="Inspection Status"
               auditKey="source_inspection-status"
               type="only-value"
               value="30 Sep 2023"
               isMocked
            />
            <div className={cx("dot")}></div>
            <PViDataPoint
               title="Inspection Status"
               auditKey="source_inspection-status"
               type="only-value"
               value="30 Sep 2023"
               isMocked
            />
         </div>
         <div className={cx("divider-container")}>
            <hr className={cx("divider")} />
         </div>
         <div className={cx("sourceInforC")}>
            <span>
               <b>DTS</b>
            </span>
            <div className={cx("dot")}></div>
            <PViDataPoint
               title="Inspection Status"
               auditKey="source_inspection-status"
               type="only-value"
               value="30 Sep 2023"
               isMocked
            />
            <div className={cx("dot")}></div>
            <PViDataPoint
               title="Inspection Status"
               auditKey="source_inspection-status"
               type="only-value"
               value="30 Sep 2023"
               isMocked
            />
         </div>
      </div>
   );
};

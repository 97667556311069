import classNames from "classnames/bind";
import styles from "./OffersLeadsList.module.scss";

import SoSFilter from "../../../components/common/SoSFilter/SoSFilter";
import { OffersLeadCard } from "../OffersLeadCard/OffersLeadCard";
import { OffersSettingsTerm } from "../OffersSettingsTerm/OffersSettingsTerm";
import { useQOffers_GetLeads } from "../../../hooks/queries/offers/leads/useQOffers_GetLeads";
const cx = classNames.bind(styles);

export const OffersLeadsList = () => {
   const qOfferLeads = useQOffers_GetLeads();

   // const qUsers = useQ_Users();

   // if (!qUsers) return null;

   // const userAssignOptions: TSoSInputSelect_option[] =
   //    qUsers.data?.users.map((user) => ({
   //       label: user.name,
   //       value: user.email,
   //    })) || [];

   return (
      <div className={cx("leftC")}>
         <OffersSettingsTerm />
         <div className={cx("leftBodyC")}>
            <div className={cx("filterTopC")}>
               <SoSFilter
                  title="Active Leads"
                  type="select"
                  isMulti={true}
                  value={[]}
                  onChange={(vs) => {}}
                  options={[]}
                  className={cx("filter-dropdown")}
               />

               <SoSFilter
                  title="All assignees"
                  type="select"
                  isMulti={true}
                  value={[]}
                  onChange={(vs) => {}}
                  options={[]}
                  className={cx("filter-dropdown")}
               />
            </div>

            <div className={cx("leadsListC")}>
               {qOfferLeads.data?.leads.map((lead, i) => (
                  <OffersLeadCard key={i} lid={lead.lid} />
               ))}
            </div>
         </div>
      </div>
   );
};

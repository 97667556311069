import classNames from "classnames/bind";
import styles from "./OffersSettingsTerm.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { SHrPopup, SHrSosButton } from "@simplyhomes/react";
import { useState } from "react";
import { OffersEditTermTemplateModal } from "../OffersEditTermTemplateModal/OffersEditTermTemplateModal";

const cx = classNames.bind(styles);

export const OffersSettingsTerm = () => {
   const [isPopupOpen, setIsPopupOpen] = useState(false);
   return (
      <div className={cx("settingsC")}>
         <div className={cx("settingC")}>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/offer/settings_black.svg")} alt="" />
            </div>
            <span className={cx("title")}>Settings</span>
         </div>
         <SHrSosButton
            type="text"
            buttonProps={{
               className: cx("btn-edit"),
               onClick: () => setIsPopupOpen(!isPopupOpen),
            }}
         >
            Edit Terms Template
         </SHrSosButton>

         <SHrPopup show={isPopupOpen}>
            <OffersEditTermTemplateModal toggel={() => setIsPopupOpen(!isPopupOpen)} />
         </SHrPopup>
      </div>
   );
};

import styles from "./SoSCheckbox.module.scss";
export const SoSCheckbox = ({ onChange, value, inputProps, labelProps, title }: TSoSCheckboxProps) => {
   return (
      <label {...labelProps} className={`${styles.contentC}`}>
         <input type="checkbox" checked={value} onChange={(e) => onChange(e.target.checked)} {...inputProps} />
         {title && <span>{title}</span>}
      </label>
   );
};

type TSoSCheckboxProps = {
   value: boolean;
   onChange: (b: boolean) => void;
   title?: string;
   labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
   inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
};

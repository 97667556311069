import classNames from "classnames/bind";
import styles from "./OffersPage.module.scss";
import { OffersViableProperties } from "./OffersViableProperties/OffersViableProperties";
import { OffersLeadsList } from "./OffersLeadsList/OffersLeadsList";
import { OffersProvider } from "../../contexts/OffersContext";

const cx = classNames.bind(styles);

const Offers = () => {
   return (
      <div className={cx("contentC")}>
         <OffersViableProperties />
         <div className={cx("bodyC")}>
            <OffersLeadsList />
            <OffersLeadDetail />
         </div>
      </div>
   );
};

export const OffersPage = () => {
   return (
      <OffersProvider>
         <Offers />
      </OffersProvider>
   );
};

const OffersLeadDetail = () => {
   return (
      <div className={cx("rightC")}>
         <div className={cx("rightTopC")}></div>
         <div className={cx("rightBodyC")}>
            <div className={cx("rightBodyLeftC")}></div>
            <div className={cx("rightBodyRightC")}></div>
         </div>
      </div>
   );
};

import { useMemo, useState } from "react";
import styles from "./QuestionSettings.module.scss";
import { SHrSosButton, SHrSosTabsBar } from "@simplyhomes/react";
import { useQEContext } from "../../../contexts/QEditorContext";
import { SoSInputTitle } from "../../../components/common/soSInputTitle/SoSInputTitle";
import { SoSCheckbox } from "../../../components/common/SoSCheckbox/SoSCheckbox";
import { SoSInputSelect } from "../../../components/common/SoSInputSelect/SoSInputSelect";
import { SHu_js_flattenObject, TWidget_Question_FollowUp_Raw, TWidget_SelectQuestion } from "@simplyhomes/utils";
import { useMQE_UpdateQuestion } from "../../../hooks/mutates/useMQE_UpdateQuestion";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { useQQE_Questions } from "../../../hooks/queries/QEditor/useQQE_Questions";
import { useMQE_CreateQuestion } from "../../../hooks/mutates/useMQE_CreateQuestion";
const tabs = ["Base", "Options", "Follow-ups", "Rejections"] as const;
export const QuestionSettings = () => {
   const { qeState, qeHelper } = useQEContext();
   const { qSelected, qIdSelected } = qeState;
   const { libraryQuestionsMap } = qeHelper;
   const [activeTab, setActiveTab] = useState<(typeof tabs)[number]>("Base");

   const qQeQuestions = useQQE_Questions();
   const mQeUpdate = useMQE_UpdateQuestion();
   const mQeCreate = useMQE_CreateQuestion();

   const currentQuestion = libraryQuestionsMap[qIdSelected];

   const isUpdated = useMemo(
      () =>
         JSON.stringify(SHu_js_flattenObject(qSelected || {})) !==
         JSON.stringify(SHu_js_flattenObject(currentQuestion || {})),
      [currentQuestion, qSelected]
   );
   const isUpdateMode = !!qIdSelected;
   if (!qSelected) return null;
   if (!qIdSelected && isUpdateMode) return null;
   const { id } = qSelected;
   const handleMasterButton = () => {
      if (isUpdateMode) return mQeUpdate.mutate();
      mQeCreate.mutate();
   };
   const isDupeId = id !== qIdSelected && !!libraryQuestionsMap[id];
   const masterButtonDisable = (isUpdateMode && !isUpdated) || isDupeId;
   return (
      <article className={`${styles.contentC}`}>
         <SHrSosTabsBar tabs={[...tabs]} value={activeTab} onChange={(v) => setActiveTab(v as (typeof tabs)[number])} />
         <SHrSosButton
            buttonProps={{ onClick: handleMasterButton, disabled: masterButtonDisable }}
            loading={mQeUpdate.isPending || qQeQuestions.isFetching || mQeCreate.isPending}
         >
            {isUpdateMode ? "Update" : "Create"}
         </SHrSosButton>
         <div className={`${styles.formC}`}>
            {activeTab === "Base" && <QuestionSettingsBaseForm />}
            {activeTab === "Options" && <QuestionSettingsAnswersForm />}
            {activeTab === "Follow-ups" && <QuestionSettingsFuForm />}
            {activeTab === "Rejections" && <QuestionSettingsRejForm />}
         </div>
      </article>
   );
};

const types = ["text", "date", "select", "upload", "ranking", "dropdown", "slider"];
const getStyles = (type: "text" | "select") =>
   type === "text"
      ? ["short-input", "long-input"]
      : type === "select"
      ? ["image-list", "image-grid", "text-list-short", "text-list-long", "text-grid"]
      : [];
const QuestionSettingsBaseForm = () => {
   const { qeState, qeDispatch, qeHelper } = useQEContext();
   const { qSelected, qIdSelected } = qeState;
   const { libraryQuestionsMap } = qeHelper;
   if (!qSelected) return null;

   const { type, id, isUnitQuestion } = qSelected;
   const isDupeId = id !== qIdSelected && !!libraryQuestionsMap[id];
   return (
      <div className={`${styles.baseForm}`}>
         <SoSCheckbox
            title="Is Unit Question?"
            value={!!isUnitQuestion}
            onChange={(isUnitQuestion) =>
               qeDispatch({ type: "update-selected-question_isUnitQuestion", payload: { isUnitQuestion } })
            }
         />
         <SoSCheckbox
            title="Allow Skip?"
            value={"allowSkip" in qSelected ? qSelected.allowSkip || false : false}
            onChange={(b) => qeDispatch({ type: "update-selected-question_allowSkip", payload: { allowSkip: b } })}
         />
         <SoSInputTitle
            title="Question ID"
            type="text"
            value={id}
            handleValue={(id) => qeDispatch({ type: "update-selected-question_id", payload: { id } })}
            error={!id ? "Enter ID" : isDupeId ? "Duplicated ID" : ""}
         />
         {"question" in qSelected && (
            <SoSInputTitle
               title="Question"
               type="text"
               value={qSelected!.question}
               handleValue={(question) =>
                  qeDispatch({ type: "update-selected-question_question", payload: { question } })
               }
            />
         )}
         {"description" in qSelected! && (
            <SoSInputTitle
               title="Description"
               type="text"
               value={qSelected!.description || ""}
               handleValue={(description) =>
                  qeDispatch({ type: "update-selected-question_description", payload: { description } })
               }
            />
         )}
         <SoSInputSelect
            title="Question Type"
            value={{ label: type, value: type }}
            onChange={(o) => qeDispatch({ type: "update-selected-question_type", payload: { type: o.value } })}
            options={types.map((t) => ({ label: t, value: t }))}
            isMulti={false}
         />
         {type === "select" && (
            <SoSCheckbox
               title="Is Multi Select?"
               value={!!(qSelected! as TWidget_SelectQuestion).multipleChoice}
               onChange={(b) =>
                  qeDispatch({ type: "update-selected-question_multipleChoice", payload: { multipleChoice: b } })
               }
            />
         )}
         {(type === "select" || type === "text") && (
            <SoSInputSelect
               title="Style"
               value={{
                  label: (qSelected! as TWidget_SelectQuestion).style,
                  value: (qSelected! as TWidget_SelectQuestion).style,
               }}
               onChange={(o) => qeDispatch({ type: "update-selected-question_style", payload: { style: o.value } })}
               options={getStyles(type).map((t) => ({ label: t, value: t }))}
               isMulti={false}
            />
         )}
      </div>
   );
};

const QuestionSettingsAnswersForm = () => {
   const { qeState, qeDispatch } = useQEContext();
   const { qSelected } = qeState;
   if (!qSelected) return null;
   const { type } = qSelected;
   if (!["select", "dropdown", "slider"].includes(type)) return <div>Not supported</div>;
   const options = "options" in qSelected ? qSelected.options : [];

   return (
      <div className={`${styles.optionsForm}`}>
         <ul>
            {options.map(({ label, value, description, img }, i) => (
               <li key={i}>
                  <div className={`${styles.optionHeader}`}>
                     <b>Option {i + 1}</b>
                     <button
                        type="button"
                        title="delete"
                        onClick={() => qeDispatch({ type: "update-selected-question_option-delete", payload: { i } })}
                     >
                        <img src={CF_CDN_URL("/assets/question-editor/delete_negative.svg")} alt="" />
                     </button>
                  </div>
                  <SoSInputTitle
                     title={`Label`}
                     type="text"
                     value={label}
                     handleValue={(label) =>
                        qeDispatch({ type: "update-selected-question_option-label", payload: { i, label } })
                     }
                     error={!label ? "Enter label" : ""}
                  />
                  <SoSInputTitle
                     title={`Value`}
                     type="text"
                     value={value}
                     handleValue={(value) =>
                        qeDispatch({ type: "update-selected-question_option-value", payload: { i, value } })
                     }
                     error={!value ? "Enter value" : ""}
                  />
                  <SoSInputTitle
                     title={`Description`}
                     type="text"
                     value={description || ""}
                     handleValue={(description) =>
                        qeDispatch({ type: "update-selected-question_option-description", payload: { i, description } })
                     }
                  />
                  <SoSInputTitle
                     title={`Image URL`}
                     type="text"
                     value={img || ""}
                     handleValue={(img) =>
                        qeDispatch({ type: "update-selected-question_option-img", payload: { i, img } })
                     }
                  />
               </li>
            ))}
         </ul>
         <QuestionSettingsAddButton
            onClick={() => qeDispatch({ type: "update-selected-question_option-add" })}
            title="Add option"
         />
      </div>
   );
};

const QuestionSettingsFuForm = () => {
   const { qeState, qeDispatch } = useQEContext();
   const { qSelected } = qeState;
   const { followUps = [] } = qSelected || {};
   return (
      <div className={`${styles.fuForm}`}>
         <ul>
            {followUps.map((_, i) => (
               <li key={i + "QuestionSettingsFuForm"}>
                  <QuestionSettingsFu i={i} key={i + "QuestionSettingsFuz"} />
               </li>
            ))}
         </ul>
         <QuestionSettingsAddButton
            onClick={() => qeDispatch({ type: "update-selected-question_fu-add" })}
            title="Add follow-up"
         />
      </div>
   );
};
enum EFuOperatorOptions {
   "And" = "and",
   "Or" = "or",
}
const fuOperatorOptions = Object.entries(EFuOperatorOptions).map(([label, value]) => ({ label, value }));
enum EFuOpOptions {
   "=" = "eq",
   "!=" = "ne",
   ">" = "gt",
   "<" = "lt",
   ">=" = "gte",
   "<=" = "lte",
}
const fuOpOptions = Object.entries(EFuOpOptions).map(([label, value]) => ({ label, value }));
const QuestionSettingsFu = ({ i }: { i: number }) => {
   const { qeState, qeDispatch, qeHelper } = useQEContext();
   const { libraryQuestionsMap } = qeHelper;
   const { qSelected } = qeState;
   const { followUps = [] } = qSelected || {};

   const questionOptions = useMemo(
      () =>
         libraryQuestionsMap
            ? Object.entries(libraryQuestionsMap).map(([value, rawQ]) => ({
                 value,
                 label: rawQ && "question" in rawQ ? rawQ.question : "---",
              }))
            : [],
      [libraryQuestionsMap]
   );
   const fu: TWidget_Question_FollowUp_Raw = followUps[i];
   if (!fu) return null;
   const { conditions = [], operator = "and", questions = [] } = fu;
   return (
      <div className={`${styles.conditionC}`}>
         <div>
            <ul>
               {conditions.map(({ qid = "", operator: op = "eq", value = "" }, j) => (
                  <li key={j + "QuestionSettingsFu"}>
                     {j === 0 ? (
                        <SoSInputSelect
                           isMulti={false}
                           options={fuOperatorOptions}
                           customStyle={"question-editor"}
                           containerProps={{ className: `${styles.cond_OP}` }}
                           placeholder="?"
                           value={fuOperatorOptions.find((o) => o.value === operator) || ""}
                           dropdownIndicator={null}
                           onChange={(o) =>
                              qeDispatch({
                                 type: "update-selected-question_fu-operator",
                                 payload: { i, operator: o.value },
                              })
                           }
                        />
                     ) : (
                        <span className={`${styles.fuOperator}`}>{operator}</span>
                     )}
                     <SoSInputSelect
                        placeholder="Select Question"
                        isMulti={false}
                        options={questionOptions}
                        customStyle={"question-editor"}
                        containerProps={{ className: `${styles.cond_Q}` }}
                        value={questionOptions.find((o) => o.value === qid) || ""}
                        dropdownIndicator={null}
                        onChange={({ value: qid }) =>
                           qeDispatch({ type: "update-selected-question_fu-cond-qid", payload: { i, j, qid } })
                        }
                     />
                     <SoSInputSelect
                        placeholder="?"
                        isMulti={false}
                        options={fuOpOptions}
                        customStyle={"question-editor"}
                        containerProps={{ className: `${styles.cond_O}` }}
                        value={fuOpOptions.find((o) => o.value === op) || ""}
                        dropdownIndicator={null}
                        onChange={({ value: op }) =>
                           qeDispatch({ type: "update-selected-question_fu-cond-op", payload: { i, j, op } })
                        }
                     />
                     <SoSInputTitle
                        labelProps={{ className: `${styles.cond_TitleInput}` }}
                        value={value as string}
                        handleValue={(val) =>
                           qeDispatch({ type: "update-selected-question_fu-cond-val", payload: { i, j, val } })
                        }
                        type="text"
                     />
                     {j === 0 ? (
                        <QuestionSettingsAddButton
                           onClick={() => qeDispatch({ type: "update-selected-question_fu-cond-add", payload: { i } })}
                        />
                     ) : (
                        <QuestionSettingsXButton
                           onClick={() =>
                              qeDispatch({ type: "update-selected-question_fu-cond-del", payload: { i, j } })
                           }
                        />
                     )}
                  </li>
               ))}
            </ul>
            <div className={`${styles.divider}`}></div>
            <ul>
               {questions.map((qid, l) => (
                  <li key={l}>
                     <span>Add</span>
                     <SoSInputSelect
                        isMulti={false}
                        customStyle={"question-editor"}
                        containerProps={{ className: `${styles.cond_addQ}` }}
                        options={questionOptions}
                        value={questionOptions.find((o) => o.value === qid) || ""}
                        dropdownIndicator={null}
                        onChange={({ value: qid }) =>
                           qeDispatch({ type: "update-selected-question_fu-ques-sel", payload: { i, l, qid } })
                        }
                     />
                     {l === 0 ? (
                        <QuestionSettingsAddButton
                           onClick={() => qeDispatch({ type: "update-selected-question_fu-ques-add", payload: { i } })}
                        />
                     ) : (
                        <QuestionSettingsXButton
                           onClick={() =>
                              qeDispatch({ type: "update-selected-question_fu-ques-del", payload: { i, l } })
                           }
                        />
                     )}
                  </li>
               ))}
            </ul>
         </div>
         <QuestionSettingsDeleteButton
            onClick={() => qeDispatch({ type: "update-selected-question_fu-del", payload: { i } })}
         />
      </div>
   );
};

const QuestionSettingsRejForm = () => {
   const { qeState, qeDispatch } = useQEContext();
   const { qSelected } = qeState;
   const { rejections = [] } = qSelected || {};
   return (
      <div className={`${styles.rejForm}`}>
         <ul>
            {rejections.map((_, i) => (
               <li key={i}>
                  <QuestionSettingsRej i={i} />
               </li>
            ))}
         </ul>
         <QuestionSettingsAddButton
            onClick={() => qeDispatch({ type: "update-selected-question_rej-add" })}
            title="Add rejection"
         />
      </div>
   );
};

const QuestionSettingsRej = ({ i }: { i: number }) => {
   const { qeState, qeDispatch, qeHelper } = useQEContext();
   const { libraryQuestionsMap } = qeHelper;
   const { qSelected } = qeState;
   const { rejections = [] } = qSelected || {};
   const rej = rejections[i];
   const { conditions = [], operator = "and", rejection } = rej || {};
   const { header = "", message = "" } = rejection || {};
   const questionOptions = Object.entries(libraryQuestionsMap).map(([value, rawQ]) => ({
      value,
      label: rawQ && "question" in rawQ ? rawQ.question : "---",
   }));
   return (
      <div className={`${styles.conditionC}`}>
         <div>
            <ul>
               {conditions.map(({ qid = "", operator: op = "eq", value = "" }, j) => (
                  <li key={j}>
                     {j === 0 ? (
                        <SoSInputSelect
                           isMulti={false}
                           options={fuOperatorOptions}
                           customStyle={"question-editor"}
                           containerProps={{ className: `${styles.cond_OP}` }}
                           placeholder="?"
                           value={fuOperatorOptions.find((o) => o.value === operator) || ""}
                           dropdownIndicator={null}
                           onChange={(o) =>
                              qeDispatch({
                                 type: "update-selected-question_rej-operator",
                                 payload: { i, operator: o.value },
                              })
                           }
                        />
                     ) : (
                        <span className={`${styles.fuOperator}`}>{operator}</span>
                     )}
                     <SoSInputSelect
                        placeholder="Select Question"
                        isMulti={false}
                        options={questionOptions}
                        customStyle={"question-editor"}
                        containerProps={{ className: `${styles.cond_Q}` }}
                        value={questionOptions.find((o) => o.value === qid) || ""}
                        dropdownIndicator={null}
                        onChange={({ value: qid }) =>
                           qeDispatch({ type: "update-selected-question_rej-cond-qid", payload: { i, j, qid } })
                        }
                     />
                     <SoSInputSelect
                        placeholder="?"
                        isMulti={false}
                        options={fuOpOptions}
                        customStyle={"question-editor"}
                        containerProps={{ className: `${styles.cond_O}` }}
                        value={fuOpOptions.find((o) => o.value === op) || ""}
                        dropdownIndicator={null}
                        onChange={({ value: op }) =>
                           qeDispatch({ type: "update-selected-question_rej-cond-op", payload: { i, j, op } })
                        }
                     />
                     <SoSInputTitle
                        type="text"
                        value={value as string}
                        labelProps={{ className: `${styles.cond_TitleInput}` }}
                        handleValue={(val) =>
                           qeDispatch({ type: "update-selected-question_rej-cond-val", payload: { i, j, val } })
                        }
                     />
                     {j === 0 ? (
                        <QuestionSettingsAddButton
                           onClick={() => qeDispatch({ type: "update-selected-question_rej-cond-add", payload: { i } })}
                        />
                     ) : (
                        <QuestionSettingsXButton
                           onClick={() =>
                              qeDispatch({ type: "update-selected-question_rej-cond-del", payload: { i, j } })
                           }
                        />
                     )}
                  </li>
               ))}
            </ul>
            <div className={`${styles.divider}`}></div>
            <SoSInputTitle
               title="Header"
               type="text"
               value={header}
               labelProps={{ className: `${styles.cond_TitleInput}` }}
               handleValue={(header) =>
                  qeDispatch({ type: "update-selected-question_rej-header", payload: { i, header } })
               }
            />
            <SoSInputTitle
               title="Message"
               type="text"
               value={message}
               labelProps={{ className: `${styles.cond_TitleInput}` }}
               handleValue={(message) =>
                  qeDispatch({ type: "update-selected-question_rej-message", payload: { i, message } })
               }
            />
         </div>
         <QuestionSettingsDeleteButton
            onClick={() => qeDispatch({ type: "update-selected-question_rej-del", payload: { i } })}
         />
      </div>
   );
};

const QuestionSettingsDeleteButton = ({ onClick, title }: { title?: string; onClick: () => void }) => {
   return (
      <button className={`${styles.deleteButton}`} title={title || "delete-btn"} type="button" onClick={onClick}>
         <img src={CF_CDN_URL("/assets/question-editor/delete_negative.svg")} alt="" />
      </button>
   );
};

const QuestionSettingsAddButton = ({ onClick, title }: { title?: string; onClick: () => void }) => {
   return (
      <button className={`${styles.addButton}`} title={title || "add-btn"} type="button" onClick={onClick}>
         <img src={CF_CDN_URL("/assets/add_box_black.svg")} alt="" />
         {title && <span>{title}</span>}
      </button>
   );
};

const QuestionSettingsXButton = ({ onClick, title }: { title?: string; onClick: () => void }) => {
   return (
      <button className={`${styles.xButton}`} title={title || "x-btn"} type="button" onClick={onClick}>
         <img src={CF_CDN_URL("/assets/question-editor/close_negative.svg")} alt="" />
         {title && <span>{title}</span>}
      </button>
   );
};

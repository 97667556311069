import { useState } from "react";
import { usePViPropertyDetails } from "../../../../hooks/sosApps/propertyViability/usePViPropertyDetails";
import classNames from "classnames/bind";
import styles from "./PViAddress.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";

const cx = classNames.bind(styles);

export const PViAddress = () => {
   const { source } = usePViPropertyDetails();
   const [copySuccess, setCopySuccess] = useState<boolean>(false);
   if (!source) return;

   const handleCopyClick = () => {
      if (source.address_one_line) {
         navigator.clipboard
            .writeText(source.address_one_line)
            .then(() => {
               setCopySuccess(true);
               setTimeout(() => setCopySuccess(false), 2000);
            })
            .catch((err) => {
               console.error("Failed to copy address: ", err);
            });
      }
   };

   return (
      <div className={cx("addressC")}>
         <div className={cx("img-icon")}>
            <img src={CF_CDN_URL("/assets/property_validator/location_on_black.svg")} alt="" />
         </div>
         <span>{source.address_one_line}</span>
         <div className={cx("img-icon")} onClick={handleCopyClick}>
            <img src={CF_CDN_URL("/assets/property_validator/content_copy_black.svg")} alt="" />
         </div>
         {copySuccess && <span className={cx("copy-alert")}>Address copied to clipboard!</span>}
      </div>
   );
};

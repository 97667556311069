import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { usePViQueryParams } from "../../sosApps/propertyViability/usePViQueryParams";

export const useQPVi_FilterOptions = () => {
   const { sosAxios } = useSosAxios();
   const { status } = usePViQueryParams();
   const query = useQuery({
      queryKey: [`useQPV_FilterOptions`, status],
      queryFn: () =>
         sosAxios
            .get(`/property-viability/filters-options`, { params: { sourceStatus: status } })
            .then(({ data }: { data: PVi_FiltersOptions_Response }) => data),
   });

   return query;
};

export type PVi_NumberPropertyBySourcePrice = {
   sourcePrice: number;
   numberProperty: number;
};

export type PVi_FiltersOptions_Response = {
   market: string[];
   zip: string[];
   ptype: string[];
   numberPropertyBySourcePrice: PVi_NumberPropertyBySourcePrice[];
};

import { useQuery } from "@tanstack/react-query";
import { usePViContext } from "../../../contexts/PViContext";
import { useSosAxios } from "../../axios/useSosAxios";

export const useQPVi_Source = () => {
   const { sosAxios } = useSosAxios();

   const { pviState } = usePViContext();
   const { sid } = pviState;
   return useQuery({
      enabled: !!sid,
      queryKey: [`useQPVi_Source`, sid],
      queryFn: () =>
         sosAxios.get(`/property-viability/sources/${sid}`).then<TResponse>(({ data }) => {
            console.log("fetched source");
            return data;
         }),
   });
};

type TResponse = { source: useQPVi_Source_Property };
type useQPVi_Source_Property = PVi_GetSourceById_Source;
type PVi_GetSourceById_Source = {
   sid: string;
   ptype: string;
   address_one_line: string;
   market: string;
   zip: string;
   state: string;
   county: string;
   city: string;
   status: string;
   geocoding_data: string;
   geocoding_date: Date;
   atom_data: string;
   corelogic_data: string;
   sqft: number;
   year_built: number;
   price: number;
   street_view_url_400: string | null;
   coordinate: { lat: number; lng: number };
   units: { beds: number; baths: number }[];
};

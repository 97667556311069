import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { useAppContext } from "../../../contexts/AppContext";

export const useQ_User_profile = () => {
   const { sosAxios } = useSosAxios();
   const { credential } = useAppContext().appState;
   return useQuery({
      enabled: !!credential,
      queryKey: ["user/profile"],
      retry: false,
      queryFn: () =>
         sosAxios
            .get("/user/profile")
            .then<{ profile: { picture: string; email: string; name: string } }>(({ data }) => data),
   });
};

import classNames from "classnames/bind";
import styles from "./OffersViableProperties.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { useState } from "react";
import { SHrSosButton } from "@simplyhomes/react";
import SoSSlider, { SoSSlider_Item } from "../../../components/SoSSlider/SoSSlider";
import { useQOffers_GetViableProperties } from "../../../hooks/queries/offers/useQOffers_GetViableProperties";

const cx = classNames.bind(styles);

export const OffersViableProperties = () => {
   const [isOpenList, setIsOpenList] = useState(false);
   const [isCollapsed, setIsCollapsed] = useState(false);
   const qOfferViableProperties = useQOffers_GetViableProperties();

   const propertiesSliderData: SoSSlider_Item[] =
      qOfferViableProperties.data?.propertyIds.map((pid) => ({
         type: "offer-property-card",
         pid,
      })) || [];

   const propertyLength = qOfferViableProperties.data?.propertyIds.length;

   const handleOpenList = () => {
      setIsOpenList(!isOpenList);
      setIsCollapsed(true);
   };

   return (
      <div className={cx("topC")}>
         <span className={cx("title")}>Viable Properties {`${propertyLength ? `(${propertyLength})` : ""}`}</span>
         <SHrSosButton type="text" buttonProps={{ className: cx("btn"), onClick: handleOpenList }}>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/offer/arrow_downward_black.svg")} alt="" />
            </div>
            Expand List
         </SHrSosButton>
         <div className={cx("container", { expanded: isOpenList, collapsed: isCollapsed && !isOpenList })}>
            <div className={cx("sliderPropertyListC")}>
               <div className={cx("titleC")}>
                  <span className={cx("title")}>
                     Viable Properties {`${propertyLength ? `(${propertyLength})` : ""}`}
                  </span>
                  <SHrSosButton type="text" buttonProps={{ className: cx("btn"), onClick: handleOpenList }}>
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/offer/arrow_upward_black.svg")} alt="" />
                     </div>
                     Hide List
                  </SHrSosButton>
               </div>
               <SoSSlider items={propertiesSliderData}></SoSSlider>
            </div>
         </div>
      </div>
   );
};

import classNames from "classnames/bind";
import styles from "./PViAssetPoint.module.scss";

const cx = classNames.bind(styles);

export const PViAssetPoint = ({ icon, assetName, label, isDefault }: AssetTypeProps) => {
   return (
      <div className={cx("assetC")}>
         {label && (
            <span>
               <b>{label}</b>
            </span>
         )}
         <div className={cx("assetDataC", { default: isDefault })}>
            {icon && <div className={cx("img-icon")}>{icon && icon}</div>}
            {assetName && assetName}
         </div>
      </div>
   );
};

type AssetTypeProps = {
   icon?: JSX.Element;
   assetName: JSX.Element;
   label?: string | undefined;
   isDefault?: boolean | undefined;
};

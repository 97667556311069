import classNames from "classnames/bind";
import { useState } from "react";
import styles from "./PViDataPointNumber.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { SHu_js_currencyFormat } from "@simplyhomes/utils";

const cx = classNames.bind(styles);

export const PViDataPointNumber = ({ value, isStrongTitled, isPrice, isDefault }: DataPointNumberProps) => {
   return (
      <div className={cx("dataC")}>
         <span className={cx({ default: isDefault })}>
            {isStrongTitled ? (
               `${isPrice ? SHu_js_currencyFormat(value, { minimumFractionDigits: 3 }) : value}`
            ) : (
               <b>{isPrice ? SHu_js_currencyFormat(value, { minimumFractionDigits: 3 }) : value}</b>
            )}
         </span>

         <div className={cx("img-icon")}>
            <img src={CF_CDN_URL("/assets/property_validator/edit_black.svg")} alt="edit" />
         </div>
      </div>
   );
};

type DataPointNumberProps = {
   isStrongTitled?: boolean;
   value: number | string | undefined;
   isPrice?: boolean | undefined;
   isDefault?: boolean | undefined;
};

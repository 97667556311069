import { TableColumn } from "react-data-table-component";
import { THistory } from "./type";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";

export const allHistoryColumn: TableColumn<THistory>[] = [
   { name: <strong>Person</strong>, selector: (history_item) => history_item.person },
   { name: <strong>Time modified</strong>, selector: (history_item) => history_item.time_modified },
   { name: <strong>Tab</strong>, selector: (history_item) => history_item.tab },
   { name: <strong>Data label</strong>, selector: (history_item) => history_item.data_label },
   {
      name: <strong>Value changed</strong>,
      selector: (history_item) => `${history_item.current_value} -> ${history_item.value_changed}`,
   },
   {
      name: (
         <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <div style={{ display: "flex", alignItems: "center", width: "16px", height: "16px" }}>
               <img src={CF_CDN_URL("/assets/property_validator/icon_restore_negative_500.svg")} alt="" />
            </div>
            <span style={{ color: "#F2415A", fontSize: "14px" }}>Reset all Data</span>
         </div>
      ),
      cell: () => (
         <div style={{ display: "flex", alignItems: "center", gap: "8px", justifyContent: "center" }}>
            <div style={{ display: "flex", alignItems: "center", width: "16px", height: "16px" }}>
               <img src={CF_CDN_URL("/assets/property_validator/icon_restore_info_500.svg")} alt="" />
            </div>
            <span style={{ color: "#295BFF", fontSize: "14px" }}>Undo</span>
         </div>
      ),
   },
];

import { SHrSpinner } from "@simplyhomes/react";
import styles from "./PViMapView.module.scss";
import { GoogleMapWrapper } from "../../../components/GoogleMapWrapper/GoogleMapWrapper";
import { SoSMapPropertyMarker } from "../../../components/common/SoSMapPropertyMarker/SoSMapPropertyMarker";
import { useMemo } from "react";
import { usePViContext } from "../../../contexts/PViContext";
import { useQPVi_Sources } from "../../../hooks/queries/PVi/useQPVi_Sources";
export const PViMapView = () => {
   const { pviOverwrite } = usePViContext();

   const qPViSources = useQPVi_Sources({ noLimit: true });
   const { sources } = qPViSources;
   // const qPViProps = useQPV_Properties({ noLimit: true });
   // const { properties } = qPViProps;

   const propMarkers = useMemo(
      () =>
         sources.map((p) => ({
            ...p,
            address: p.address_one_line,
            bedrooms: p.units.reduce((acc, u) => acc + u.beds, 0),
            bathrooms: p.units.reduce((acc, u) => acc + u.baths, 0),
            unitCount: p.units.length,
            askingPrice: p.price,
            sqft: p.sqft || 0,
            streetViewUrl: p.street_view_url_150 || "",
         })),
      [sources]
   );
   if (qPViSources.isFetching)
      return (
         <article className={`${styles.contentC} ${styles.loading}`}>
            <SHrSpinner />
            <span>Loading Properties</span>
         </article>
      );
   return (
      <article className={`${styles.contentC}`}>
         <GoogleMapWrapper center={{ lat: 40.098844, lng: -95.8959109 }} zoom={5}>
            {propMarkers.map((s) => (
               <SoSMapPropertyMarker key={s.sid} property={s} onAddressClick={() => pviOverwrite({ sid: s.sid })} />
            ))}
         </GoogleMapWrapper>
      </article>
   );
};

import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useOffersContext } from "../../../../contexts/OffersContext";
import { useQOffers_GetTermTemplates } from "../../../queries/offers/termTemplate/useQOffers_GetTermTemplates";

export const useMTT_CreateTermTemplate = () => {
   const { sosAxios } = useSosAxios();
   const { offersDispatch } = useOffersContext();

   const qOfferTermTemplates = useQOffers_GetTermTemplates();

   const mutation = useMutation({
      mutationFn: (createTermTemplateProps: TCreateTermTemplateProps) =>
         sosAxios
            .post("/offers/term-templates", { name: createTermTemplateProps.name })
            .then<SoS_CreateTermTemplate_Response>(({ data }) => {
               if (!data) return null;
               qOfferTermTemplates.refetch();
               offersDispatch({ overwrite: { ttid: data?.template.tid } });
               return data;
            }),
   });

   return { ...mutation };
};

type TCreateTermTemplateProps = {
   name: string;
};

type SoS_CreateTermTemplate_TermTemplate = {
   tid: number;
};

type SoS_CreateTermTemplate_Response = { template: SoS_CreateTermTemplate_TermTemplate };

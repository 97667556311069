import { Dispatch, createContext, useContext, useReducer } from "react";
import { TAppCategory } from "../constants/apps";

type TAD_State = {
   selectedCategory: TAppCategory;
};

const initialState: TAD_State = {
   selectedCategory: "all",
};

type TAD_Action =
   | { type: "update_ABC"; payload: { value1: number } }
   | { overwrite: Partial<TAD_State> }
   | { type: "create"; payload: { username: string; password: string } };

export const AppsDrawerContext = createContext<{
   adState: TAD_State;
   adDispatch: Dispatch<TAD_Action>;
}>({ adState: initialState, adDispatch: () => {} });

const adReducer = (state: TAD_State, action: TAD_Action) => {
   if ("overwrite" in action) return { ...state, ...action.overwrite };
   switch (action.type) {
      default:
         return state;
   }
};

export const AppsDrawerProvider = ({ children }: { children: React.ReactNode }) => {
   const [adState, adDispatch] = useReducer(adReducer, initialState);
   return <AppsDrawerContext.Provider value={{ adState, adDispatch }}>{children}</AppsDrawerContext.Provider>;
};

export const useADContext = () => useContext(AppsDrawerContext);

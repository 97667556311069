import classNames from "classnames/bind";
import styles from "./PVipropertyDetailsTab.module.scss";
import { PViAssetPoint } from "../../PViAssetPoint/PViAssetPoint";
import { usePViPropertyDetails } from "../../../../../hooks/sosApps/propertyViability/usePViPropertyDetails";
import { CF_CDN_URL } from "../../../../../utils/CF_CDN_URL";
const cx = classNames.bind(styles);

export const PVipropertyDetailsTab = () => {
   return (
      <div className={cx("container")}>
         <PropertyOverview />

         <hr className={cx("divide-hr")} />

         <Characteristics />

         <hr className={cx("divide-hr")} />

         <FeaturesAndAmenities />
      </div>
   );
};

const PropertyOverview = () => {
   const { source } = usePViPropertyDetails();
   if (!source) return null;
   const { sqft, year_built, ptype } = source;
   return (
      <div className={cx("containerDetailC")}>
         <span className={cx("heading-title")}>Property Overview</span>
         <div className={cx("addressDetailC")}>
            <PViAssetPoint label="One line Adress" assetName={<span>1380 Ansel Rd, Cleveland, OH, USA</span>} />
            <PViAssetPoint label="Street Name 1" assetName={<span>Street</span>} isDefault />
            <PViAssetPoint label="Street Name 2" assetName={<span>Street</span>} isDefault />
            <PViAssetPoint label="City/town" assetName={<span>City</span>} isDefault />
            <PViAssetPoint label="State" assetName={<span>State</span>} isDefault />
         </div>

         <div className={cx("assetDetailC")}>
            <PViAssetPoint
               label="Square Feet"
               assetName={
                  <span>
                     {sqft ?? "null"} ft <sup>2</sup>
                  </span>
               }
               icon={<img src={CF_CDN_URL("/assets/property_validator/square_foot_black.svg")} alt="" />}
            />

            <PViAssetPoint
               label="Year Built"
               assetName={
                  <span>
                     {year_built ?? "null"} ft <sup>2</sup>
                  </span>
               }
               icon={<img src={CF_CDN_URL("/assets/construction_black.svg")} alt="" />}
            />

            <PViAssetPoint
               label="Property Type"
               assetName={<span>{ptype}</span>}
               icon={<img src={CF_CDN_URL("/assets/nav_home.svg")} alt="" />}
            />
         </div>
         <div className={cx("assetDetailC")}>
            <PViAssetPoint
               label="Neighborhood"
               assetName={<span>Neighborhood</span>}
               icon={<img src={CF_CDN_URL("/assets/property_validator/holiday_village_black.svg")} alt="" />}
               isDefault
            />
            <PViAssetPoint
               label="Days on Market"
               assetName={<span>6 DOM</span>}
               icon={<img src={CF_CDN_URL("/assets/property_validator/watch_later_black.svg")} alt="" />}
               isDefault
            />
            <PViAssetPoint
               label="Source"
               assetName={<span>MLS</span>}
               icon={<img src={CF_CDN_URL("/assets/property_validator/source_black.svg")} alt="" />}
               isDefault
            />
         </div>
      </div>
   );
};

const Characteristics = () => {
   return (
      <div className={cx("containerDetailC")}>
         <span className={cx("heading-title")}>Characteristics</span>
         <div className={cx("characteristicsDetailC")}>
            <PViAssetPoint label="Water Supply" assetName={<span>Public</span>} isDefault />
            <PViAssetPoint label="Sewage Type" assetName={<span>Septic</span>} isDefault />
            <PViAssetPoint label="Drain Type" assetName={<span>Cast iron</span>} isDefault />
            <PViAssetPoint label="Construction" assetName={<span>Type</span>} isDefault />
            <PViAssetPoint label="Foundation" assetName={<span>Type</span>} isDefault />
            <PViAssetPoint label="Siding" assetName={<span>Type</span>} isDefault />
            <PViAssetPoint label="Roof Type" assetName={<span>Type</span>} isDefault />
            <PViAssetPoint label="Roof Age" assetName={<span>Type</span>} isDefault />
            <PViAssetPoint label="Stories" assetName={<span>Type</span>} isDefault />
         </div>
      </div>
   );
};

const FeaturesAndAmenities = () => {
   return (
      <div className={cx("containerDetailC")}>
         <span className={cx("heading-title")}>Features and Amenities</span>
         <div className={cx("featureDetailC")}>
            <PViAssetPoint label="Has Attic" assetName={<span>Yes</span>} isDefault />
            <PViAssetPoint label="Has Deck" assetName={<span>No</span>} isDefault />
            <PViAssetPoint label="Has Patio" assetName={<span>Yes</span>} isDefault />
            <PViAssetPoint label="Has Common Space" assetName={<span>No</span>} isDefault />
            <PViAssetPoint label="Has Yard" assetName={<span>Yes</span>} isDefault />
            <PViAssetPoint label="Has Elevator" assetName={<span>No</span>} isDefault />
         </div>
         <div className={cx("featureDetailC")}>
            <PViAssetPoint label="Has Basement" assetName={<span>Yes</span>} isDefault />
            <PViAssetPoint label="Has Common Laundry" assetName={<span>No</span>} isDefault />
            <PViAssetPoint label="Has Garage" assetName={<span>Yes</span>} isDefault />
            <PViAssetPoint label="Has Gate" assetName={<span>No</span>} isDefault />
            <PViAssetPoint label="Has Pool" assetName={<span>Yes</span>} isDefault />
            <PViAssetPoint label="Has Porch" assetName={<span>No</span>} isDefault />
         </div>
         <div className={cx("featureDetailC")}>
            <PViAssetPoint label="Has Sidewalk" assetName={<span>Yes</span>} isDefault />
            <PViAssetPoint label="Has Driveway" assetName={<span>No</span>} isDefault />
            <PViAssetPoint label="Has Exterior Stairs" assetName={<span>Yes</span>} isDefault />
         </div>
      </div>
   );
};

import classNames from "classnames/bind";

import styles from "./NotificationModal.module.scss";
import { SHrSosButton } from "@simplyhomes/react";

const cx = classNames.bind(styles);

type NotificationModalProps = {
   icon: JSX.Element;
   title: string;
   description?: string;
   buttonTitle: string;
   mainAction: () => void;
};

const NotificationModal = ({ icon, title, description, buttonTitle, mainAction }: NotificationModalProps) => {
   return (
      <div className={cx("container")}>
         <div className={cx("context")}>
            <div className={cx("icon")}>{icon}</div>
            <div className={cx("content-wrapper")}>
               <span className={cx("title")}>{title}</span>
               <span className={cx("description")}>{description}</span>
            </div>
         </div>

         <div className={cx("btn-bottom")}>
            <SHrSosButton buttonProps={{ onClick: mainAction }}>{buttonTitle}</SHrSosButton>
         </div>
      </div>
   );
};

export default NotificationModal;

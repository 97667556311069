import { useCallback, useRef } from "react";

export const useDebounceCallback = (callback: (...args: any[]) => void, delay: number) => {
   const timeoutRef = useRef<NodeJS.Timeout | null>(null);

   const debouncedCallback = useCallback(
      (...args: any[]) => {
         if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
         }
         timeoutRef.current = setTimeout(() => {
            callback(...args);
         }, delay);
      },
      [callback, delay]
   );

   return { debouncedCallback };
};

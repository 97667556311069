import { usePViContext } from "../../../contexts/PViContext";
import { useQPVi_Source } from "../../queries/PVi/useQPVi_Source";

export const usePViPropertyDetails = () => {
   const { pviState, pviDispatch: pvDispatch, pviOverwrite } = usePViContext();
   const qPViSource = useQPVi_Source();
   const { source } = qPViSource.data || {};
   const isNew = source?.status === "New";
   return { source, isNew, pviState, pvDispatch, qPViSource, pviOverwrite };
};

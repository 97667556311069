import { createContext, useContext, useReducer } from "react";

class OffersState {
   ttid: string = "";
   lid: string = "";
}
type TOffersAction = { type: ""; payload: {} } | { overwrite: TOffersOverwrite };
type TOffersOverwrite = Partial<OffersState>;

export const OffersContext = createContext<{ offersState: OffersState; offersDispatch: React.Dispatch<TOffersAction> }>(
   {
      offersState: new OffersState(),
      offersDispatch: () => {},
   }
);

const offersReducer = (state: OffersState, action: TOffersAction): OffersState => {
   if ("overwrite" in action) {
      return { ...state, ...action.overwrite };
   }
   switch (action.type) {
      default:
         break;
   }
   return state;
};

export const OffersProvider = ({ children }: { children: React.ReactNode }) => {
   const [offersState, offersDispatch] = useReducer(offersReducer, new OffersState());

   return (
      <OffersContext.Provider value={{ offersState: offersState, offersDispatch: offersDispatch }}>
         {children}
      </OffersContext.Provider>
   );
};

export const useOffersContext = () => {
   const context = useContext(OffersContext);
   const offersOverwrite = (overwrite: TOffersOverwrite) => context.offersDispatch({ overwrite });
   return { ...context, offersOverwrite };
};

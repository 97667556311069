import classNames from "classnames/bind";
import styles from "./PViGridView.module.scss";
import { SHrSosButton } from "@simplyhomes/react";
import { usePViContext } from "../../../contexts/PViContext";
import { useQPVi_Sources } from "../../../hooks/queries/PVi/useQPVi_Sources";
import { PViPropertyCard } from "../PViPropertyCard/PViPropertyCard";

const cx = classNames.bind(styles);

export const PViGridView = () => {
   const { pviOverwrite } = usePViContext();

   const qPViSources = useQPVi_Sources();
   const { sources = [] } = qPViSources;

   const handleClickProperty = (sid: string) => {
      pviOverwrite({ sid, showDetailsModal: true });
   };

   return (
      <div className={cx("viewC")}>
         {sources.map((source, index) => (
            <PViPropertyCard source={source} key={source.sid + index} onClick={() => handleClickProperty(source.sid)} />
         ))}

         <div className={cx("footer")}>
            <SHrSosButton
               buttonProps={{
                  onClick: () => qPViSources.fetchNextPage(),
                  className: cx("load-more", { hidden: !qPViSources.hasNextPage || qPViSources.isFetchingNextPage }),
               }}
            >
               Load More
            </SHrSosButton>
         </div>
      </div>
   );
};

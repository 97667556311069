import { TableColumn, TableStyles, defaultThemes } from "react-data-table-component";
import { TProperty } from "../../contexts/CsvUpContext";
import PropertyStatus from "./PropertyStatus/PropertyStatus";

export const propertyColumns: TableColumn<TProperty>[] = [
   {
      name: <strong>Status</strong>,
      width: "228px",
      cell: ({ isValid, reason }: TProperty) => {
         return <PropertyStatus isValid={isValid} reason={reason} />;
      },
   },
   {
      name: <strong>Street Address</strong>,
      selector: (property: TProperty) => property.address,
   },
   {
      name: <strong>City</strong>,
      selector: (property: TProperty) => property.city,
   },
   {
      name: <strong>State</strong>,
      selector: (property: TProperty) => property.stateOrProvince,
   },
   {
      name: <strong>Property Type</strong>,
      selector: (property: TProperty) => property.propertyType,
   },
   {
      name: <strong>Price</strong>,
      width: "150px",
      cell: (property: TProperty) => <div className="col-center">{property.price}</div>,
   },
   {
      name: <strong>Total Beds</strong>,
      width: "100px",
      cell: (property: TProperty) => <div className="col-center">{property.beds}</div>,
   },
   {
      name: <strong>Baths</strong>,
      width: "100px",
      cell: (property: TProperty) => <div className="col-center">{property.baths}</div>,
   },
   {
      name: <strong>Square Feet</strong>,
      width: "150px",
      cell: (property: TProperty) => <div className="col-center">{property.sqft}</div>,
   },
   {
      name: <strong>Year Built</strong>,
      width: "100px",
      cell: (property: TProperty) => <div className="col-center">{property.yearBuilt}</div>,
   },
   {
      name: <strong>#MLS</strong>,
      selector: (property: TProperty) => property.mlsId,
   },
];

export const customStyles: TableStyles = {
   header: {
      style: {
         minHeight: "56px",
      },
   },
   headRow: {
      style: {
         borderTopStyle: "solid",
         borderTopWidth: "1px",
         borderTopColor: "#D4D5D9",
      },
   },
   headCells: {
      style: {
         "&:not(:last-of-type)": {
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderRightColor: "#D4D5D9",
         },
      },
   },
   cells: {
      style: {
         "&:not(:last-of-type)": {
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderRightColor: "#D4D5D9",
         },
      },
   },
};

import classNames from "classnames/bind";

import styles from "./PropertyStatus.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";

const cx = classNames.bind(styles);

type PropertyStatusProps = {
   isValid: boolean;
   reason: string;
};

const PropertyStatus = ({ isValid, reason }: PropertyStatusProps) => {
   return (
      <div className={cx("container")}>
         <img
            src={CF_CDN_URL(
               `${isValid ? "/assets/csv_upload/check_black.svg" : "/assets/csv_upload/warning_amber_black.svg"}`
            )}
            alt=""
            className={cx("img", {
               valid: isValid,
               invalid: !isValid,
            })}
         />
         <span
            className={cx({
               valid: isValid,
               invalid: !isValid,
            })}
         >
            {isValid ? "Ready to submit" : reason}
         </span>
      </div>
   );
};

export default PropertyStatus;

import { useAioAxios } from "../../axios/useAioAxios";

export const useQFns_AD = () => {
   const { aioAxios } = useAioAxios();
   const qfns_AD = {
      q_getSubscribedApps: () =>
         aioAxios.get("/user/apps/subscribed").then<TgetSubscribedApps_Resp>(({ data }) => data),
      subscribeApp: ({ appId, subscribe }: { appId: string; subscribe: boolean }) =>
         aioAxios.post("/user/apps/subscribed", {}, { params: { appId, subscribe } }).catch((err) => err),
   };
   return { qfns_AD };
};
type TgetSubscribedApps_Resp = { appIds: string[] };

import { useIsMutating, useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { useQPVi_SourceStatuses } from "../../queries/PVi/useQPVi_SourceStatuses";
import { usePViPropertyDetails } from "../../sosApps/propertyViability/usePViPropertyDetails";
import { useMPVi_QuickPickSource } from "./useMPVi_QuickPickSource";
import { useQPVi_Source } from "../../queries/PVi/useQPVi_Source";
import { useQPVi_Sources } from "../../queries/PVi/useQPVi_Sources";

export const useMPVi_UpdateSourceStatus = () => {
   const { sosAxios } = useSosAxios();

   const qPViSourceStatuses = useQPVi_SourceStatuses();
   const qPViSource = useQPVi_Source();
   const qPViSources = useQPVi_Sources();

   const qPViQPSource = useMPVi_QuickPickSource();

   const { sid } = usePViPropertyDetails().source || {};
   const mutationKey = ["useMPVi_UpdateSourceStatus"];
   const isMutating = useIsMutating({ mutationKey });
   const mutation = useMutation({
      mutationKey,
      mutationFn: ({ status, reasons, rejectDetail, getNextQuickPick }: UpdateSourceData) =>
         sosAxios
            .patch(`/property-viability/sources/statuses/${sid}`, { status, reasons, rejectDetail })
            .then(({ data }: { data: UpdateSourceResponse }) => {
               if (getNextQuickPick) qPViQPSource.mutate();
               else qPViSource.refetch();
               qPViSources.refetch();
               qPViSourceStatuses.refetch();
               return data;
            }),
   });
   return { ...mutation, isMutating };
};

type UpdateSourceResponse = { message: string };

type UpdateSourceData = { status: string; reasons?: string[]; rejectDetail?: string; getNextQuickPick: boolean };

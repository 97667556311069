import classNames from "classnames/bind";
import styles from "./OffersPropertyCard.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { SHu_js_currencyFormat } from "@simplyhomes/utils";
import { SHrSosButton } from "@simplyhomes/react";
import { useQOffers_GetViableProperties } from "../../../hooks/queries/offers/useQOffers_GetViableProperties";
import { useState } from "react";

const cx = classNames.bind(styles);

export const OffersPropertyCard = ({ pid }: TOffersPropertyCardProps) => {
   const [copySuccess, setCopySuccess] = useState<boolean>(false);
   const qOfferViableProperties = useQOffers_GetViableProperties();
   if (!qOfferViableProperties.data) return;
   const { propertiesMap } = qOfferViableProperties.data;
   const property = propertiesMap[pid];

   if (!property) return null;

   const { street_view_url_150, asking_price, address_one_line } = property;

   const handleCopyClick = () => {
      if (address_one_line) {
         navigator.clipboard
            .writeText(address_one_line)
            .then(() => {
               setCopySuccess(true);
               setTimeout(() => setCopySuccess(false), 2000);
            })
            .catch((err) => {
               console.error("Failed to copy address: ", err);
            });
      }
   };
   return (
      <div className={cx("container")}>
         <div className={cx("property-img")}>
            <img src={street_view_url_150} alt="" />
         </div>
         <div className={cx("contentC")}>
            {copySuccess && <span className={cx("copy-alert")}>Address copied to clipboard!</span>}

            <div className={cx("addressC")}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/offer/location_on_black.svg")} alt="" />
               </div>
               <span>{address_one_line}</span>
               <div className={cx("img-icon", "cursor-pointer")} onClick={handleCopyClick}>
                  <img src={CF_CDN_URL("/assets/offer/content_copy_black.svg")} alt="" />
               </div>
            </div>
            <div className={cx("numbersC")}>
               <div className={cx("price")}>
                  <span>Asking Price</span>
                  <span className={cx("number-price")}>{SHu_js_currencyFormat(asking_price)}</span>
               </div>
               <div className={cx("price")}>
                  <span>Current Max Price</span>
                  <span className={cx("number-price")}>--</span>
               </div>
            </div>
            <div className={cx("btns")}>
               <SHrSosButton type="outlined" buttonProps={{ className: cx("btn") }}>
                  Review Property
               </SHrSosButton>
               <SHrSosButton buttonProps={{ className: cx("btn") }}>
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/plus_white.svg")} alt="" />
                  </div>
                  Create Lead
               </SHrSosButton>
            </div>
         </div>
      </div>
   );
};

type TOffersPropertyCardProps = {
   pid: string;
};

import classNames from "classnames/bind";
import styles from "./OffersSimplyTerms.module.scss";
import { SoSInputTitle } from "../../../../components/common/soSInputTitle/SoSInputTitle";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { useQOffers_GetTermTemplateById } from "../../../../hooks/queries/offers/termTemplate/useQOffers_GetTermTemplateById";
import { SoSInputSelect } from "../../../../components/common/SoSInputSelect/SoSInputSelect";
import { useQSoS_Options, useSoSOptions_Offers_Simply_Terms } from "../../../../hooks/queries/options/useQSoS_Options";
import { useEffect, useState } from "react";

const cx = classNames.bind(styles);

const generateId = () => Math.random().toString(36).substr(2, 9);

type RowSimplyTermData = {
   id: string;
   title: string;
   default: string;
};

type OffersSimplyTermsProps = {
   triggerAddNewItem: boolean;
};

export const OffersSimplyTerms = ({ triggerAddNewItem }: OffersSimplyTermsProps) => {
   const qOfferTermTemplate = useQOffers_GetTermTemplateById();
   const simplyTermTemplatesData = qOfferTermTemplate.data?.template?.data.simply;
   const [simplyTermTemplates, setSimplyTermTemplates] = useState<RowSimplyTermData[]>(simplyTermTemplatesData!);

   useEffect(() => {
      if (!simplyTermTemplatesData) return;
      setSimplyTermTemplates(simplyTermTemplatesData.map((t, i) => ({ ...t, id: generateId() })));
   }, [simplyTermTemplatesData]);

   useEffect(() => {
      if (!simplyTermTemplates) return;
      setSimplyTermTemplates([...simplyTermTemplates, { title: "", default: "", id: generateId() }]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [triggerAddNewItem]);

   const handleDeleteRowItem = (id: string) => {
      console.log(`delete ${id}`);
      console.log(simplyTermTemplates.filter((t) => t.id !== id));
      setSimplyTermTemplates(simplyTermTemplates.filter((t) => t.id !== id));
   };

   console.log(simplyTermTemplates);

   return simplyTermTemplates?.map((t, i) => (
      <RowSimplyTerm key={i} itemRow={t} onDelete={(id) => handleDeleteRowItem(id)} />
   ));
};

type RowSimplyTermProps = {
   itemRow: RowSimplyTermData;
   onDelete: (id: string) => void;
};

const RowSimplyTerm = ({ itemRow: initItemRow, onDelete }: RowSimplyTermProps) => {
   const [item, setItem] = useState(initItemRow);
   const { data: simplyTermsData } = useSoSOptions_Offers_Simply_Terms();
   const { data: simplyTermsDataRight } = useQSoS_Options({ moid: `sos_simply_term_${item.title}` });

   const simpyTermsValue = simplyTermsData?.options || [];
   const simplyTerms = simpyTermsValue.map((t) => ({ label: t.label, value: t.value }));

   const simpyTermsRightValue = simplyTermsDataRight?.options || [];
   const simplyTermsRight = simpyTermsRightValue.map((t) => ({ label: t.label, value: t.value }));

   return (
      <div className={cx("simplyTermsC")}>
         <div className={cx("leftInputTermsC")}>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/drag_indicator_black.svg")} alt="" />
            </div>
            <div className={cx("input-select")}>
               <SoSInputSelect
                  title="Type"
                  isMulti={false}
                  value={simplyTerms.find((t) => t.value === item.title)?.label!}
                  onChange={(o) =>
                     setItem({
                        ...item,
                        title: o.value,
                        default: o.value === initItemRow.default ? initItemRow.default : "",
                     })
                  }
                  options={simplyTerms}
                  isLoading={!simplyTerms.length}
               />
            </div>
         </div>
         <div className={cx("rightInputTermsC")}>
            <div className={cx("input-select")}>
               {simpyTermsRightValue.length ? (
                  <SoSInputSelect
                     title="Default"
                     isMulti={false}
                     value={simplyTermsRight.find((t) => t.value === initItemRow.default)?.label!}
                     onChange={(o) => {}}
                     options={simpyTermsRightValue}
                     isLoading={!simplyTermsRight.length}
                  />
               ) : (
                  <SoSInputTitle
                     type="text"
                     title="Default"
                     value={item.title === initItemRow.title ? initItemRow.default : item.default}
                     handleValue={() => {}}
                  />
               )}
            </div>
            <div className={cx("img-icon", "delete")} onClick={() => onDelete(item.id)}>
               <img src={CF_CDN_URL("/assets/delete_outline_black.svg")} alt="" />
            </div>
         </div>
      </div>
   );
};

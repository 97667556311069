import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";

export const useQQE_Flows = () => {
   const { sosAxios } = useSosAxios();
   return useQuery({
      queryKey: ["QQE_Flows"],
      queryFn: () => sosAxios.get("/qeditor/flows").then<TData>(({ data }) => data),
   });
};
type TData = {
   flows: { fid: string; name: string }[];
};

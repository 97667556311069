import classNames from "classnames/bind";
import DataTable from "react-data-table-component";
import PapaParse from "papaparse";
import { SHrSosButton } from "@simplyhomes/react";
import { FileUploader } from "react-drag-drop-files";
import { useState } from "react";

import styles from "./CsvUploadPage.module.scss";
import { CF_CDN_URL } from "../../utils/CF_CDN_URL";
import { customStyles, propertyColumns } from "./data-table-columns";
import NotificationModal from "../../components/modalComponent/NotificationModal/NotificationModal";
import { useMCU_CreateProperty } from "../../hooks/mutates/useMCU_CreateProperty";
import { SoSInputSelect } from "../../components/common/SoSInputSelect/SoSInputSelect";
import {
   CsvUpProvider,
   PROPERTY_DOCUMENT_HEADERS_BY_PURPOSE,
   PROPERTY_PURPOSE_OPTIONS,
   PROPERTY_STATUS_FAILED,
   PropertyPurposeOptionType,
   TProperty,
   TPropertyCompact,
   useCsvUpContext,
} from "../../contexts/CsvUpContext";
import { useMCU_ValidateClevelandMls } from "../../hooks/mutates/useMCU_ValidateClevelandMls";

const cx = classNames.bind(styles);

const csvUploadHelper = {
   transformProperties: (rawData: Record<string, any>[]): TProperty[] =>
      rawData.map((o) => ({
         propertyType: o["PROPERTY TYPE"],
         address: o["ADDRESS"],
         city: o["CITY"],
         stateOrProvince: o["STATE OR PROVINCE"],
         zipOrPostalCode: o["ZIP OR POSTAL CODE"],
         price: Number(o["PRICE"]),
         beds: Number(o["BEDS"]),
         baths: Number(o["BATHS"]),
         sqft: Number(o["SQUARE FEET"]) || 0,
         yearBuilt: Number(o["YEAR BUILT"]),
         mlsId: o["MLS#"],
         reason: "",
         isValid: true,
      })),
   compactProperties: (properties: TProperty[]): TPropertyCompact[] =>
      properties.map(({ address, city, stateOrProvince, zipOrPostalCode, beds, baths, propertyType, ...proRest }) => {
         let units = [{ beds, baths }];
         if (propertyType.startsWith("Multi") && baths > 1 && beds > 1) {
            units = [
               {
                  beds: Math.floor(beds / 2),
                  baths: Math.floor(baths / 2),
               },
               {
                  beds: beds - Math.floor(beds / 2),
                  baths: baths - Math.floor(baths / 2),
               },
            ];
         }
         return {
            fullAddress: `${address}, ${city}, ${stateOrProvince}, ${zipOrPostalCode}, USA`,
            units,
            ...proRest,
         };
      }),
};

const CsvUploadPage = () => {
   return (
      <CsvUpProvider>
         <CsvUpload />
      </CsvUpProvider>
   );
};

const CsvUploadStage = () => {
   const { csvUpDispatch } = useCsvUpContext();
   const { mutateAsync: validateClevelandMls, isPending } = useMCU_ValidateClevelandMls();

   const [currentFile, setCurrentFile] = useState<File | null>(null);
   const [purposeSelected, setPurposeSelected] = useState<PropertyPurposeOptionType | null>(null);
   const [tempData, setTempData] = useState<Record<string, any>[]>([]);
   const [typeErrorFile, setTypeErrorFile] = useState("");

   const neededColumns = purposeSelected ? PROPERTY_DOCUMENT_HEADERS_BY_PURPOSE[purposeSelected.value] : [];
   const headers = tempData.length > 0 ? Object.keys(tempData[0]) : [];
   const missingCols = tempData.length > 0 ? neededColumns.filter((key) => !headers.includes(key)) : [];

   const handleChange = (fileSelected: File) => {
      if (!fileSelected) return;

      setCurrentFile(fileSelected);
      setTypeErrorFile("");

      PapaParse.parse<any>(fileSelected, {
         header: true,
         skipEmptyLines: true,
         complete: (results) => {
            setTempData(results.data);
         },
      });
   };

   const handleReadingFile = async () => {
      if (tempData.length === 0) return;
      const transformedData = csvUploadHelper.transformProperties(tempData);
      const compactedProperties = csvUploadHelper.compactProperties(transformedData);

      const res = await validateClevelandMls({
         properties: compactedProperties,
      });

      if (res && res.statusMap) {
         const { statusMap } = res;
         const propertiesFiltered = [...transformedData];
         for (const fullAddressKey in statusMap) {
            const index = propertiesFiltered.findIndex(
               ({ address, city, stateOrProvince, zipOrPostalCode }) =>
                  `${address}, ${city}, ${stateOrProvince}, ${zipOrPostalCode}, USA` === fullAddressKey
            );

            if (index >= 0) {
               propertiesFiltered[index].isValid = !PROPERTY_STATUS_FAILED.includes(statusMap[fullAddressKey].status);
               propertiesFiltered[index].reason = statusMap[fullAddressKey].reason;
            }
         }
         csvUpDispatch({ overwrite: { stage: "preview", properties: propertiesFiltered } });
      }
   };

   const handleSelectePurpose = (e: any) => {
      setPurposeSelected(e);
   };

   return (
      <div className={cx("container")}>
         <div className={cx("inner")}>
            <div className={cx("drag-and-drop-container")}>
               <div className={cx("drag-and-drop-titles")}>
                  <h1>Upload File</h1>
                  <span>You are about to submit new properties to</span>
                  <SoSInputSelect
                     options={PROPERTY_PURPOSE_OPTIONS}
                     value={purposeSelected!}
                     onChange={handleSelectePurpose}
                     placeholder="Choose the purpose"
                     isMulti={false}
                  />
               </div>

               <div className={cx("drag-and-drop-file")}>
                  <FileUploader
                     handleChange={handleChange}
                     name="file"
                     types={["CSV"]}
                     classes={cx("file-uploader")}
                     onTypeError={() => setTypeErrorFile("Please select file with CSV extention")}
                  >
                     <div className={cx("drag-and-drop-file-inner")}>
                        <div className={cx("drag-and-drop-icon")}>
                           <div>
                              <img src={CF_CDN_URL("/assets/file_upload_black.svg ")} alt="" />
                           </div>
                           <span>Drag & drop or</span>
                        </div>
                        <SHrSosButton type="outlined">Choose file to Upload</SHrSosButton>
                        <span>Valid file format is (CSV,...) - maximum ...MB</span>
                     </div>
                  </FileUploader>
               </div>
               {((currentFile && purposeSelected && !missingCols.length && !typeErrorFile) ||
                  (currentFile && !purposeSelected && !typeErrorFile)) && (
                  <div className={cx("drag-and-drop-file-name")}>
                     <span>{currentFile.name}</span>
                     <div onClick={() => setCurrentFile(null)}>
                        <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                     </div>
                  </div>
               )}
               {(missingCols.length > 0 || typeErrorFile) && (
                  <div className={cx("drag-and-drop-file-status")}>
                     <div>
                        <img src={CF_CDN_URL("/assets/warning_black.svg")} alt="" />
                     </div>

                     <span>
                        {typeErrorFile
                           ? "Please select file with CSV extention"
                           : `Missing these columns: ${missingCols.join(", ")}`}
                     </span>
                  </div>
               )}

               <SHrSosButton
                  buttonProps={{
                     className: cx("reading-file-button"),
                     disabled: missingCols.length > 0 || !purposeSelected || !currentFile,
                     onClick: handleReadingFile,
                  }}
                  loading={isPending}
               >
                  Start Reading File
               </SHrSosButton>
            </div>
            <div className={cx("bg-right")}>
               <img src={CF_CDN_URL("/assets/upload_background.png")} alt="" />
            </div>
         </div>
      </div>
   );
};

const CsvPreviewStage = () => {
   const { csvUpState, csvUpDispatch } = useCsvUpContext();
   const { properties } = csvUpState;
   const { mutateAsync: createPropertyMutateAsync, isPending } = useMCU_CreateProperty();
   const handleSubmitValidProperties = async () => {
      // call API this here
      try {
         const compactedProperties = csvUploadHelper.compactProperties(properties.filter((p) => p.isValid));

         const uploadResp = await createPropertyMutateAsync({ properties: compactedProperties });
         console.log({ uploadResp });
         csvUpDispatch({ overwrite: { stage: "success" } });
      } catch (error) {
         console.log({ error });
         csvUpDispatch({ overwrite: { stage: "error" } });
      }
   };

   return (
      <div className={cx("preview-stage-container")}>
         <div className={cx("control-bar")}>
            <span>
               {properties.filter((p) => p.isValid).length}/{properties.length} properties ready to upload
            </span>
            <div className={cx("actions")}>
               <SHrSosButton
                  type="elevated"
                  buttonProps={{
                     onClick: () => csvUpDispatch({ overwrite: { stage: "upload", properties: [] } }),
                  }}
               >
                  Choose A Different File
               </SHrSosButton>
               <SHrSosButton
                  buttonProps={{
                     onClick: handleSubmitValidProperties,
                  }}
                  loading={isPending}
               >
                  Submit Valid Properties
               </SHrSosButton>
            </div>
         </div>
         <div className={cx("table")}>
            <DataTable
               columns={propertyColumns}
               data={properties}
               highlightOnHover
               customStyles={customStyles}
               noDataComponent={<span>NO RECORD</span>}
            />
         </div>
      </div>
   );
};

const CsvErrorStage = () => {
   const { csvUpDispatch } = useCsvUpContext();

   const handleFinishAction = () => {
      csvUpDispatch({ overwrite: { stage: "upload", properties: [] } });
   };

   return (
      <div className={cx("csv-notificate-container")}>
         <NotificationModal
            icon={<img src={CF_CDN_URL("/assets/error_circle_black.svg")} alt="" className={cx("error-img")} />}
            title={"Fail to submit properties"}
            description="Something went wrong. Please try again with another valid file"
            buttonTitle={"Error"}
            mainAction={handleFinishAction}
         />
      </div>
   );
};

const CsvSuccessStage = () => {
   const { csvUpDispatch } = useCsvUpContext();

   const handleFinishAction = () => {
      csvUpDispatch({ overwrite: { stage: "upload", properties: [] } });
   };

   return (
      <div className={cx("csv-notificate-container")}>
         <NotificationModal
            icon={<img src={CF_CDN_URL("/assets/check_circle_black.svg")} alt="" className={cx("success-img")} />}
            title={"Submission successful"}
            description="Processing"
            buttonTitle={"Finish"}
            mainAction={handleFinishAction}
         />
      </div>
   );
};

const CsvUpload = () => {
   const { csvUpState } = useCsvUpContext();
   const { stage } = csvUpState;
   return (
      <>
         {stage === "upload" && <CsvUploadStage />}
         {stage === "preview" && <CsvPreviewStage />}
         {stage === "success" && <CsvSuccessStage />}
         {stage === "error" && <CsvErrorStage />}
      </>
   );
};

export default CsvUploadPage;

import { SHrSosButton, SHrSosTabsBar } from "@simplyhomes/react";
import classNames from "classnames/bind";
import { useState } from "react";
import styles from "./PViConversation.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";

const cx = classNames.bind(styles);

const tabsRight = ["Comment", "Seller outreach"];
export const PViConversation = () => {
   const [tabRightSelected, setTabRightSelected] = useState<(typeof tabsRight)[number]>(tabsRight[0]);
   return (
      <div className={cx("container")}>
         <SHrSosTabsBar tabs={tabsRight} value={tabRightSelected} onChange={setTabRightSelected} />

         <div className={cx("btnNotifyAndSlack")}>
            <SHrSosButton buttonProps={{ className: cx("btn", "white") }}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_validator/notification_add_black.svg")} alt="" />
               </div>
               Notify Me
            </SHrSosButton>
            <SHrSosButton buttonProps={{ className: cx("btn") }}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_validator/slack_icon.svg")} alt="" />
               </div>
               Link to Slack
            </SHrSosButton>
         </div>

         <div className={cx("commentTabC")}>
            <div className={cx("typeComment")}></div>
            <div className={cx("allCommentC")}>
               <div className={cx("commentC")}>
                  <div className={cx("avt")}></div>
                  <div className={cx("comment")}></div>
               </div>
            </div>
         </div>
      </div>
   );
};

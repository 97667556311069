import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";

export const useQSoS_Options = ({ moid }: TQSoS_Options_Props) => {
   const { sosAxios } = useSosAxios();
   const query = useQuery<SoS_GetOptions_Response>({
      queryKey: [`useQSoS_Options`, moid],
      queryFn: () =>
         sosAxios
            .get(`/options`, {
               params: {
                  moid,
               },
            })
            .then<SoS_GetOptions_Response>(({ data }) => data),
   });

   return query;
};

type TQSoS_Options_Props = {
   moid: string;
};

export type SoS_GetOptions_Option = {
   value: string;
   label: string;
};

export type SoS_GetOptions_Response = { options: SoS_GetOptions_Option[] };

export const useSoSOptions_PVi_Source_Reject_Reasons = () => useQSoS_Options({ moid: "sos_source_reject_reason" });
export const useSoSOptions_Offers_Simply_Terms = () => useQSoS_Options({ moid: "sos_simply_term" });

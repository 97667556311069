import { useQuery } from "@tanstack/react-query";

import { useOffersContext } from "../../../../contexts/OffersContext";
import { useSosAxios } from "../../../axios/useSosAxios";

export const useQOffers_GetTermTemplateById = () => {
   const { sosAxios } = useSosAxios();
   const { offersState } = useOffersContext();
   const { ttid } = offersState;
   return useQuery({
      enabled: !!ttid,
      queryKey: [`useQOffers_GetTermTemplateById`, ttid],
      queryFn: () =>
         sosAxios
            .get(`/offers/term-templates/${ttid}`)
            .then<SoS_GetTermTemplateById_Response>(({ data }: { data: SoS_GetTermTemplateById_Response }) => {
               return data;
            }),
   });
};

type SoS_GetTermTemplateById_TermTemplate_Data_Field = {
   simply: {
      id: string;
      title: string;
      default: string;
   }[];
   additional: string[];
};

export type SoS_GetTermTemplateById_TermTemplate = {
   tid: number;
   name: string;
   data: SoS_GetTermTemplateById_TermTemplate_Data_Field;
};

type SoS_GetTermTemplateById_Response = { template: SoS_GetTermTemplateById_TermTemplate };

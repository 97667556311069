import { TApp } from "../../../constants/apps";
import styles from "./AppCard.module.scss";
import classNames from "classnames/bind";
import { SHrSosButton } from "@simplyhomes/react";
import { useState } from "react";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { useQFns_AD } from "../../../hooks/queries/appsDrawer/useQFns_AD";
import { useQ_AD_subcribedApps } from "../../../hooks/queries/appsDrawer/useQ_AD_subcribedApps";

const cx = classNames.bind(styles);

const AppCard = ({ appItem }: AppCardProps) => {
   const { qfns_AD } = useQFns_AD();
   const qADsApps = useQ_AD_subcribedApps();
   const [subscribing, setSubscribing] = useState(false);

   const subscribedApps = qADsApps.data?.appIds || [];
   const isSubscribed = subscribedApps.includes(appItem.id);

   const handleSubscribe = async () => {
      setSubscribing(true);
      await qfns_AD.subscribeApp({ appId: appItem.id, subscribe: !isSubscribed }).then(() => qADsApps.refetch());
      setSubscribing(false);
   };
   return (
      <div className={cx("contentC")}>
         <div className={cx("tittleC")}>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL(`/assets/${appItem.icon}`)} alt="" />
            </div>
            <span>{appItem.name}</span>
         </div>
         <span>{appItem.description}</span>

         <div className={cx("btnC")}>
            <SHrSosButton
               type={isSubscribed ? "outlined" : "filled"}
               loading={qADsApps.isFetching || subscribing}
               buttonProps={{
                  className: cx("btn-subcribe"),
                  onClick: handleSubscribe,
               }}
            >
               <img src={CF_CDN_URL(`/assets/${isSubscribed ? "remove_black.svg" : "plus_white.svg"}`)} alt="" />
               {isSubscribed ? "Unsubscribe" : "Subscribe"}
            </SHrSosButton>
            <div className={cx("img-new")}>
               <img src={CF_CDN_URL("/assets/open_in_new_black.svg")} alt="" />
            </div>
         </div>
      </div>
   );
};

export default AppCard;
type AppCardProps = {
   appItem: TApp;
};

import { SHrPopup, SHrSosButton } from "@simplyhomes/react";
import styles from "./DevOcean.module.scss";
import { useState } from "react";
import { SoSInputTitle } from "../../components/common/soSInputTitle/SoSInputTitle";
import { useQOffers_GetTermTemplates } from "../../hooks/queries/offers/termTemplate/useQOffers_GetTermTemplates";
import { useQSoS_UserByEmail } from "../../hooks/queries/user/useQSoS_UserByEmail";
import { useQ_Users } from "../../hooks/queries/user/useQ_Users";
import { useQOffers_GetLeads } from "../../hooks/queries/offers/leads/useQOffers_GetLeads";
export const DevOcean = () => {
   const userRes = useQSoS_UserByEmail({ email: "ocean.nguyen@simplyhomes.com" });
   console.log(userRes.data?.user);

   const termTemplatesRes = useQOffers_GetTermTemplates();
   console.log(termTemplatesRes.data?.templates);

   const usersRes = useQ_Users();

   console.log(usersRes.data?.users);

   const leadsRes = useQOffers_GetLeads();
   console.log(leadsRes.data?.leads);

   const [isPopupOpen, setIsPopupOpen] = useState(false);

   return (
      <div className={`${styles.contentC}`}>
         <SHrSosButton type="bland">Review Property</SHrSosButton>{" "}
         <SHrSosButton type="dangerous">Review Property</SHrSosButton>{" "}
         <SHrSosButton type="elevated">Review Property</SHrSosButton>{" "}
         <SHrSosButton type="filled">Review Property</SHrSosButton>{" "}
         <SHrSosButton type="outlined">Review Property</SHrSosButton>{" "}
         <SHrSosButton type="positive">Review Property</SHrSosButton>
         <SHrSosButton type="text">Review Property</SHrSosButton>
         <SHrSosButton type="tonal" buttonProps={{ onClick: () => setIsPopupOpen(!isPopupOpen) }}>
            Review Property
         </SHrSosButton>
         <SoSInputTitle title="Question ID" type="text" value={""} handleValue={() => {}} />
         <SHrPopup show={isPopupOpen}>
            <div>Popup Content</div>
            <SHrSosButton type="filled" buttonProps={{ onClick: () => setIsPopupOpen(!isPopupOpen) }}>
               Close
            </SHrSosButton>
         </SHrPopup>
      </div>
   );
};

import { useAppContext } from "../../contexts/AppContext";
import { TuseCreateAxiosParams, useCreateAxios } from "./useCreateAxios";

export const useAioAxios = (p?: TuseAioAxiosParams) => {
   const { redirectOnError } = p || {};
   const { credential } = useAppContext().appState;
   const { createdAxios: aioAxios } = useCreateAxios({
      config: { baseURL: process.env.REACT_APP_AIO_API_URL + "/sos", headers: { authorization: credential } },
      redirectOnError: redirectOnError == null ? true : redirectOnError,
   });

   return { aioAxios };
};
type TuseAioAxiosParams = Pick<TuseCreateAxiosParams, "redirectOnError">;

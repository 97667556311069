import { ReactNode, useReducer, useContext, createContext } from "react";
import { MapPropertyDto } from "../hooks/queries/map/useQMap_Properties";

type TMapState = {
   search: string;
   properties: MapPropertyDto[];
   filteredPropertiesMap: Record<string, MapPropertyDto | undefined>;
   selectedPropertyId: string;
   hoveredPropertyId: string;
   center: { lat: number; lng: number };
   zoom: number;
};
const mapInitialState: TMapState = {
   search: "",
   properties: [],
   filteredPropertiesMap: {},
   selectedPropertyId: "",
   hoveredPropertyId: "",
   center: { lat: 37.0459377, lng: -85.3823968 },
   zoom: 5,
};
// type TMapAction = { overwrite: Partial<TMapState> };
type TMapAction =
   | { overwrite: Partial<Pick<TMapState, "center" | "zoom" | "hoveredPropertyId">> }
   | { type: "setSearch"; payload: { search: string } }
   | { type: "setProperties"; payload: { properties: MapPropertyDto[] } }
   | { type: "selectProperty"; payload: { id: string } };
const propertyArrayToMap = (properties: MapPropertyDto[]) =>
   properties.reduce<Record<string, MapPropertyDto | undefined>>((acc, p) => ({ ...acc, [p.id]: p }), {});
const mapReducer = (state: TMapState, action: TMapAction): TMapState => {
   if ("overwrite" in action) return { ...state, ...action.overwrite };
   switch (action.type) {
      case "setSearch": {
         const { search } = action.payload;
         const querriedProperties = state.properties;
         const filteredProperties = querriedProperties.filter((p) =>
            p.address.toLowerCase().includes(search.toLowerCase())
         );
         const filteredPropertiesMap = propertyArrayToMap(filteredProperties);
         return { ...state, filteredPropertiesMap, search };
      }
      case "setProperties": {
         const { properties } = action.payload;
         const filteredPropertiesMap = propertyArrayToMap(properties);
         return { ...state, properties, filteredPropertiesMap };
      }
      case "selectProperty": {
         const { id } = action.payload;
         const selectedProperty = state.filteredPropertiesMap[id];
         if (!selectedProperty) return state;
         if (state.selectedPropertyId === id) return { ...state, selectedPropertyId: "" };
         const { coordinates } = selectedProperty;
         const center = { lat: coordinates.x, lng: coordinates.y };
         return { ...state, selectedPropertyId: id, center, zoom: 17 };
      }

      default:
         return state;
   }
};

const MapContext = createContext<{
   mapState: TMapState;
   mapDispatch: React.Dispatch<TMapAction>;
}>({
   mapState: mapInitialState,
   mapDispatch: () => {},
});

export const MapProvider = ({ children }: { children: ReactNode }) => {
   const [mapState, mapDispatch] = useReducer(mapReducer, mapInitialState);
   return <MapContext.Provider value={{ mapState, mapDispatch }}>{children}</MapContext.Provider>;
};

export const useMapContext = () => useContext(MapContext);

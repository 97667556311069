import { useState } from "react";
import styles from "./SoSMapPropertyMarker.module.scss";
import { useClickOutside } from "../../../hooks/react/useClickOutside";
import { OverlayViewF } from "@react-google-maps/api";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { SHu_js_currencyFormat } from "@simplyhomes/utils";
export const SoSMapPropertyMarker = ({ property, onAddressClick }: TSoSMapPropertyMarkerProps) => {
   const { address, streetViewUrl, askingPrice, bathrooms, bedrooms, coordinates, sqft, unitCount } = property;

   const [expanded, setExpanded] = useState(false);

   const handleCopyToClipboard = () => navigator.clipboard.writeText(address);

   const handleClickOuside = () => {
      if (!expanded) return;
      setExpanded(false);
   };
   const { ref: clickOutsideRef } = useClickOutside({ onClickOutside: handleClickOuside });

   return (
      <OverlayViewF mapPaneName="overlayMouseTarget" position={coordinates}>
         <div className={`${styles.mapPropC}`} ref={clickOutsideRef}>
            {!expanded ? (
               <div className={`${styles.mapPropMini}`} onClick={() => setExpanded(true)}>
                  <img src={CF_CDN_URL("/assets/map/map_marker_frame.svg")} alt="" />
                  {streetViewUrl && <img src={streetViewUrl} alt="streetview" />}
               </div>
            ) : (
               <div className={`${styles.mapPropInfo}`}>
                  <div className={`${styles.mapPropInfoL}`}>
                     {streetViewUrl && <img src={streetViewUrl} alt="streetview" />}
                  </div>
                  <div className={`${styles.mapPropInfoR}`}>
                     <div>
                        <img src={CF_CDN_URL("/assets/map/location_neutral.svg")} alt="" />
                        <span onClick={onAddressClick}>{address}</span>
                        <button type="button" onClick={handleCopyToClipboard}>
                           <img src={CF_CDN_URL("/assets/map/content_copy_neutral.svg")} alt="copy" />
                        </button>
                     </div>
                     <ul>
                        <li>
                           <p>Asking Price</p>
                           <b>{SHu_js_currencyFormat(askingPrice).split(".")[0]}</b>
                        </li>
                        <li>
                           <p>Max Price</p>
                           <b>--</b>
                        </li>
                     </ul>
                     <div>
                        <div>
                           <img src={CF_CDN_URL("/assets/map/square_foot_neutral.svg")} alt="" />
                           <span>{sqft}</span>
                        </div>
                        <div>
                           <img src={CF_CDN_URL("/assets/map/house_neutral.svg")} alt="" />
                           <span>{unitCount}</span>
                        </div>
                        <div>
                           <img src={CF_CDN_URL("/assets/map/bedroom_neutral.svg")} alt="" />
                           <span>{bedrooms}</span>
                        </div>
                        <div>
                           <img src={CF_CDN_URL("/assets/map/bathroom_neutral.svg")} alt="" />
                           <span>{bathrooms}</span>
                        </div>
                     </div>
                  </div>
               </div>
            )}
         </div>
      </OverlayViewF>
   );
};
export type TSoSMapPropertyMarker_Property = {
   sqft: number;
   address: string;
   bedrooms: number;
   unitCount: number;
   bathrooms: number;
   askingPrice: number;
   streetViewUrl: string;
   coordinates: { lat: number; lng: number };
};
type TSoSMapPropertyMarkerProps = {
   property: TSoSMapPropertyMarker_Property;
   onAddressClick?: () => void;
};

import classNames from "classnames/bind";
import { useContext, useMemo, useState } from "react";

import styles from "./ApplicationLibraryPage.module.scss";
import AppCard from "./AppCard/AppCard";
import { CF_CDN_URL } from "../../utils/CF_CDN_URL";
import { TAppCategory, apps } from "../../constants/apps";
import { useQ_AD_subcribedApps } from "../../hooks/queries/appsDrawer/useQ_AD_subcribedApps";
import { AppsDrawerContext, AppsDrawerProvider, useADContext } from "../../contexts/AppsDrawerContext";

const cx = classNames.bind(styles);

const ApplicationLibraryPage = () => {
   return (
      <AppsDrawerProvider>
         <div className={cx("appLibraryC")}>
            <div className={cx("container")}>
               <HeadingAndSearchBar />
               <div className={cx("body_context")}>
                  <CategoriesMenuLeft />
                  <TabComponent />
               </div>
            </div>
         </div>
      </AppsDrawerProvider>
   );
};
export default ApplicationLibraryPage;

const HeadingAndSearchBar = () => {
   return (
      <div className={cx("heading_search")}>
         <span className={cx("heading")}>Application library</span>
         <div className={cx("input_search")}>
            <input type="text" placeholder="Search app" />
            <img src={CF_CDN_URL("/assets/search.svg")} alt="" />
         </div>
      </div>
   );
};

const CategoriesMenuLeft = () => {
   const { adState, adDispatch } = useADContext();
   const { selectedCategory } = adState;
   return (
      <div className={cx("categories")}>
         <span>Categories</span>
         <div className={cx("categories_menu")}>
            <ul>
               {menuCategories.map((category, index) => (
                  <li key={index}>
                     <button
                        type="button"
                        onClick={() => adDispatch({ overwrite: { selectedCategory: category.type } })}
                        className={cx({ active: category.type === selectedCategory })}
                     >
                        <img src={CF_CDN_URL(`/assets/${category.icon}`)} alt="" />
                        <span>{category.title}</span>
                     </button>
                  </li>
               ))}
            </ul>
         </div>
      </div>
   );
};

const tabs = ["Explore", "Subscribed"] as const;

const TabComponent = () => {
   const [tabSelected, setTabSelected] = useState<(typeof tabs)[number]>(tabs[0]);
   const { adState } = useContext(AppsDrawerContext);
   const { selectedCategory } = adState;

   const qADsApps = useQ_AD_subcribedApps();

   const subscribedApps = useMemo(() => qADsApps.data?.appIds || [], [qADsApps.data?.appIds]);
   const selectedApps = useMemo(() => (selectedCategory === "all" ? apps : []), [selectedCategory]);
   const appsToRender = useMemo(
      () =>
         tabSelected === "Explore"
            ? selectedApps
            : tabSelected === "Subscribed"
            ? selectedApps.filter((app) => subscribedApps.includes(app.id))
            : [],
      [selectedApps, subscribedApps, tabSelected]
   );

   return (
      <div className={cx("tab")}>
         <ul className={cx("ul-tab")}>
            {tabs.map((elem, index) => {
               const style = elem === tabSelected ? "selected" : "";
               return (
                  <li key={index} className={cx("li-item", style)} onClick={() => setTabSelected(elem)}>
                     {elem}
                  </li>
               );
            })}
         </ul>
         <div className={cx("cardC")}>
            {appsToRender.map((app, i) => {
               return <AppCard appItem={app} key={i + app.name} />;
            })}
         </div>
      </div>
   );
};

type TMenuCategory = {
   type: TAppCategory;
   title: string;
   icon: string;
};

const menuCategories: TMenuCategory[] = [
   {
      title: "All categories",
      type: "all",
      icon: "grid_view_black.svg",
   },
   {
      title: "Leads",
      type: "leads",
      icon: "people_alt_black.svg",
   },
   {
      title: "Underwriting",
      type: "underwriting",
      icon: "gpp_good_black.svg",
   },
   {
      title: "Transaction",
      type: "transaction",
      icon: "request_page_black.svg",
   },
   {
      title: "Renovation",
      type: "renovation",
      icon: "construction_black.svg",
   },
   {
      title: "Asset Management",
      type: "asset-management",
      icon: "maps_home_work_black.svg",
   },
];

import classNames from "classnames/bind";
import styles from "./PViRejectModal.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { SoSCheckbox } from "../../../../components/common/SoSCheckbox/SoSCheckbox";
import { SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { useMPVi_UpdateSourceStatus } from "../../../../hooks/mutates/PVi/useMPVi_UpdateSourceStatus";
import { useState } from "react";
import { useSoSOptions_PVi_Source_Reject_Reasons } from "../../../../hooks/queries/options/useQSoS_Options";

const cx = classNames.bind(styles);

export const PViRejectModal = ({ toggle }: TRejectionProps) => {
   const [selectedReasons, setSelectedReasons] = useState<string[]>([]);

   const [rejectDetail, setRejectDetail] = useState<string>("");

   const { mutateAsync: mutateUpdateSourceStatus, isMutating } = useMPVi_UpdateSourceStatus();

   const qPViSourceRejectReasons = useSoSOptions_PVi_Source_Reject_Reasons();

   const handleReasonChange = (reasonId: string) => {
      if (selectedReasons.includes(reasonId)) {
         setSelectedReasons(selectedReasons.filter((id) => id !== reasonId));
      } else {
         setSelectedReasons([...selectedReasons, reasonId]);
      }
   };

   const handleRejectProperty = () => {
      mutateUpdateSourceStatus({
         status: "Rejected",
         reasons: selectedReasons,
         rejectDetail,
         getNextQuickPick: true,
      }).then(() => toggle());
   };

   return (
      <article className={cx("contentC")}>
         <div className={cx("topC")}>
            <div className={cx("topLeft")}>
               <div className={cx("img-icon")}>
                  <img
                     className={cx({})}
                     src={CF_CDN_URL("/assets/property_validator/rate_review_black.svg ")}
                     alt=""
                  />
               </div>
               <div className={cx("headerC")}>
                  <span>Please provide a reason for rejection</span>
                  <p>You can select more than one answer</p>
               </div>
            </div>
            <div className={cx("close-btn")}>
               <button type="button" title="close" onClick={toggle}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>

         {qPViSourceRejectReasons.isFetching && (
            <article className={`${styles.loading}`}>
               <SHrSpinner />
               <span>Loading Source Reject Reasons...</span>
            </article>
         )}

         <div className={cx("reasonsC")}>
            {qPViSourceRejectReasons.data?.options.map((reason, i) => (
               <SoSCheckbox
                  title={reason.label}
                  value={selectedReasons.includes(reason.value)}
                  onChange={() => handleReasonChange(reason.value)}
                  key={i}
               />
            ))}

            <div className={cx("otherReasonC")}>
               <span>
                  <b>Further Detail</b>
               </span>
               <textarea
                  placeholder="Share your view here..."
                  value={rejectDetail}
                  onChange={(e) => setRejectDetail(e.target.value)}
               />
            </div>
         </div>

         <SHrSosButton
            loading={!!isMutating}
            type="dangerous"
            buttonProps={{ className: cx("btn", "reject"), onClick: handleRejectProperty }}
         >
            Reject Lead
         </SHrSosButton>
      </article>
   );
};

type TRejectionProps = {
   toggle: () => void;
};
